import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import "./career-development-page.css";

//Images
import Home_Background from "../../../assets/career_development_background.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import NumberCard from "./numbercard";
import ScrollTrigger from "react-scroll-trigger";
import FlipCard from "./FlipCard";

//Charts
import CareerDevelopmentCharts from "./CareerDevelopmentCharts";
import CareerDevelopmentCharts02 from "./CareerDevelopmentCharts02";

import SpotLight from "./spotlight/SpotLight";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

export default function Homepage() {
  const [renderState, setRenderState] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="career-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 className="career-topic-home">
                    CAREER
                    <br />
                    DEVELOPMENT
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="career-cards">
          <div className="career-para-box">
            <div data-aos="zoom-in">
              <h1 className="career-para-two">CAREER</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="career-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <div className="euqality-paragraph-section-1">
          <ScrollTrigger onEnter={() => setRenderState(true)}>
            {renderState && (
              <center className="euqality-paragraph-section-1-center">
                <p>
                  The development of our people is a vital focus area of the
                  Hirdaramani culture. We offer a range of career development
                  and enhancement programs aimed at achieving two goals:
                </p>
                <ul className="career-paragraph-section-1-center-yello-p">
                  <li>80% of supervisors to be reached through career advancement programs by 2025.</li>
                  <li>60% of executives to be reached through career advancement programs by 2025.</li>
                </ul>
              </center>
            )}
          </ScrollTrigger>
        </div>

        {/* Section 4 */}
        <CareerDevelopmentCharts />

        {/* Section 5 */}
        <SpotLight />

        {/* Section 6 */}
        <CareerDevelopmentCharts02 />

        {/* Section - 7 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
