import React, { useEffect } from "react";
import "./trust-hightlight-charts.css";

//Chart
import TrustHightlightBarChart from "./Charts/trust-chart-1";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="new-trust-hightlight-chart-container">
          <div className="trust-hightlight-bolder-box-line">
            <div className="new-trust-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Trust highlights</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="trust-hightlight-bolder-box-line-content">
              <div className="trust-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>
                      Target 16: Use 80% Certified Sustainable materials
                    </strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Hirdaramani has made the commitment to ensure that, by 2025,
                    at least 80% of the raw materials used in our manufacturing
                    processes will be Certified Sustainable, which is defined in
                    line with globally accepted sustainability standards.
                    <br />
                    <br />A key challenge faced by us and all companies aiming
                    for this goal is that at least 64% of materials still come
                    from synthetic sources and, as such, change has to be
                    gradually managed and implemented along with a commitment to
                    closing the loop through increased recycling of fabric,
                    while choosing the available and preferred option from each
                    fibre category.
                  </p>
                </div>
              </div>
              <div className="trust-hightlight-bolder-box-line-content-right">
                <TrustHightlightBarChart />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="trust-hightlight-chart-container">
        <div className="trust-hightlight-header-contents">
          <div
            className="trust-hightlight-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>Trust highlights</h1>
          </div>
          <div className="trust-hightlight-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>
                Target 16: Use 80% Certified Sustainable materials
              </strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              Hirdaramani has made the commitment to ensure that, by 2025, at
              least 80% of the raw materials used in our manufacturing processes
              will be Certified Sustainable, which is defined in line with
              globally accepted sustainability standards.
              <br />
              <br />A key challenge faced by us and all companies aiming for
              this goal is that at least 64% of materials still come from
              synthetic sources and, as such, change has to be gradually managed
              and implemented along with a commitment to closing the loop
              through increased recycling of fabric, while choosing the
              available and preferred option from each fibre category.
            </p>
          </div>
        </div>
        <center>
          <div className="trust-hightlight-bar-chart">
            <TrustHightlightBarChart />
          </div>
        </center>
      </div> */}
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
