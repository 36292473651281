import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import "./pillars.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Pillars() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div style={{ paddingTopp: "100px", paddingBottom: "100px" }}>
      <center>
        <h1
          className="pillars-topic"
          data-aos="fade-in"
          data-aos-duration="2000"
        >
          OUR PILLARS
        </h1>

        <div className="ban-gap">
          <div className="icon-container">
            <div
              className="icon-column"
              id="column1"
              data-aos="fade-right"
              data-aos-duration="2500"
            >
              <a href="/conserve/" aria-label="button">
                <div div className="button-image-1">
                  <h1 className="topic-overlay">CONSERVE</h1>
                </div>
              </a>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-right"
              data-aos-duration="2500"
            >
              <a
                href="/empower/"
                aria-label="button"
                data-aos="fade-left"
                data-aos-duration="2500"
              >
                <div div className="button-image-2">
                  <h1 className="topic-overlay">EMPOWER</h1>
                </div>
              </a>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
              <a href="/support/" aria-label="button">
                <div div className="button-image-3">
                  <h1 className="topic-overlay">SUPPORT</h1>
                </div>
              </a>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
              <a href="/trust/" aria-label="button">
                <div div className="button-image-4">
                  <h1 className="topic-overlay">TRUST</h1>
                </div>
              </a>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
