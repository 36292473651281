import React, { useEffect } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Background_Img from "../../../assets/environment-spotlight.webp";
import wow_gender_image_with_frame from "../../../assets/image_032.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import "./spotlight.css";

import Aos from "aos";
import "aos/dist/aos.css";

export default function Spotlight() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      {/* Section 1 */}

      <div style={{ position: "relative" }}>
        <div className="environment-spotlight-background">
          <div>
            <img
              src={Background_Img}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div className="environment-spotlight-text-box-2">
            <div>
              <Parallax speed={10}>
                <h1
                  data-aos="fade-right"
                  className="environment-spotlight-topic-home-spot"
                >
                  SPOTLIGHT
                </h1>
              </Parallax>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Section 2 */}
      {/* 01 */}
      <div className="environment-spotlight-wow-gender-section">
        <div className="environment-spotlight-wow-gender-section-left environment-spotlight-wow-gender-section-left-01">
          <img
            src={wow_gender_image_with_frame}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="environment-spotlight-wow-gender-section-right healthcare-spotlight-wow-gender-section-right-01">
          <div className="environment-spotlight-wow-gender-section-right-contents ">
            <h1 data-aos="fade-in">Coastal protection and mangrove projects</h1>
            <p data-aos="fade-in">
              Coastal erosion is one of the world’s largest environmental
              threats.{" "}
              <a
                href="/support/environment/spotlight-coastal-protection-and-mangrove-projects/"
                className="link"
              >
                Read here
              </a>{" "}
              about Hirdaramani’s support of dedicated coastal and mangrove
              regeneration projects across Vietnam and Sri Lanka.
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/environment/spotlight-coastal-protection-and-mangrove-projects/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="environment-spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
