import React, { useState, useEffect, useRef } from "react";
import "./chart_content.css";
import bottleImage from "../../../../../assets/conserve-chemical-chart-02-image-01.webp";

const Chart = () => {
  const [waterPercentage, setWaterPercentage] = useState(0);
  const chartSectionRef = useRef(null);

  useEffect(() => {
    const chartSectionNode = chartSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const timer = setTimeout(() => {
              const waterHeight = '100%';
              setWaterPercentage(waterHeight);
            }, 300); // Adjust delay time as needed

            return () => clearTimeout(timer);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (chartSectionNode) {
      observer.observe(chartSectionNode);
    }

    return () => {
      if (chartSectionNode) {
        observer.unobserve(chartSectionNode);
      }
    };
  }, []);

  return (
    <div className="conserve-chemical-chart-02-section" ref={chartSectionRef}>
      <div className="conserve-chemical-chart-02-container">
        <img className="conserve-chemical-chart-02-image" src={bottleImage} alt="Bottle" />
        <div className="conserve-chemical-chart-02-container-content-01">
          <div className="conserve-chemical-chart-02-image-water-effect-01" style={{ height: waterPercentage }} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
