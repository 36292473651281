import React, { useEffect, useRef } from "react";
import "./highlights-line.css";

// npm i framer-motion
import { motion } from "framer-motion";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.1;
    return {
      pathLength: 1,
      opacity: 1,

      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export default function NewAnimation() {
  // Refs for the marker elements
  const blueMarkerRef = useRef(null);
  const greenMarkerRef = useRef(null);

  // Function to calculate angle between two points
  const calculateAngle = (x1, y1, x2, y2) => {
    return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
  };

  // Effect to set marker rotation
  useEffect(() => {
    const blueMarker = blueMarkerRef.current;
    const greenMarker = greenMarkerRef.current;

    // Calculate angle for blue arrow
    const blueAngle = calculateAngle(1050, 1016, 1048, 635);
    blueMarker.setAttribute("orient", blueAngle);

    // Calculate angle for green arrow
    const greenAngle = calculateAngle(1050, 1016, 1048, 635);
    greenMarker.setAttribute("orient", greenAngle);
  }, []);

  return (
    <div className="arrow-lines">
      <div className="blue-arrow">
        <motion.svg
          width="900"
          height="1018"
          viewBox="-150 -330 1230 500"
          initial="hidden"
          animate="visible"
          xmlns="http://www.w3.org/2000/svg"
          className="blue-arrow"
        >
          <defs>
            <marker
              id="arrow"
              markerWidth="10"
              markerHeight="10"
              refX="7"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
              ref={blueMarkerRef}
            >
              <motion.path d="M0,0 L0,6 L9,3 z" fill="#313893" />
            </marker>
          </defs>

          <motion.path
            d="M1095.44 1016.12C1095.44 1016.12 1154.46 787.437 1048.12 635.446C845.516 345.881 446.107 788.228 175.819 524.94C-12.3215 341.671 5.3763 0.751768 5.3763 0.751768"
            stroke="#313893"
            strokeWidth="8"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="round"
            variants={draw}
            initial="hidden"
            animate="visible"
            custom={1}
            markerEnd="url(#arrow)"
          />
        </motion.svg>
      </div>

      <div className="green-arrow">
        <motion.svg
          width="900"
          height="700"
          viewBox="-150 -400 1230 1018"
          initial="hidden"
          animate="visible"
          xmlns="http://www.w3.org/2000/svg"
          className="green-arrow"
        >
          <defs>
            <marker
              id="arrow2"
              markerWidth="10"
              markerHeight="10"
              refX="7"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
              ref={greenMarkerRef}
            >
              <motion.path d="M0,0 L0,6 L9,3 z" fill="#3CE5B2" />
            </marker>
          </defs>

          <motion.path
            d="M1095.44 1016.12C1095.44 1016.12 1154.46 787.437 1048.12 635.446C845.516 345.881 446.107 788.228 175.819 524.94C-12.3215 341.671 5.3763 0.751768 5.3763 0.751768"
            stroke="#3CE5B2"
            strokeWidth="8"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeDasharray="3.846, 1.282"
            variants={draw}
            initial="hidden"
            animate="visible"
            custom={1}
            markerEnd="url(#arrow2)"
          />
        </motion.svg>
      </div>
    </div>
  );
}
