import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./unsdg.css";
import legacy_image from "../../assets/t.png";

export default function Pcard() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="unsdg-section-container">
      <div className="unsdg-section-container-left">
        <h1 data-aos="fade-right" data-aos-duration="2000">
          UN SUSTAINABLE DEVELOPMENT GOALS
        </h1>
        <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
          We are guided by the UN Sustainable Development Goals (UNSDGs), which
          provide an integrated approach recognising that action in one area
          will affect outcomes in others, and that development must balance
          social, economic, and environmental sustainability.
          <br />
          <br />
          By aligning our sustainability agenda with the UNSDGs, Hirdaramani can
          prioritize the most important issues facing society and work towards
          creating a better future for all.
        </p>
      </div>
      <div className="unsdg-section-container-right">
        <div className="unsdg-section-container-right-image">
          <img
            src={legacy_image}
            alt="Legacy"
            data-aos="zoom-out"
            data-aos-duration="2000"
          />
        </div>
      </div>
    </div>
  );
}
