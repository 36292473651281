import React, { useEffect } from "react";
import "./WasteTargetsCharts.css";

//Charts
import WasteTargetsChart01 from "./charts/chart-1/chart";
import WasteTargetsChart02 from "./charts/chart-2/chart";
import WasteTargetsChart03 from "./charts/chart-3/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="new-chemical-chart-page-hightlight-chart-container">
          <div className="new-chemical-chart-page-hightlight-bolder-box-line">
            <div className="new-chemical-chart-page-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Waste target highlights</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>
                      Target E9: Processed fabric waste per garment
                    </strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    In 2023, we almost met our Future First 2025 target of
                    having fewer than 34 grams per garment of normalised fabric
                    waste. All our operating countries contributed to this
                    reduction in waste, with Bangladesh continuing to remain
                    below their targets.
                  </p>
                </div>
              </div>
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
                <center>
                  <WasteTargetsChart02 />
                </center>
              </div>
            </div>
          </div>
          {/* // */}
          <div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Target E10: Zero waste to landfill</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    While huge reductions in landfill waste were recorded at
                    Hirdaramani two years ago, more streamlining work is now
                    under way as we steer ourselves in the right direction.We
                    are proud of these continuing efforts to make landfill waste
                    a concern of the past under our Zero Waste to Landfill
                    program.
                  </p>
                </div>
              </div>
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
                <center>
                  <WasteTargetsChart01 />
                </center>
              </div>
            </div>
          </div>
          {/* // */}
          <div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Target E11: Recycle fabric waste</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    While we marginally fell short of meeting our fabric
                    recycling waste targets in 2022, there has been a slight
                    decrease in 2023 as well, taking us to 78%, 2% below the
                    target set out in our Future First Roadmap. However, we are
                    fully committed to surpassing the target of 80% in 2025.
                  </p>
                </div>
              </div>
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
                <center>
                  <WasteTargetsChart03 />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="new-chemical-chart-container">
        <div className="new-chemical-header-contents">
          <div
            className="new-chemical-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Waste <br />
              target <br /> highlights
            </h1>
          </div>
          <div className="new-chemical-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target E9: Processed fabric waste per garment</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              In 2023, we almost met our Future First 2025 target of having
              fewer than 34 grams per garment of normalised fabric waste. All
              our operating countries contributed to this reduction in waste,
              with Bangladesh continuing to remain below their targets.
            </p>
          </div>
        </div>
        <center>
          <div className="new-chemical-bar-chart">
            <WasteTargetsChart02 />
          </div>
        </center>

        
        <div className="new-chemical-header-contents">
          <div className="new-chemical-header-content-left"> </div>
          <div className="new-chemical-header-content-right new-chemical-chart-margin-bottom">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target E10: Zero waste to landfill</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              While huge reductions in landfill waste were recorded at
              Hirdaramani two years ago, more streamlining work is now under way
              as we steer ourselves in the right direction.We are proud of these
              continuing efforts to make landfill waste a concern of the past
              under our Zero Waste to Landfill program.
            </p>
          </div>
        </div>
        <center>
          <div className="new-chemical-bar-chart">
            <WasteTargetsChart01 />
          </div>
        </center>

        
        <div className="new-chemical-header-contents">
          <div className="new-chemical-header-content-left"> </div>
          <div className="new-chemical-header-content-right new-chemical-chart-margin-bottom">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target E11: Recycle fabric waste</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              While we marginally fell short of meeting our fabric recycling
              waste targets in 2022, there has been a slight decrease in 2023 as
              well, taking us to 78%, 2% below the target set out in our Future
              First Roadmap. However, we are fully committed to surpassing the
              target of 80% in 2025.
            </p>
          </div>
        </div>
        <center>
          <div className="new-chemical-bar-chart">
            <WasteTargetsChart03 />
          </div>
        </center>
      </div> */}
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
