import React, { useEffect } from "react";
import "./community-part.css";
import { ParallaxProvider } from "react-scroll-parallax";
import CommunitySpotlight from "./community-spotlight";

export default function Community() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        {/* section-2 */}
        <div>
          <div className="community-animation-para">
            <center className="community-animation-para-center">
              <h1>Community</h1>
              <p>
                While children and healthcare are core areas of support, we
                always take a broader look at our local communities to see how
                we can engage and identify where our assistance is needed most.
              </p>
              <p>
                This means that our community support can range across a variety
                of impactful measures, from water and sanitation projects and
                infrastructure development right through to supporting local
                institutions.
              </p>
            </center>
          </div>
        </div>

        <CommunitySpotlight />
      </div>
    </ParallaxProvider>
  );
}
