import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./chart.css";
import Content01 from "./srilanka_content_01";
import Content02 from "./srilanka_content_02";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box-ban">
          <div className="conserve-chart-03-card-container">
            <div className="conserve-chart-03-card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Content01 />
                </div>
                <div className="conserve-chart-03-letter letter-content-01">
                  <h4>Certified Facilities</h4>
                </div>
                <div className="conserve-chart-03-number number-content-01">
                  {counterOn && (
                    <CountUp start={0} end={41} duration={3} delay={0.3} />
                  )}
                  %
                </div>
              </center>
            </div>

            <div className="conserve-chart-03-card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Content02 />
                </div>
                <div className="conserve-chart-03-letter letter-content-02">
                  <h4>Not Certified</h4>
                </div>
                <div className="conserve-chart-03-number number-content-02">
                  {counterOn && (
                    <CountUp start={0} end={59} duration={3} delay={0.3} />
                  )}
                  %
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
