import React, { useEffect } from "react";
import "./HealthcareTargetsCharts.css";

//Charts
import TargetsBarChart01 from "./Charts/chart-1/chart-1";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="new-childrin-education-chart-container-1">
        <div className="childrin-education-bolder-box-line">
          <div className="new-childrin-education-header-contents">
            <div
              className="new-conserve-header-content-left-2"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Healthcare Targets</h1>
            </div>
          </div>
        </div>
        <div>
          <div className="childrin-education-bolder-box-line-content">
            <div className="childrin-education-bolder-box-line-content-left">
              <div>
                <h4 data-aos="fade-in" data-aos-duration="2000">
                  <strong>Target S8: People impact</strong>
                </h4>
                <br />
                <p
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  We established a target of 5,600 people benefitting from our
                  range of health programs. This goal was surpassed in
                  considerable numbers, primarily due to the contributions from
                  the Hirdaramani Memorial Trust (HMT), which is our Group-wide
                  philanthropic foundation.
                </p>
                <p
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  It is worth noting, however, that many of our individual
                  operating countries have implemented projects that cannot be
                  considered qualifiable to meet the set target, as some
                  contributions at country and facility level are indirect and
                  not included in these figures.
                </p>
              </div>
            </div>
            <div className="childrin-education-bolder-box-line-content-right">
              <TargetsBarChart01 />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="childrin-education-chart-container">
        <div className="childrin-education-header-contents">
          <div
            className="childrin-education-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Healthcare Targets
            </h1>
          </div>
          <div className="childrin-education-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target S8: People impact</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              We established a target of 5,600 people benefitting from our range
              of health programs. This goal was surpassed in considerable
              numbers, primarily due to the contributions from the Hirdaramani
              Memorial Trust (HMT), which is our Group-wide philanthropic
              foundation.
            </p>
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              It is worth noting, however, that many of our individual operating
              countries have implemented projects that cannot be considered
              qualifiable to meet the set target, as some contributions at
              country and facility level are indirect and not included in these
              figures.
            </p>
          </div>
        </div>
        <br />
        <br />
        <center>
          <div className="childrin-education-bar-chart">
            <TargetsBarChart01 />
          </div>
        </center>
      </div> */}
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
