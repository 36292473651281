import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./childrin&education.css";
import HomeVideo from "../../../assets/childrin&education_background.webp";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TargetsCharts from "./TargetsCharts";
import Spotlight from "./spotlight";
import FlipCard from "./FlipCard";

import Aos from "aos";
import "aos/dist/aos.css";

export default function ChildrinEducation() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="childrin-education-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 className="childrin-education-topic-home">
                    Children & <br />
                    Education
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div style={{ backgroundColor: "#313893", color: "#ffffff" }}>
          <center>
            <div className="childrin-education-page-para-2">
              <h4 data-aos="fade-in" data-aos-once="true">
                Health. Wellbeing. Education. These are the core foundations for
                a better future for our children. It deserves our focus and
                investment.
              </h4>
            </div>
          </center>
        </div>

        {/* section-3 */}
        <TargetsCharts />

        {/* section-4 */}
        <Spotlight />

        {/* Section - 5 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
