import React, { useState } from "react";
//import { Link } from "react-router-dom";
import "./chartWater.css";

import Group from './group.jsx'
import Ethiopia from './ethiopia.jsx'
import Vietnam from './vietnam.jsx'
import Bangladesh from './bangladesh.jsx'
import Srilanka from './srilanka.jsx';

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "group":
        return <Group />;
      case "ethiopia":
        return <Ethiopia />;
      case "vietnam":
        return <Vietnam />;
      case "bangladesh":
        return <Bangladesh />;
      case "srilanka":
        return <Srilanka />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%", padding:'0px 15px' }}>
      <div className="conserve-water-chart-01">
        <h1 className="conserve-chart-heading">
          Normalised Industrial Freshwater Consumption (L/Garment)
        </h1>
        <div className="conserve-water-chart-01-paragraph-two-column">
          <div className="conserve-water-chart-01-paragraph-column-1-new">
            <h4>L per garment</h4>
          </div>
          <div className="conserve-water-chart-01-paragraph-column-2-new">
            <div>{renderPage()}</div>
          </div>
          <div className="conserve-water-chart-01-paragraph-column-3-new">
            <div>
              <div
                className={`conserve-water-chart-01-text-box-1-opacity ${
                  selectedTab === "group" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("group")}
                onKeyDown={(e) => handleKeyDown(e, "group")}
              >
                Group
              </div>
              <br />
              <div
                className={`conserve-water-chart-01-text-box-2-opacity ${
                  selectedTab === "ethiopia" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("ethiopia")}
                onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
              >
                Ethiopia
              </div>
              <br />
              <div
                className={`conserve-water-chart-01-text-box-3-opacity ${
                  selectedTab === "vietnam" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("vietnam")}
                onKeyDown={(e) => handleKeyDown(e, "vietnam")}
              >
                Vietnam
              </div>
              <br />
              <div
                className={`conserve-water-chart-01-text-box-4-opacity ${
                  selectedTab === "bangladesh" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("bangladesh")}
                onKeyDown={(e) => handleKeyDown(e, "bangladesh")}
              >
                Bangladesh
              </div>
              <br />
              <div
                className={`conserve-water-chart-01-text-box-5-opacity ${
                  selectedTab === "srilanka" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("srilanka")}
                onKeyDown={(e) => handleKeyDown(e, "srilanka")}
              >
                Sri Lanka
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aa-pc conserve-water-chart-01">
        <div style={{ marginTop: "-20px" }}>
          <div className="conserve-water-chart-01-paragraph-two-column">
            <div className="conserve-water-chart-01-paragraph-column-4-new">
              <br />
            </div>
            <div className="conserve-water-chart-01-paragraph-column-5-new">
              <div className="conserve-water-chart-01-letter letter-content-01">
                <h4>2022</h4>
              </div>
              <div className="conserve-water-chart-01-letter letter-content-02">
                <h4>2023</h4>
              </div>
              <div className="conserve-water-chart-01-letter letter-content-03">
                <h4>Target</h4>
              </div>
            </div>
            <div className="conserve-water-chart-01-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}