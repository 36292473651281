import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./sri_lanka.css";
import FemaleChart1 from "./vietnam_female_1-0";
import FemaleChart2 from "./vietnam_female_2-0";
import FemaleChart3 from "./sri_lanka_female_3-30";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box  empower-equality-chart-01">
          <div className="career-card-container-5">
            <div className="card empower-equality-chart-01" style={{ width: "120px" }}>
              <center>
                <div>
                  <FemaleChart1 />
                </div>
                <div className="middle-topic">
                  <div className="equality-chart-1-sri_lanka-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={33} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="Sri_Lanka_2023">2023</span>
              </center>
            </div>

            <div className="card empower-equality-chart-01" style={{ width: "120px" }}>
              <center>
                <div>
                  <FemaleChart2 />
                </div>
                <div className="middle-topic">
                  <div className="equality-chart-1-sri_lanka-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={33} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="Sri_Lanka_2022">2022</span>
              </center>
            </div>

            <div className="card empower-equality-chart-01" style={{ width: "120px" }}>
              <center>
                <div>
                  <FemaleChart3 />
                </div>
                <div className="middle-topic">
                  <div className="equality-chart-1-sri_lanka-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={30} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
                <br />
                <span className="Sri_Lanka_Target">Target</span>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
