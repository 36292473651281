import React, { useEffect } from "react";
import './SpotLight-PACE.css'
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/PACE_image_background.jpg";
//import Blue_Background from "../../../../assets/wow_gender_image_with_blue_background_02.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";


function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 100%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box PACE-section">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="spotlight-topic-home pace-section"
                  >
                    P.A.C.E. (Personal
                    <br /> Advancement and Career
                    <br /> Enhancement) <br />
                    program
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left">
            <div className="spotlight-content-left-l career-PACE-spotlight-left-l">
              {/* <img src={Blue_Background} alt="Blue Shape" data-aos="flip-right"/> */}
              <div className="career-PACE-spotlight-content-left-l-content">
                <div className="b-cr">
                  <p data-aos="fade-in" data-aos-delay="1000">
                    Gap Inc., renowned global clothing brand, introduced this
                    program in 2007 and Hirdaramani is proud to be a committed
                    partner. Designed to empower women in the apparel industry
                    through personal and professional development, the program,
                    was launched in Bangladesh in 2022 and in Vietnam in 2023.
                  </p>
                </div>
              </div>
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">PACE</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                It now operates as an ongoing program that creates opportunity
                for our female associates to advance in their careers and as
                well as enhance their personal development. The program
                curriculum centres on three core modules:
              </p>
              <ul>
                <li>Communication</li>
                <li>Problem Solving and Decision Making (PDSM)</li>
                <li>Time and Stress Management (TSM).</li>
              </ul>
              <p>
                The P.A.C.E. program offers great synergies with our female
                leadership targets in our Future First Sustainability Roadmap.
                Our associates can leverage world-best practices and industry
                knowledge to develop into well-rounded individuals, both
                personally and professionally.
              </p>
              <p>2023 participation:</p>
              <ul>
                <li>Bangladesh: 1,045 female employees</li>
                <li>Vietnam: 270 female employees.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight
