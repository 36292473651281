import React, { useState } from "react";
import "./chartChemical.css";

import Group from './group.jsx'
import Ethiopia from './ethiopia.jsx'
import Vietnam from './vietnam.jsx'
import Bangladesh from './bangladesh.jsx'
import Srilanka from './srilanka.jsx';

// import HGroupLogo from './../../../../../assets/h-group-logo.webp';
// import EthiopiaLogo from './../../../../../assets/Ethiopia-flag.png';
// import VietnamLogo from './../../../../../assets/vietnam-flag.png';
// import BangladeshLogo from './../../../../../assets/bangladesh.png';
// import SriLankaLogo from './../../../../../assets/Sri-Lanka.png';

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "group":
        return <Group />;
      case "ethiopia":
        return <Ethiopia />;
      case "vietnam":
        return <Vietnam />;
      case "bangladesh":
        return <Bangladesh />;
      case "srilanka":
        return <Srilanka />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%", padding:'0 15px' }}>
      <div className="conserve-chemical-chart-01">
        <h1 className="conserve-energy-chart-heading">% of screened chemicals used </h1>
        <div className="conserve-chemical-chart-01-paragraph-two-column">
          {/* <div className="conserve-chart-1-paragraph-column-1-new">
          </div> */}
          <div className="conserve-chemical-chart-01-paragraph-column-2-new">
            <div>{renderPage()}</div>
          </div>
          <div className="conserve-chemical-chart-01-paragraph-column-3-new">
            <div>
              <div
                className={`conserve-chemical-chart-01-text-box-1-opacity ${
                  selectedTab === "group" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("group")}
                onKeyDown={(e) => handleKeyDown(e, "group")}
              >
                {/* <img src={HGroupLogo} alt="" /> */}
                <h4>H-Group</h4>
              </div>
              <br />
              <div 
                className={`conserve-chemical-chart-01-text-box-2-opacity ${
                  selectedTab === "ethiopia" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("ethiopia")}
                onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
              >
                {/* <img src={EthiopiaLogo} alt="" /> */}
                <h4>Ethiopia</h4>
              </div>
              <br />
              <div
                className={`conserve-chemical-chart-01-text-box-3-opacity ${
                  selectedTab === "vietnam" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("vietnam")}
                onKeyDown={(e) => handleKeyDown(e, "vietnam")}
              >
                {/* <img src={VietnamLogo} alt="" /> */}
                <h4>Vietnam</h4>
              </div>
              <br />
              <div
                className={`conserve-chemical-chart-01-text-box-4-opacity ${
                  selectedTab === "bangladesh" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("bangladesh")}
                onKeyDown={(e) => handleKeyDown(e, "bangladesh")}
              >
                {/* <img src={BangladeshLogo} alt="" /> */}
                <h4>Bangladesh</h4>
              </div>
              <br />
              <div
                className={`conserve-chemical-chart-01-text-box-5-opacity ${
                  selectedTab === "srilanka" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("srilanka")}
                onKeyDown={(e) => handleKeyDown(e, "srilanka")}
              >
                {/* <img src={SriLankaLogo} alt="" /> */}
                <h4>Sri Lanka</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}