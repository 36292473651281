import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./career-chart-2.css";
import MaleChart from "./male";
import FemaleChart from "./female";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <h2 className="career-employees-chart-topic-2">
          Hours of Training by Gender
        </h2>
        <br />

        <div style={{ width: "80%" }}>
          <div className="career-card-container-2">
            <div className="card career-chart-02" style={{ width: "81px" }}>
              <center>
                <div>
                  <MaleChart />
                </div>
                <br />
                <span className="career-para-2-male">Male</span>
                <div className="career-number-loader-2-male">
                  {counterOn && (
                    <CountUp
                      start={100000}
                      end={108736}
                      duration={3}
                      delay={0.3}
                    />
                  )}
                </div>
              </center>
            </div>

            <div className="card career-chart-02" style={{ width: "81px" }}>
              <center>
                <div>
                  <FemaleChart />
                </div>
                <br />
                <span className="career-para-2-female">Female</span>
                <div className="career-number-loader-2-female">
                  {counterOn && (
                    <CountUp
                      start={100000}
                      end={245682}
                      duration={3}
                      delay={0.3}
                    />
                  )}
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
