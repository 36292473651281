import React from "react";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile as faFaceSmileHalo } from "@fortawesome/free-solid-svg-icons";
import {
  faGlobe,
  faHandHoldingHeart,
  faRankingStar,
} from "@fortawesome/free-solid-svg-icons";
import "./marquree.css"

export default function marquree() {
  return (
    <div>
      <div className="marquree-part">
        {/* First Row: Left to Right */}
        <Marquee direction="left" speed={10}>
          <div className="marquee-content-1">
            {/* Content for the first row */}
            conserve the environment
            <FontAwesomeIcon icon={faGlobe} className="font-icon" />
            conserve the environment
            <FontAwesomeIcon icon={faGlobe} className="font-icon" />
            conserve the environment
            <FontAwesomeIcon icon={faGlobe} className="font-icon" />
            conserve the environment
            <FontAwesomeIcon icon={faGlobe} className="font-icon" />
            conserve the environment
            <FontAwesomeIcon icon={faGlobe} className="font-icon" />
          </div>
        </Marquee>

        {/* Second Row: Right to Left */}
        <Marquee direction="right" speed={10}>
          <div className="marquee-content">
            {/* Content for the second row */}
            empower our associates
            <FontAwesomeIcon icon={faFaceSmileHalo} className="font-icon" />
            empower our associates
            <FontAwesomeIcon icon={faFaceSmileHalo} className="font-icon" />
            empower our associates
            <FontAwesomeIcon icon={faFaceSmileHalo} className="font-icon" />
            empower our associates
            <FontAwesomeIcon icon={faFaceSmileHalo} className="font-icon" />
            empower our associates
            <FontAwesomeIcon icon={faFaceSmileHalo} className="font-icon" />
          </div>
        </Marquee>
        {/* 3rd Row: Left to Right */}
        <Marquee direction="left" speed={10}>
          <div className="marquee-content">
            {/* Content for the 3rd row */}
            support the communities
            <FontAwesomeIcon icon={faHandHoldingHeart} className="font-icon" />
            support the communities
            <FontAwesomeIcon icon={faHandHoldingHeart} className="font-icon" />
            support the communities
            <FontAwesomeIcon icon={faHandHoldingHeart} className="font-icon" />
            support the communities
            <FontAwesomeIcon icon={faHandHoldingHeart} className="font-icon" />
            support the communities
            <FontAwesomeIcon icon={faHandHoldingHeart} className="font-icon" />
          </div>
        </Marquee>

        {/* Second Row: Right to Left */}
        <Marquee direction="right" speed={10}>
          <div className="marquee-content-2">
            {/* Content for the second row */}
            trust in good choices
            <FontAwesomeIcon icon={faRankingStar} className="font-icon" />
            trust in good choices
            <FontAwesomeIcon icon={faRankingStar} className="font-icon" />
            trust in good choices
            <FontAwesomeIcon icon={faRankingStar} className="font-icon" />
            trust in good choices
            <FontAwesomeIcon icon={faRankingStar} className="font-icon" />
            trust in good choices
            <FontAwesomeIcon icon={faRankingStar} className="font-icon" />
          </div>
        </Marquee>
      </div>
    </div>
  );
}
