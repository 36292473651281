import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./pages/home";
import Footer from "./components/footer";
import Conserve from "./pages/conserve";
import Empower from "./pages/empower";
import Support from "./pages/support";
import Trust from "./pages/trust";
import Energy from "./pages/energy";
import Water from "./pages/water";
import Chemical from "./pages/chemical";
import Waste from "./pages/waste";
import Equality from "./pages/equality";
import ChildrinEducation from "./pages/childrin&education";
import Wellbing from "./pages/wellbeing";
import Environment from "./pages/environment";
import SpotLight from "./pages/spotlight";
import CareerDevelopment from "./pages/career-development";
import CareerWellbeing from "./pages/career-wellbeing";
import CleanDrinkingWaterForChildren from "./pages/spotlight-clean-drinking-water-for-children";
import FareSpectraSchoolOfAutism from "./pages/spotlight-clean-fare-spectra-school-of-autism";
import TEAProject from "./pages/spotlight-t-e-a-project";
import WowIskole from "./pages/spotlight-wow-iskole";
import PACE from "./pages/spotlight-PACE";
import WorkplaceTrainingAndEducationPrograms from "./pages/SpotLight-workplace-training-and-education-programs";
import MentalHealthAndWellbeing from "./pages/SpotLight-mental-health-and-wellbeing";
import OnSiteMentalHealthClinics from "./pages/SpotLight-on-site-mental-health-clinics";
import HealthyAndPositiveRelationshipsProgram from "./pages/SpotLight-healthy-and-positive-relationships-program";
import WOWHealthAndVitality from "./pages/SpotLight-wow-health-and-vitality";
import WOWLibraries from "./pages/SpotLight-wow-libraries";
import SpotlightCoastalProtectionAndMangroveProjects from "./pages/spotlight-coastal-protection-and-mangrove-projects";
import SpotlightUndpBackyardPoultryAndMarket from "./pages/spotlight-undp-backyard-poultry-and-market";
import SpotlightSupportForHospitals from "./pages/spotlight-support-for-hospitals";
import BuildingTheKhangChienBridge from "./pages/spotlight-building-the-khang-chien-bridge";

import CookiesPolicy from "./pages/cookies-policy";
import PrivacyPolicy from "./pages/privacy-policy";

import CookieNotice from "./components/CookieNotice";
import NotFound from "./components/NotFound";

import ContactUs from "./pages/contact-us";

import SiteMap from "./pages/sitemap";

const App = () => {
  return (
    <Router>
      <CookieNotice />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/conserve/" element={<Conserve />} />
        <Route path="/empower/" element={<Empower />} />
        <Route path="/support/" element={<Support />} />
        <Route path="/trust/" element={<Trust />} />

        <Route path="/conserve/energy/" element={<Energy />} />
        <Route path="/conserve/water/" element={<Water />} />
        <Route path="/conserve/chemical/" element={<Chemical />} />
        <Route path="/conserve/waste/" element={<Waste />} />

        <Route path="/empower/equality/" element={<Equality />} />
        <Route path="/empower/equality/spotlight" element={<SpotLight />} />
        <Route
          path="/empower/career-development/"
          element={<CareerDevelopment />}
        />
        <Route
          path="/empower/career-development/spotLight-PACE/"
          element={<PACE />}
        />
        <Route
          path="/empower/career-development/spotLight-workplace-training-and-education-programs/"
          element={<WorkplaceTrainingAndEducationPrograms />}
        />
        <Route path="/empower/wellbeing/" element={<CareerWellbeing />} />
        <Route
          path="/empower/wellbeing/spotlight-mental-health-and-wellbeing/"
          element={<MentalHealthAndWellbeing />}
        />
        <Route
          path="/empower/wellbeing/spotlight-on-site-mental-health-clinics/"
          element={<OnSiteMentalHealthClinics />}
        />
        <Route
          path="/empower/wellbeing/spotlight-healthy-and-positive-relationships-program/"
          element={<HealthyAndPositiveRelationshipsProgram />}
        />
        <Route
          path="/empower/wellbeing/spotlight-wow-health-and-vitality/"
          element={<WOWHealthAndVitality />}
        />
        <Route
          path="/empower/wellbeing/spotlight-wow-libraries/"
          element={<WOWLibraries />}
        />

        <Route
          path="/support/children&education/"
          element={<ChildrinEducation />}
        />
        <Route path="/support/wellbeing/" element={<Wellbing />} />
        <Route path="/support/environment/" element={<Environment />} />
        <Route
          path="/support/environment/spotlight-coastal-protection-and-mangrove-projects/"
          element={<SpotlightCoastalProtectionAndMangroveProjects />}
        />

        <Route
          path="/support/wellbeing/spotlight-support-for-hospitals/"
          element={<SpotlightSupportForHospitals />}
        />
        <Route
          path="/support/wellbeing/spotlight-building-the-khang-chien-bridge/"
          element={<BuildingTheKhangChienBridge />}
        />
        <Route
          path="/support/wellbeing/spotlight-undp-backyard-poultry-and-market/"
          element={<SpotlightUndpBackyardPoultryAndMarket />}
        />

        <Route
          path="/support/children&education/spotlight-clean-drinking-water-for-children/"
          element={<CleanDrinkingWaterForChildren />}
        />
        <Route
          path="/support/children&education/spotlight-fare-spectra-school-of-autism/"
          element={<FareSpectraSchoolOfAutism />}
        />
        <Route
          path="/support/children&education/spotlight-t-e-a-project/"
          element={<TEAProject />}
        />
        <Route
          path="/support/children&education/spotlight-wow-iskole/"
          element={<WowIskole />}
        />

        <Route path="/cookies-policy/" element={<CookiesPolicy />} />

        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />

        {/* Add the NotFound route as the last route */}
        <Route path="*" element={<NotFound />} />

        <Route path="/sitemap/" element={<SiteMap />} />

        <Route path="/contact-us/" element={<ContactUs />} />

        {/* Add other routes here */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
