import React, { useEffect } from "react";
import Header from "../../components/header";
import "./supportpage.css";
import HomeVideo from "../../assets/support_background.jpg";
import FlipCard from "./FlipCard";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

export default function Homepage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="support-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="support-topic-home">Support</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div>
          <div className="support-animation-para-2">
            <center
              className="support-animation-para-center"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <h1>SUPPORT OUR COMMUNITIES</h1>
              <p>
                Our business stretches across the globe and continues to grow
                every day. But taking care of the communities around us and
                helping them grow will be close to our hearts.
              </p>
              <p>
                Across our footprint, we are the major source of employment for
                the local communities. While we take great pride in providing
                livelihoods to thousands, we also understand that this comes
                with great responsibility.
              </p>
              <p>
                Our support of local communities runs wide and deep. It extends
                into areas of education, health & wellbeing, and even connects
                with our environmental focus where we support reforestation and
                biodiversity projects. It’s captured in our Future First
                Sustainability Roadmap 2025 under three key areas:
              </p>
            </center>
          </div>
        </div>

        {/* section-3 */}
        <div className="support-card">
          <div className="para-box">
            <div data-aos="fade-up-left" data-aos-duration="500">
              <h2 className="para-one-1">
                In our Future First roadmap, we concentrate
              </h2>
              <h2 className="para-one-2">
                our conservation efforts in four main areas:
              </h2>
            </div>
            <div data-aos="zoom-in">
              <h1 className="para-two">SUPPORT</h1>
            </div>
            <div className="support-flip-part">
              <FlipCard />
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
