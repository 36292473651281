import React, { useEffect } from "react";
import "./OtherEnergyTargetsCharts.css";

//Charts
import EnergyTargetsChart01 from "./charts/chart-1/chart";
import EnergyTargetsChart02 from "./charts/chart-2/chart";
import EnergyTargetsChart03 from "./charts/chart-3/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const labels = document.getElementsByClassName("apexcharts-pie-label");

    if (labels && labels.length > 0) {
      for (let i = 0; i < labels.length; i++) {
        if (i % 2 === 0) {
          const label = labels[i];
          label.setAttribute("text-anchor", "end");
        } else {
          const label = labels[i];
          label.setAttribute("text-anchor", "start");
        }
      }
    }
  }, []);

  return (
    <div>
      <div>
        <div className="new-energy-page-hightlight-chart-container">
          <div className="new-energy-page-hightlight-bolder-box-line">
            <div className="new-chemical-chart-page-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Other Environmental Targets</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>
                      Target E4 & E5: Reduction in industrial freshwater
                      consumption
                    </strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    The Hirdaramani Group’s freshwater consumption in 2023
                    showed a marginal decrease mainly due to reduced usage in
                    Vietnam and Bangladesh. Sri Lanka noted a significant rise,
                    and this substantially impacted Group results. We are
                    consciously working towards addressing any setbacks in
                    meeting our Future First target of reducing freshwater
                    consumption per garment by 50% and are eagerly exploring
                    means of decreasing our reliance on groundwater and
                    municipal water for non-potable purposes.
                  </p>
                </div>
              </div>
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
                <center>
                  <EnergyTargetsChart01 />
                </center>
              </div>
            </div>
          </div>
          {/* // */}
          <div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
                <center>
                  <EnergyTargetsChart02 />
                </center>
              </div>
              <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
                <center>
                  <EnergyTargetsChart03 />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="new-conserve-chart-container">
        <div className="new-conserve-header-contents">
          <div
            className="new-conserve-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Energy <br /> Targets
            </h1>
          </div>
          <div className="new-conserve-header-content-right">
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              From 2022 to 2023, we have recorded an overall decrease in our
              Total GHG Emissions as a Group. We note that as a single facility
              Ethiopia has a much lower GHG footprint but has recorded a strong
              decrease as well.
            </p>
          </div>
        </div>
        <center>
          <div>
            <div className="new-conserve-bar-chart">
              <EnergyTargetsChart01 />
            </div>
          </div>

          <div>
           
            <h2 className="topic-2nd">
              Target E1: 20% normalised energy savings
            </h2>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <div className="new-conserve-bar-chart">
                <div>
                  <EnergyTargetsChart02 />
                </div>
                <div>
                  <EnergyTargetsChart03 />
                </div>
              </div>
              <div className="chart-para-two-column">
                <div className="chart-para-column-1"></div>
                <div className="chart-para-column-2">
                  <p className="chart-para-p">
                    Overall, there will be continued focus to reduce our energy
                    consumption across our footprint, putting us on track to
                    achieve our target.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div> */}
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
