import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./gender.css";
import Chart1 from "./non-production-male";
import Chart2 from "./non-production-female";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div>
          <div className="career-card-container-5">
            <div className="card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Chart1 />
                </div>
                <div className="gender-middle-topic">
                  <div className="gender-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={76} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
              </center>
            </div>

            <div className="card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Chart2 />
                </div>
                <div className="gender-middle-topic">
                  <div className="gender-number-loader">
                    {counterOn && (
                      <CountUp start={0} end={72} duration={3} delay={0.3} />
                    )}
                    %
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
