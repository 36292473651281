import React, { useEffect } from "react";
import "./FlipCard.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Pillars() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div style={{ paddingBottom: "50px" }}>
      <center>
        <div className="empower-ban-gap">
          <div className="empower-icon-container">
            <div
              className="empower-icon-column"
              id="column1"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/empower/equality/" aria-label="button">
                <div className="empower-button-image-box">
                  <div className="empower-button-image empower-button-image-1-box" />
                  <h1 className="empower-topic-overlay">EQUALITY</h1>
                </div>
              </a>
            </div>
            <div
              className="empower-icon-column"
              id="column2"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/empower/career-development/" aria-label="button">
                <div className="empower-button-image-box">
                  <div className="empower-button-image empower-button-image-2-box" />
                  <h1 className="empower-topic-overlay">CAREER DEVELOPMENT</h1>
                </div>
              </a>
            </div>
            <div
              className="empower-icon-column"
              id="column2"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/empower/wellbeing/" aria-label="button">
                <div className="empower-button-image-box">
                  <div className="empower-button-image empower-button-image-3-box" />
                  <h1 className="empower-topic-overlay">WELLBEING</h1>
                </div>
              </a>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
