import React from "react";
import Page from "../templates/support/childrin&education/spotlight/wow-iskole";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          WOW Iskole | Education Program for Primary Kids | Hirdaramani
        </title>
        <meta
          name="title"
          content="WOW Iskole | Education Program for Primary Kids |  Hirdaramani"
        />
        <meta
          name="description"
          content="Delve into WOW Iskole’s mission to instill courage, perseverance, and positivity in Sri Lankan students through specialized training programs and sustainable educational practices."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/children&education/spotlight-wow-iskole/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="WOW Iskole" />
        <meta
          property="og:title"
          content="WOW Iskole | Education Program for Primary Kids |  Hirdaramani"
        />
        <meta
          property="og:description"
          content="Delve into WOW Iskole’s mission to instill courage, perseverance, and positivity in Sri Lankan students through specialized training programs and sustainable educational practices."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/children&education/spotlight-wow-iskole/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
