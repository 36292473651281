import React from 'react'
import Page from '../templates/empower/equality/spotlight/SpotLight'
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          WOW Gender Inclusive Workplace | Coaching Sessions | Hirdaramani
        </title>
        <meta
          name="title"
          content="WOW Gender Inclusive Workplace | Coaching Sessions | Hirdaramani"
        />
        <meta
          name="description"
          content="Discover Hirdaramani's WOW pilot project fostering gender inclusivity in leadership. Over 300 leaders certified as gender enablers, tackling unconscious bias and stereotypes."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/equality/spotlight/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="WOW Gender Inclusive Workplace" />
        <meta
          property="og:title"
          content="WOW Gender Inclusive Workplace | Coaching Sessions | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Discover Hirdaramani's WOW pilot project fostering gender inclusivity in leadership. Over 300 leaders certified as gender enablers, tackling unconscious bias and stereotypes."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/equality/spotlight/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
