import React, { useEffect } from "react";
import "./TargetsCharts-2.css";

//Charts
import OtherWaterTargetChart03 from "./charts/chart-3/chart";
import OtherWaterTargetChart04 from "./charts/chart-4/chart";
import OtherWaterTargetChart05 from "./charts/chart-6/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="chart-container-1">
        <div>
          <div className="chart-01-new-targetscharts-2-page-hightlight-bolder-box-line-content">
            <div className="chart-01-new-targetscharts-2-page-hightlight-bolder-box-line-content-left">
              <h4>
                <strong>
                  Target E6: Reduction in Freshwater Consumption
                </strong>
              </h4>
              <br />
              <br />
              <div>
                <OtherWaterTargetChart03 />
              </div>
              <br />
              <div>
                <p className="chart-para-p">
                  While we have seen an increase in freshwater consumption in 2023
                  when compared to 2022, we still remain below the target level.
                </p>
              </div>
            </div>

            <div className="chart-01-new-targetscharts-2-page-hightlight-bolder-box-line-content-right">
              <h4>
                <strong>
                  Target E7: Industrial Wastewater Recycling and Using it Back in
                  the Garment Washing Process.
                </strong>
              </h4>
              <br />
              <p className="chart-para-p">
                With 36% of wastewater recycled for industrial process use, we
                are on track to meet the target of 50%.
              </p>
              <br />
              <div>
                <div>
                  <OtherWaterTargetChart04 />
                </div>
                <br />
                <p className="chart-para-p">
                  Note: We treat 100% of the wastewater. However, only 36% is used
                  back in the garment washing process. While we have seen an
                  increase in freshwater consumption in 2023 when compared to
                  2022, we still remain below the target level.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="chart-container-3">
        <div>
          <div className="new-targetscharts-2-page-hightlight-bolder-box-line-content">
            <div className="new-targetscharts-2-page-hightlight-bolder-box-line-content-left">
              <div>
                <h4>
                  <strong>
                    Target E8: Rainwater Harvesting  
                  </strong>
                </h4>
                <br />
                <div>
                  <p>
                    <p>
                      We have fallen short of our target of harvesting rainwater
                      to replace a minimum of 10% of domestic water used for
                      cleaning and cooling. This was due primarily to the
                      unpredictability of rainfall seasons in 2023.
                    </p>
                    <p>
                      Rainwater harvesting remains a vital initiative for
                      Hirdaramani, and we will continue to increase the number
                      of rainwater tanks at our facilities with the aim of
                      driving our efforts.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="new-targetscharts-2-page-hightlight-bolder-box-line-content-right">
              <div>
                <OtherWaterTargetChart05 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
