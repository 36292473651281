import React from "react";
//import { Link } from "react-router-dom";
import Male from "./group-data";
import "./srilanka.css";

export default function Srilanka() {
  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="career-chart-10-srilanka-paragraph-two-column">
          <div className="career-chart-10-srilanka-paragraph-column-0"></div>
          <div className="career-chart-10-srilanka-paragraph-column-1">
            <h4>Target</h4>
            <h4>2023</h4>
            <h4>2022</h4>
          </div>
          <div className="career-chart-10-srilanka-paragraph-column-2">
            <div>
              <Male />
            </div>
          </div>
          <div className="career-chart-10-srilanka-paragraph-column-3">
            <div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="career-chart-10-srilanka-paragraph-two-column">
            <div className="career-chart-10-srilanka-paragraph-column-4">
              <br />
            </div>
            <div className="career-chart-10-srilanka-paragraph-column-5"></div>
            <div className="career-chart-10-srilanka-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
