import React, { useEffect } from "react";
import "./EnergyCharts_2.css";
import EnergyTargetsChart04 from "./charts/chart-4/chart";
//import EnergyTargetsChart05 from "./charts/chart-5/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function EnergyCharts_2() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="new-2-energy-page-hightlight-chart-container">
          <div>
            <div className="new-2-energy-page-hightlight-bolder-box-line-content">
              <div className="new-2-energy-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>
                      Target E3:50% Renewable Energy
                    </strong>
                  </h4>
                  <br />
                  <p data-aos="fade-in" data-aos-duration="2000">
                    The increased use of renewable energy forms a foundational
                    part of our Future First Sustainability Roadmap 2025 aimed
                    at reducing energy impacts and greenhouse gas emissions. Our
                    long-term goal is to operate all our facilities on 50% or
                    more of renewable energy by 2030 in line with our carbon
                    emissions reduction goals outlined in our SBTi measures.
                  </p>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    In 2023, we increased our use of renewable energy as part of
                    our overall energy usage to 18.46%, up from 13.14% in 2022.
                    This 5.32% change is strong progress and reflects our
                    commitment to the still-burgeoning renewable energy
                    technologies.
                  </p>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Our operations in Vietnam have continued to increase the use
                    of renewable sources to provide their energy needs, moving
                    well past the 50% target, while Sri Lanka has seen a
                    decrease of renewable energy used. In Bangladesh, there are
                    continued challenges due to rules and regulations in the
                    Economic Processing Zone (EPZ) prohibiting the installation
                    of solar panels, however we continue to explore alternate
                    methods to bridge this issue.
                  </p>
                </div>
              </div>
              <div className="new-2-energy-page-hightlight-bolder-box-line-content-right">
                <center>
                  <EnergyTargetsChart04 />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <div style={{ position: "relative" }}>
          <div className="energy-chart-section-2">
            <center>
              <div className="energy-chart-section-2-container">
                <center className="Header">
                  <h6 data-aos="fade-in" data-aos-duration="2000">
                    Target E3:50% Renewable Energy
                  </h6>
                </center>
                <div className="new-conserve-bar-chart">
                  <EnergyTargetsChart04 />
                </div> */}
        {/* <div className="new-conserve-bar-chart">
              <EnergyTargetsChart05 />
            </div> */}
        {/* 
                <div className="enery-chart-section-2-contents">
                  <p data-aos="fade-in" data-aos-duration="2000">
                    The increased use of renewable energy forms a foundational
                    part of our Future First Sustainability Roadmap 2025 aimed
                    at reducing energy impacts and greenhouse gas emissions. Our
                    long-term goal is to operate all our facilities on 50% or
                    more of renewable energy by 2030 in line with our carbon
                    emissions reduction goals outlined in our SBTi measures.
                  </p>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    In 2023, we increased our use of renewable energy as part of
                    our overall energy usage to 18.46%, up from 13.14% in 2022.
                    This 5.32% change is strong progress and reflects our
                    commitment to the still-burgeoning renewable energy
                    technologies.
                  </p>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Our operations in Vietnam have continued to increase the use
                    of renewable sources to provide their energy needs, moving
                    well past the 50% target, while Sri Lanka has seen a
                    decrease of renewable energy used. In Bangladesh, there are
                    continued challenges due to rules and regulations in the
                    Economic Processing Zone (EPZ) prohibiting the installation
                    of solar panels, however we continue to explore alternate
                    methods to bridge this issue.
                  </p>
                </div>
              </div>
            </center>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default EnergyCharts_2;
