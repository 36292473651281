import React from "react";
import Page from "../templates/contact-us/contact-us";
//import { Helmet } from "react-helmet";

export default function ContactUs() {
  return (
    <div>
      <Page />
    </div>
  );
}
