import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-wow-health-and-vitality';
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          WOW Health and Vitality | Enhancing Employee Wellbeing | Hirdaramani
        </title>
        <meta
          name="title"
          content="WOW Health and Vitality | Enhancing Employee Wellbeing | Hirdaramani"
        />
        <meta
          name="description"
          content="Explore Hirdaramani's health initiatives: free eye and dental clinics, diabetes programs, and the Healthy Leaders project. Over 6,300 employees benefited."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-wow-health-and-vitality/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Employee Wellbeing" />
        <meta
          property="og:title"
          content="WOW Health and Vitality | Enhancing Employee Wellbeing | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Explore Hirdaramani's health initiatives: free eye and dental clinics, diabetes programs, and the Healthy Leaders project. Over 6,300 employees benefited."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-wow-health-and-vitality/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
