import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import './chartWater4.css';
import Content01 from "./srilanka_content_01";
import Content02 from './srilanka_content_02';

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box-ban">
          <div className="conserve-water-chart-04-card-container">
            <div className="conserve-water-chart-04-card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Content01 />
                </div>
                <div className="conserve-water-chart-04-letter letter-content-01">
                  <h4>Used for process</h4>
                </div>
                <div className="conserve-water-chart-04-number number-content-01">
                  {counterOn && (<CountUp start={0} end={36} duration={3} delay={0.3} />)}%
                </div>
              </center>
            </div>

            <div className="conserve-water-chart-04-card" style={{ width: "120px" }}>
              <center>
                <div>
                  <Content02 />
                </div>
                <div className="conserve-water-chart-04-letter letter-content-02">
                  <h4>Not used for process</h4>
                </div>
                <div className="conserve-water-chart-04-number number-content-02">
                  {counterOn && (<CountUp start={0} end={64} duration={3} delay={0.3} />)}%
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
