import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./highlights-trust.css";
import HighlightLine from "./highlights-line";

export default function HighlightsTrust() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className="r-part">
          <p className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp
                start={0}
                end={85.8}
                decimals={1}
                duration={2}
                delay={0}
              />
            )}
            % <span className="card-circle">E10</span>
          </p>
          <p className="values-para">Zero waste to landfill</p>
          <p className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp
                start={0}
                end={120.7}
                decimals={1}
                duration={2}
                delay={0}
              />
            )}
            % <span className="card-circle">E8</span>
          </p>
          <p className="values-para">
            Harvest minimum 10% of domestic water from rainwater
          </p>
          <p className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp
                start={0}
                end={153.3}
                decimals={1}
                duration={2}
                delay={0}
              />
            )}
            % <span className="card-circle">E7</span>
          </p>
          <p className="values-para">
            Recycle over 50% of industrial wastewater for industrial processes
          </p>

          <div className="horizontal-line" />
          <div style={{ paddingBottom: "120px" }} />
        </div>
        <HighlightLine />
        <div style={{ paddingBottom: "1px" }} />
        <div className="mobile">
          <div style={{ paddingBottom: "10px" }} />
        </div>
      </ScrollTrigger>
    </div>
  );
}
