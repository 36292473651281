import React, { useEffect, useState } from "react";
import "./chart.css";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

import Group2019 from "./group_2019.jsx";
import Group2021 from "./group_2021.jsx";
import Group2022 from "./group_2022.jsx";
import Group2023 from "./group_2023.jsx";

export default function Chart() {
  const [year, setYear] = useState(2023);
  const [btnPlusState, setBtnPlusState] = useState(false);
  const [btnMinusState, setBtnMinusState] = useState(false);

  const handleYearPlusCount = () => {
    if (year === 2019) {
      setYear(year + 2);
    } else if (year !== 2023) {
      setYear(year + 1);
    }
  };

  const handleYearMinusCount = () => {
    if (year === 2021) {
      setYear(year - 2);
    } else if (year !== 2019) {
      setYear(year - 1);
    }
  };

  useEffect(() => {
    if (year >= 2023) {
      setBtnPlusState(false);
      setBtnMinusState(true);
    } else if (year <= 2019) {
      setBtnMinusState(false);
      setBtnPlusState(true);
    } else {
      setBtnMinusState(true);
      setBtnPlusState(true);
    }
  }, [year]);

  const renderPage = () => {
    switch (year) {
      case 2019:
        return <Group2019 />;
      case 2021:
        return <Group2021 />;
      case 2022:
        return <Group2022 />;
      case 2023:
        return <Group2023 />;
      default:
        return null;
    }
  };

  return (
    <div className="conserve-waste-chart-03-whole">
      <div className="conserve-waste-chart-03">
        <h1 className="conserve-chart-heading">
          Recycled Fabric Waste Percentage
        </h1>
        <div className="conserve-waste-chart-03-paragraph-two-column">
          <div className="conserve-waste-chart-03-paragraph-column-1-new"></div>
          <div className="conserve-waste-chart-03-paragraph-column-2-new">
            <div>{renderPage()}</div>
          </div>
          {/* <div className="conserve-waste-chart-03-paragraph-column-3-new">
          </div> */}
        </div>
      </div>
      <div>
        <div style={{ marginTop: "-20px" }}>
          <div className="conserve-waste-chart-03-paragraph-two-column">
            <div className="conserve-waste-chart-03-paragraph-column-4-new">
              <br />
            </div>
            <div className="conserve-waste-chart-03-paragraph-column-5-new">
              <div className="conserve-waste-chart-03-letter letter-content-01">
                <div className="conserve-waste-chart-03-letter-icons">
                  <TiArrowSortedUp
                    onClick={() => handleYearPlusCount()}
                    className={`conserve-waste-chart-03-letter-icon ${
                      btnPlusState ? "selected-icon" : ""
                    }`}
                  />

                  <TiArrowSortedDown
                    onClick={() => handleYearMinusCount()}
                    className={`conserve-waste-chart-03-letter-icon ${
                      btnMinusState ? "selected-icon" : ""
                    }`}
                  />
                </div>
                <h4 style={{ userSelect: "none" }}>{year}</h4>
              </div>
              <div className="conserve-waste-chart-03-letter letter-content-02">
                <h4 style={{ userSelect: "none" }}>Target</h4>
              </div>
            </div>
            {/* <div className="conserve-waste-chart-03-paragraph-column-6"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
