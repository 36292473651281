import React from "react";
import "./Sri-Lanka.css";
import FemaleChart1 from "./image-chart-1";

export default function Chart() {
  return (
      <div style={{ position: "relative", width:'100%' }}>
        <div className="empower-wellbeing-chart-2-chart-box">
          <div className="empower-wellbeing-chart-2trust-card-container-1">
            <div className="card" style={{ width: "100%" }}>
              <center>
              <h1 className="empower-wellbeing-chart-02-chart-heading">Number of Associates Impacted by WOW</h1>
                <div className="empower-wellbeing-chart-2-conatiner">
                  <FemaleChart1 />
                  <div className="empower-wellbeing-chart-2-country-name-container">
                    <h5>Group</h5>
                    <h5>Ethiopia</h5>
                    <h5>Vietnam</h5>
                    <h5>Bangladesh</h5>
                    <h5>Sri Lanka</h5>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
  );
}
