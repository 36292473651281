import React, { useEffect } from "react";
import "./SpotLight-mental-health-and-wellbeing.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/counselling_backgound.jpg";
import Blue_Background from "../../../../assets/image_05.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 0%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="spotlight-topic-home mental-health-spotlight-topic-home"
                  >
                    WOW
                    <br />
                    counselling and
                    <br />
                    mental health
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left mental-health-spotlight-content-left">
            <div className="spotlight-content-left-l mental-health-spotlight-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">WOW</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                While mental health has been a topic that has been increasingly
                addressed worldwide, with support and resources more available
                than it was a few years ago, cultural stigmas continue to exist
                within communities. What is important however is creating an
                environment where mental health can be an open discussion and
                awareness and support is continuously reinforced. We continue to
                focus on this and drive effective initiatives throughout our
                operations.
              </p>
              <p>
                In Sri Lanka, our efforts have made significant progress and we
                are keen to echo our success in our other countries of
                operation. All our business units in Sri Lanka participate in
                the WOW Counselling Program with five of the facilities
                featuring a dedicated counsellor.
              </p>
              <p>
                Our program encourages voluntary engagement to seek and receive
                help with mental health. We offer widespread access to
                professional counselling services for individual employees,
                groups, and families across a spectrum of mental health topics.
              </p>
              <p>
                In 2023, counselling services were accessed by 1,403 of our
                associates. 74% per cent of participants were women, which
                appropriately reflects our 70:30 female-male employee ratio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
