import React, { useEffect } from "react";
import "./EmpowerCharts.css";

//Charts
import EmpowerPieChart01 from "./EmpowerPieChart01";
import EmpowerPieChart02 from "./Charts/chart-1/employees-by-gender";
import EmpowerPieChart03 from "./Charts/chart-3/chart-3";
import EmpowerBarGroupChart from "./Charts/chart-4/chart-4";
import EmpowerBarChart01 from "./Charts/chart-2/employees-by-gender";
import EmpowerBarChart02 from "./Charts/chart-5/chart-4";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="new-empower-chart-container-1">
      <div>
        <div className="empower-bolder-box-line">
          <div className="new-conserve-header-contents">
            <div
              className="new-conserve-header-content-left-2"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Other Empower Targets</h1>
            </div>
          </div>
        </div>

        <div className="empower-bolder-box-line-4">
          <center className="empower-chart-topic-center">
            <div className="empower-chart-header-content-right">
              <h4 data-aos="fade-in" data-aos-duration="2000">
                <strong>Overall Employee Statistics</strong>
              </h4>
            </div>
          </center>
        </div>

        <center>
          <div className="empower-pie-charts">
            <EmpowerPieChart01 />
            <EmpowerPieChart02 />
          </div>
        </center>
        
        <div className="empower-border-box-bottom" />
          
          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <center>
                    <EmpowerBarGroupChart />
                  </center>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  
              </div>
            </div>
          </div>
          
        {/* <center>
          <div className="empower-pie-charts">
            <EmpowerPieChart01 />
            <EmpowerPieChart02 />
          </div>
          <div className="empower-bolder-box-line-2">
            <div className="empower-bar-chart">
              <EmpowerBarGroupChart />
            </div>
          </div>
        </center> */}

        {/* Chart Section 2 */}

          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Parental Leave</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Over 75% of employees are returning to work after parental
                    leave, this shows improvement from last year with 100% of men
                    returning to work(compared to 96% in 2022) and 74% of women
                    (compared to 69% in 2022) returning to work.
                  </p>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                  <center>
                    <EmpowerPieChart03 />
                  </center>
              </div>
            </div>
          </div>

          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left empower-chart-03">
                <div>
                  <center>
                    <EmpowerBarChart01 />
                  </center>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right empower-chart-05">
                <center>
                <EmpowerBarChart02 />
                </center>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
