import React from "react";
import Page from "../templates/conserve/chemical/chemicalpage";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>Chemical Management | Target Highlights | Hirdaramani</title>
        <meta
          name="title"
          content="Chemical Management | Target Highlights | Hirdaramani"
        />
        <meta
          name="description"
          content="Learn about our comprehensive approach to chemical management for a more environmentally friendly apparel manufacturing process."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/conserve/chemical/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Chemical Management" />
        <meta
          property="og:title"
          content="Chemical Management | Target Highlights | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Learn about our comprehensive approach to chemical management for a more environmentally friendly apparel manufacturing process."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/conserve/chemical/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
