import React from 'react'
import Page from '../templates/empower/career-development/spotlight/SpotLight-workplace-training-and-education-programs'
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          Workplace Training Education Programs | Career Development |
          Hirdaramani
        </title>
        <meta
          name="title"
          content="Workplace Training Education Programs | Career Development | Hirdaramani"
        />
        <meta
          name="description"
          content="Hirdaramani launched diverse training initiatives: e-learning in Vietnam, finance skills in Bangladesh, English proficiency, and FSCD training. Over 2,800 employees benefitted."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/career-development/spotLight-workplace-training-and-education-programs/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Workplace Training Education Programs" />
        <meta
          property="og:title"
          content="Workplace Training Education Programs | Career Development | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Hirdaramani launched diverse training initiatives: e-learning in Vietnam, finance skills in Bangladesh, English proficiency, and FSCD training. Over 2,800 employees benefitted."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/career-development/spotLight-workplace-training-and-education-programs/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
