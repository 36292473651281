import React from "react";
import "./chartWaste.css";
import Srilanka from './srilanka.jsx';

export default function Chart() {

  return (
    <div style={{ width: "100%", padding:'0px 0' }}>
      <div className="conserve-waste-chart-02">
        <h1 className="conserve-chart-heading">Landfill Waste Percentage - Group Wide</h1>
        <div className="conserve-waste-chart-02-paragraph-two-column">
          <div className="conserve-waste-chart-02-paragraph-column-02">
            <div><Srilanka /></div>
          </div>
        </div>
      </div>
    </div>
  );
}