import React, { useEffect } from "react";
import "./EqualityHighlightsCharts.css";

//Charts
import EqualityHighlightsBarChart01 from "./Charts/chart-1/equality-chart-1";
import EqualityHighlightsChart02 from "./Charts/chart-3/equality-chart-2";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function EqualityHighlightsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="new-equality-hightlight-chart-container">
          <div className="new-equality-hightlight-bolder-box-line">
            <div className="new-equality-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Equality highlights</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <h4
                    className="equality-highlights-header-content-right-heading"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  >
                    <strong>Target S2: Women leadership</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Across the Group, we still have some way to go to achieve
                    our 30% Female leadership positions target. There has been a
                    significant increase in the Sri Lankan operations, which is
                    very encouraging, and Vietnam has maintained its percentage
                    at 33%, exceeding our target for the second year in a row.
                    <br />
                    <br />
                    In Ethiopia, due to the limited scale of operations, there
                    is only zero position that falls under the grade 7 and above
                    category, and hence the target for female leadership does
                    not apply to the country. In Bangladesh, we are making
                    conscious efforts to make strides towards our targets,
                    efforts which we believe will be reflected in the upcoming
                    reporting period.
                  </p>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                <EqualityHighlightsBarChart01 />
              </div>
            </div>
          </div>

          {/* 02 */}
          <div>
            <div className="new-equality-hightlight-bolder-box-line-content">
              <div className="new-equality-hightlight-bolder-box-line-content-left">
                <div>
                  <p
                    className="equality-highlights-header-content-right-heading"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  >
                    <strong>Target S3: Women supervisors</strong>
                  </p>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Our goal of having 70% of supervisory positions occupied by
                    women is ambitious and distinctive of our culture that
                    strongly believes in female empowerment. There is still work
                    to be done to achieve our target, but for the second year in
                    a row Vietnam is exceeding our goals. Both Sri Lanka and
                    Bangladesh have witnessed small increases, while Ethiopia
                    registered a downturn that will be addressed as part of our
                    efforts in the coming year.
                  </p>
                </div>
              </div>
              <div className="new-equality-hightlight-bolder-box-line-content-right">
                <EqualityHighlightsChart02 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <div className="equality-highlights-chart-container">
          <div className="equality-highlights-header-contents">
            <div
              className="equality-highlights-header-content-left"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Equality highlights</h1>
            </div>
            <div className="equality-highlights-header-content-right">
              <p
                className="equality-highlights-header-content-right-heading"
                data-aos="fade-in"
                data-aos-duration="2000"
              >
                <strong>Target S2: Women leadership</strong>
              </p>
              <br />
              <p
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                Across the Group, we still have some way to go to achieve our
                30% Female leadership positions target. There has been a
                significant increase in the Sri Lankan operations, which is very
                encouraging, and Vietnam has maintained its percentage at 33%,
                exceeding our target for the second year in a row.
                <br />
                <br />
                In Ethiopia, due to the limited scale of operations, there is
                only zero position that falls under the grade 7 and above
                category, and hence the target for female leadership does not
                apply to the country. In Bangladesh, we are making conscious
                efforts to make strides towards our targets, efforts which we
                believe will be reflected in the upcoming reporting period.
              </p>
            </div>
          </div>
          <center>
            <div className="equality-highlights-bar-chart">
              <EqualityHighlightsBarChart01 />
            </div>
          </center>

          <div className="equality-highlights-header-contents">
            <div className="equality-highlights-header-content-left"></div>
            <div className="equality-highlights-header-content-right">
              <p
                className="equality-highlights-header-content-right-heading"
                data-aos="fade-in"
                data-aos-duration="2000"
              >
                <strong>Target S3: Women supervisors</strong>
              </p>
              <br />
              <p
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                Our goal of having 70% of supervisory positions occupied by
                women is ambitious and distinctive of our culture that strongly
                believes in female empowerment. There is still work to be done
                to achieve our target, but for the second year in a row Vietnam
                is exceeding our goals. Both Sri Lanka and Bangladesh have
                witnessed small increases, while Ethiopia registered a downturn
                that will be addressed as part of our efforts in the coming
                year.
              </p>
            </div>
          </div>
          <center>
            <div className="equality-highlights-bar-chart">
              <EqualityHighlightsChart02 />
            </div>
          </center>
        </div> */}
      </div>
    </div>
  );
}

export default EqualityHighlightsCharts;
