import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-on-site-mental-health-clinics';
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          On Site Health Clinics | Employee Health Benefits | Hirdaramani
        </title>
        <meta
          name="title"
          content="On Site Health Clinics | Employee Health Benefits | Hirdaramani"
        />
        <meta
          name="description"
          content="Initiating pilot projects for on-site mental health clinics in Sri Lanka, collaborating with state hospitals to support employees with mental health issues, focusing on clinical treatment."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-on-site-mental-health-clinics/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="On Site Health Clinics" />
        <meta
          property="og:title"
          content="On Site Health Clinics | Employee Health Benefits | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Initiating pilot projects for on-site mental health clinics in Sri Lanka, collaborating with state hospitals to support employees with mental health issues, focusing on clinical treatment."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-on-site-mental-health-clinics/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
