import React, { useEffect } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Background_Img from "../../../assets/community-spotlight.webp";
import Frame1 from "../../../assets/image_024.jpg";
import Frame2 from "../../../assets/image_029.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import "./community-spotlight.css";

import Aos from "aos";
import "aos/dist/aos.css";

export default function Spotlight() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      {/* Section 1 */}

      <div style={{ position: "relative" }}>
        <div className="community-spotlight-background">
          <div>
            <img
              src={Background_Img}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div className="healthcare-spotlight-text-box-2">
            <div>
              <Parallax speed={10}>
                <h1
                  data-aos="fade-right"
                  className="healthcare-spotlight-topic-home-spot"
                >
                  SPOTLIGHT
                </h1>
              </Parallax>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.35)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Section 2 */}
      {/* 01 */}
      <div className="community-spotlight-wow-gender-section">
        <div className="community-spotlight-wow-gender-section-left community-spotlight-wow-gender-section-left-01">
          <img src={Frame1} alt="WOW Gender" data-aos="flip-right" />
        </div>
        <div className="community-spotlight-wow-gender-section-right community-spotlight-wow-gender-section-right-01">
          <div className="community-spotlight-wow-gender-section-right-contents">
            <h1 data-aos="fade-in">Building the Khang Chien Bridge</h1>
            <p data-aos="fade-in">
              What does an apparel manufacturer have to do with building a
              bridge? When it benefits the local community and economy, we make
              it our business to do what we can.
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/wellbeing/spotlight-building-the-khang-chien-bridge/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="community-spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 01 */}
      <div className="community-spotlight-wow-gender-section">
        <div className="community-spotlight-wow-gender-section-left  community-spotlight-wow-gender-section-left-02">
          <img src={Frame2} alt="WOW Gender" data-aos="flip-right" />
        </div>
        <div className="community-spotlight-wow-gender-section-right community-spotlight-wow-gender-section-right-02">
          <div className="community-spotlight-wow-gender-section-right-contents">
            <h1 data-aos="fade-in">UNDP BACKYARD POULTRY FARMING AND MARKET</h1>
            <p data-aos="fade-in">
              In association with Lacoste and the United Nations Development
              Program (UNDP), Hirdaramani is part of an innovative initiative to
              increase income and nutritional supply for two local communities
              in Sri Lanka.
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/wellbeing/spotlight-undp-backyard-poultry-and-market/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="community-spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
