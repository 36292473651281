import React, { useState } from "react";
import { Link } from "react-router-dom";
import SriLanka from "./Sri-Lanka";
import BangladeshCard from "./Bangladesh";
import Vietnam from "./Vietnam";
import Ethiopia from "./Ethiopia";
import HMT from "./HMT";
import Group from "./Group";
import "./chart-1.css";

export default function Highlights() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "srilanka":
        return <SriLanka />;
      case "banglades":
        return <BangladeshCard />;
      case "vietnam":
        return <Vietnam />;
      case "ethiopia":
        return <Ethiopia />;
      case "hmt":
        return <HMT />;
      case "group":
        return <Group />;
      default:
        return null;
    }
  };

  return (
    <div>
      <center>
        <h2 className="main-topic-chart-1-p">
          People Reached via Healthcare Programs
        </h2>
        <br />
        <div className="childrin-education-chart-1-paragraph-two-column-2">
          <div className="chart-1-country-list-2 support-wellbeing-chart-01">
            <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-01 ${
                selectedTab === "srilanka" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("srilanka")}
              onKeyDown={(e) => handleKeyDown(e, "srilanka")}
            >
              {/* <img src={srilanka} alt="1" /> */}
              <h3 className={selectedTab === "srilanka" ? "selected" : ""}>
                Sri Lanka
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-02 ${
                selectedTab === "banglades" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("banglades")}
              onKeyDown={(e) => handleKeyDown(e, "banglades")}
            >
              {/* <img src={banglades} alt="2" /> */}
              <h3 className={selectedTab === "banglades" ? "selected" : ""}>
                Bangladesh
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-03 ${
                selectedTab === "vietnam" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("vietnam")}
              onKeyDown={(e) => handleKeyDown(e, "vietnam")}
            >
              {/* <img src={vietnam} alt="3" /> */}
              <h3 className={selectedTab === "vietnam" ? "selected" : ""}>
                Vietnam
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-04 ${
                selectedTab === "ethiopia" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("ethiopia")}
              onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
            >
              {/* <img src={ethiopia} alt="4" /> */}
              <h3 className={selectedTab === "ethiopia" ? "selected" : ""}>
                Ethiopia
              </h3>
            </Link>

            {/* <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-05 ${selectedTab === "hmt" ? "selected" : ""}`}
              onClick={() => handleTabClick("hmt")}
              onKeyDown={(e) => handleKeyDown(e, "hmt")}
            >
              <img src={hmt} alt="4" />
              <h3 className={selectedTab === "hmt" ? "selected" : ""}>HMT</h3>
            </Link> */}

            <Link
              tabIndex={0}
              className={`chart-t wellbeing-chart-t-06 ${
                selectedTab === "group" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("group")}
              onKeyDown={(e) => handleKeyDown(e, "group")}
            >
              {/* <img src={group} alt="4" /> */}
              <h3 className={selectedTab === "group" ? "selected" : ""}>
                Group
              </h3>
            </Link>
          </div>
        </div>

        <br />
      </center>
      
      <center>
        <div>{renderPage()}</div>
      </center>
    </div>
  );
}
