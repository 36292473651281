import React from "react";
import Page from "../templates/support/childrin&education/spotlight/clean-drinking-water-for-children";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Drinking water project for school in Vietnam | Community CSR |
          Hirdaramani
        </title>
        <meta
          name="title"
          content="Drinking water project for school in Vietnam | Community CSR | Hirdaramani"
        />
        <meta
          name="description"
          content="Dive into Hirdaramani's sustainability project, delivering clean drinking water to school children in Vietnam. Discover how we're prioritizing community well-being and environmental stewardship."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/children&education/spotlight-clean-drinking-water-for-children/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Drinking water project" />
        <meta
          property="og:title"
          content="Drinking water project for school in Vietnam | Community CSR | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Dive into Hirdaramani's sustainability project, delivering clean drinking water to school children in Vietnam. Discover how we're prioritizing community well-being and environmental stewardship."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/children&education/spotlight-clean-drinking-water-for-children/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
