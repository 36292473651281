import React, { useEffect } from "react";
import "./OtherEnvironmentalTargetsCharts.css";

//Charts
import OtherEnviornmentalTargetChart01 from "./charts/chart-1/chart";
import OtherEnviornmentalTargetChart02 from "./charts/chart-2/chart";
import OtherEnviornmentalTargetChart03 from "./charts/chart-3/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const labels = document.getElementsByClassName("apexcharts-pie-label");

    if (labels && labels.length > 0) {
      for (let i = 0; i < labels.length; i++) {
        if (i % 2 === 0) {
          const label = labels[i];
          label.setAttribute("text-anchor", "end");
        } else {
          const label = labels[i];
          label.setAttribute("text-anchor", "start");
        }
      }
    }
  }, []);

  return (
    <div className="new-conserve-chart-container-1">
      <div className="bolder-box-line">
        <div className="new-conserve-header-contents">
          <div
            className="new-conserve-header-content-left-2"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>Other Environmental Targets</h1>
          </div>
          {/* <div className="new-conserve-header-content-right"></div> */}
        </div>
      </div>
      <div>
        <div className="bolder-box-line-content">
          <div className="bolder-box-line-content-left">
            <div>
              <h4 data-aos="fade-in" data-aos-duration="2000">
                <strong>Target E17, E18, E19: Other Environmental Goals</strong>
              </h4>
              <br />
              <p
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                Our facilities Groupwide have achieved the HiGG vFEM target of
                80 points. The targets for 100% Green-building certified
                facilities and ISO certified facilities have seen continued
                progress.
              </p>
            </div>
          </div>
          <div className="bolder-box-line-content-right">
            <OtherEnviornmentalTargetChart01 />
          </div>
        </div>
      </div>
      <center>
        {/* <div
          className="empower-target-charts"
          data-aos="fade-in"
          data-aos-duration="2000"
        >
          <OtherEnviornmentalTargetChart02 />
          <OtherEnviornmentalTargetChart03 />
        </div> */}
        <div className="bolder-box-line-content-2">
          <div className="bolder-box-line-content-left-2">
            <OtherEnviornmentalTargetChart02 />
          </div>
          <div className="bolder-box-line-content-right-2">
            <OtherEnviornmentalTargetChart03 />
          </div>
        </div>
      </center>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
