import React, { useEffect } from "react";
import "./SpotLight-on-site-mental-health-clinics.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/on-site_mental_health_clinic_background.JPG";
import Blue_Background from "../../../../assets/image_011.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 10%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="spotlight-topic-home">
                    On-site
                    <br />
                    Mental Health
                    <br /> Clinics
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left on-site-mental-spotlight-content-left">
            <div className="spotlight-content-left-l on-site-mental-spotlight-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">OMHC</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                We are currently running pilot projects for on-site mental
                health clinics in two facilities in Sri Lanka, in collaboration
                with the respective state hospital in their areas.
              </p>
              <p>
                The project is aimed at supporting employees with mental ill
                health, especially those requiring clinical treatment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
