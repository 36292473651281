import React, { useEffect } from "react";
import "./SpotLight-healthy-and-positive-relationships-program.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/healthy_and_positive_relationships_program_background.jpg";
import Blue_Background from "../../../../assets/image_03.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 30%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="spotlight-topic-home-hprp-section"
                  >
                    Healthy and <br />
                    positive relationships
                    <br /> program
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left healthy-positive-spotlight-content-left">
            <div className="spotlight-content-left-l healthy-positive-spotlight-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">HPRP</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                The Hirdaramani workforce is relatively young, with 43% of
                employees under 35 years of age. This is when many are starting
                or raising their families, and so are subject to a variety of
                pressures.
              </p>
              <p>
                An analysis of workplace absenteeism indicated that
                approximately 36% of absences were due to family, spouse or
                relationship matters. A survey in March 2023 also showed that
                36% of employees experienced some form of domestic violence from
                a spouse or family member.
              </p>
              <p>
                We addressed this problem through a series of information and
                education campaigns. Across four facilities, we reached 4,621
                employees who learned more about the principles of healthy
                relationships and psychological and biological foundations of
                fostering sustainably positive relationships.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
