import React, { useState } from "react";
import Conserve from "./highlights-conserve";
import Empower from "./highlights-empower";
import Support from "./highlights-support";
import Trust from "./highlights-trust";
import "./highlights.css";
import pdf from "../../assets/pdf/H-Sustainability-Targets-2023.pdf";

import Pic from "../../assets/highlight_img-2.webp";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

export default function Highlights() {
  const [selectedTab, setSelectedTab] = useState("conserve");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "conserve":
        return <Conserve />;
      case "empower":
        return <Empower />;
      case "support":
        return <Support />;
      case "trust":
        return <Trust />;
      default:
        return null;
    }
  };

  return (
    <ParallaxProvider>
      <div
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "100px",
        }}
      >
        <div className="paragraph-two-column">
          <div className="paragraph-column-1">
            <center>
              <h1 className="highlights-main-topic">2023 HIGHLIGHTS</h1>

              <a
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="zoom-button"
              >
                Read 2023 Performance Summary
              </a>
            </center>
            <div className="img-gap-s" />
            <div className="pic">
              <ParallaxBanner style={{ aspectRatio: "2 / 2.5" }}>
                <ParallaxBannerLayer image={Pic} speed={5} />
              </ParallaxBanner>
            </div>
            {/* <img className="pic" src={Pic} alt="" /> */}
          </div>
          <div className="paragraph-column-2">
            <div className="pc">
              <nav className="navbar">
                <ul>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "conserve" ? "selected" : ""}
                    onClick={() => handleTabClick("conserve")}
                    onKeyDown={(e) => handleKeyDown(e, "conserve")}
                  >
                    <h2 className="tab-label">CONSERVE</h2>
                  </li>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "empower" ? "selected" : ""}
                    onClick={() => handleTabClick("empower")}
                    onKeyDown={(e) => handleKeyDown(e, "empower")}
                  >
                    <h2 className="tab-label">EMPOWER</h2>
                  </li>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "support" ? "selected" : ""}
                    onClick={() => handleTabClick("support")}
                    onKeyDown={(e) => handleKeyDown(e, "support")}
                  >
                    <h2 className="tab-label">SUPPORT</h2>
                  </li>
                  {/* Uncomment if "trust" tab is needed */}
                  {/* <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "trust" ? "selected" : ""}
                    onClick={() => handleTabClick("trust")}
                    onKeyDown={(e) => handleKeyDown(e, "trust")}
                  >
                    <h2 className="tab-label">TRUST</h2>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="mobile">
              <nav className="navbar">
                <ul>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "conserve" ? "selected" : ""}
                    onClick={() => handleTabClick("conserve")}
                    onKeyDown={(e) => handleKeyDown(e, "conserve")}
                  >
                    <h2 className="tab-label">CONSERVE</h2>
                  </li>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "empower" ? "selected" : ""}
                    onClick={() => handleTabClick("empower")}
                    onKeyDown={(e) => handleKeyDown(e, "empower")}
                  >
                    <h2 className="tab-label">EMPOWER</h2>
                  </li>
                </ul>
                <ul>
                  <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "support" ? "selected" : ""}
                    onClick={() => handleTabClick("support")}
                    onKeyDown={(e) => handleKeyDown(e, "support")}
                  >
                    <h2 className="tab-label">SUPPORT</h2>
                  </li>
                  {/* Uncomment if "trust" tab is needed */}
                  {/* <li
                    role="button"
                    tabIndex={0}
                    className={selectedTab === "trust" ? "selected" : ""}
                    onClick={() => handleTabClick("trust")}
                    onKeyDown={(e) => handleKeyDown(e, "trust")}
                  >
                    <h2 className="tab-label">TRUST</h2>
                  </li> */}
                </ul>
              </nav>
            </div>
            <br />
            <div className="renderpage">{renderPage()}</div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
