import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-wow-libraries';
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>WOW Libraries | Employee Wellbeing | Hirdaramani</title>
        <meta
          name="title"
          content="WOW Libraries | Employee Wellbeing | Hirdaramani"
        />
        <meta
          name="description"
          content="Enhance wellbeing with WOW Everyday Reading Projects. Discover our libraries with 1,000 books each, engaging over 4,700 employees in 2023."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-wow-libraries/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="WOW Libraries" />
        <meta
          property="og:title"
          content="WOW Libraries | Employee Wellbeing | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Enhance wellbeing with WOW Everyday Reading Projects. Discover our libraries with 1,000 books each, engaging over 4,700 employees in 2023."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-wow-libraries/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
