import React, { useEffect } from "react";
import "./WellbeingCharts.css";

//Charts
// import CareerDevelopmentBarChart01 from './CareerDevelopmentBarChart01';
// import CareerDevelopmentBarChart02 from './CareerDevelopmentBarChart02';
// import WellbeingPieChart01 from "./WellbeingPieChart01";
import WellbeingPieChart01 from "./Chart/Sri-Lanka";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div>
        <div className="new-wellbeing-page-hightlight-chart-container">
          <div className="new-wellbeing-page-hightlight-bolder-box-line">
            <div className="new-wellbeing-page-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Other WellBeing Highlights</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="new-wellbeing-page-hightlight-bolder-box-line-content">
              <div className="new-wellbeing-page-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Facilities with WOW</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    82% of Hirdaramani facilities now have WOW programs
                    implemented. Our target is 100% and, with the initiatives
                    expanding in Vietnam, Bangladesh, and Ethiopia, we are well
                    on track to achieving our goals.
                  </p>
                </div>
              </div>
              <div className="new-wellbeing-page-hightlight-bolder-box-line-content-right">
                <WellbeingPieChart01 />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <div className="career-development-chart-container1">
          <div className="career-development-header-contents">
            <div
              className="career-development-header-content-left"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Other WellBeing Highlights</h1>
            </div>
            <div className="career-development-header-content-right">
              <p data-aos="fade-in" data-aos-duration="2000">
                <strong>Facilities with WOW</strong>
              </p>
              <br />
              <p
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                82% of Hirdaramani facilities now have WOW programs implemented.
                Our target is 100% and, with the initiatives expanding in
                Vietnam, Bangladesh, and Ethiopia, we are well on track to
                achieving our goals.
              </p>
            </div>
          </div>
          <center>
            <h2 className="main-topic-WellbeingCharts-p">
              Percentage of Facilities with WOW Implemented
            </h2>
            <div className="career-development-bar-chart">
              <WellbeingPieChart01 />
            </div>
          </center>
        </div>
      </div> */}
    </>
  );
}

export default OtherEnvironmentalTargetsCharts;
