import React from "react";
import Page from "../templates/empower/empowerpage";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Empower Our People | Positive Work Environment | Hirdaramani
        </title>
        <meta
          name="title"
          content="Empower Our People | Positive Work Environment | Hirdaramani"
        />
        <meta
          name="description"
          content="Discover how Hirdaramani prioritizes people through empowerment initiatives. Learn about our commitment to healthy working conditions, equal opportunities, and personal wellbeing for our team."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Empower Our People" />
        <meta
          property="og:title"
          content="Empower Our People | Positive Work Environment | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Discover how Hirdaramani prioritizes people through empowerment initiatives. Learn about our commitment to healthy working conditions, equal opportunities, and personal wellbeing for our team."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
