import React, { useEffect, useState } from "react";
import "./stories.css";

// Images
import wtn_image_1 from "../../assets/image_0505.jpg";
import wtn_image_2 from "../../assets/GenderInclusiveWorkplace01.png";
import wtn_image_3 from "../../assets/WOWEducationProgrammes01.webp";

//Icons
import { MdArrowRightAlt } from "react-icons/md";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function WhereToNext() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [dataDelay, setDataDelay] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 770) {
      setDataDelay(true);
    }
  }, []);

  return (
    <div className="stories-card-section-container">
      <div>
        <div className="stories-card-section-topic">
          <h1 data-aos="fade-in" data-aos-duration="2000">
            FEATURE STORIES
          </h1>
        </div>
        <div
          className="stories-section-line"
          data-aos="fade-in"
          data-aos-duration="2000"
        ></div>
      </div>
      <center>
        <div className="stories-section-cards">
          {/* Card 1  */}
          <div
            className="stories-section-card"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
          >
            <div className="stories-section-card-image">
              <img src={wtn_image_1} alt="Roadmap" />
            </div>
            <div className="stories-section-card-content">
              <h5>Mental health and wellbeing</h5>
              <p>
                Mental health is key focus area in wellbeing objectives. With
                dedicated practitioners and on-site counselling services,
                Hirdaramani addresses this crucial area of wellbeing with the
                support of both internal and external resources.
              </p>
              <button>
                Read More{" "}
                <div className="stories-section-card-content-icon">
                  <MdArrowRightAlt size={30} />
                </div>
              </button>
            </div>
          </div>

          {/* Card 2 */}
          <div
            className="stories-section-card"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay={`${dataDelay ? "0" : "1000"}`}
          >
            <div className="stories-section-card-image">
              <img
                src={wtn_image_2}
                alt="Sustainable Apparel Coalition (SAC)"
              />
            </div>
            <div className="stories-section-card-content">
              <h5>WOW gender inclusive workplace</h5>
              <p>
                2023 witnessed the introduction of a new pilot project to
                develop gender enablers at leadership level and to formulate a
                gender inclusive policy for the group.
              </p>
              <button>
                Read More{" "}
                <div className="stories-section-card-content-icon">
                  <MdArrowRightAlt size={30} />
                </div>
              </button>
            </div>
          </div>

          {/* Card 3 */}
          <div
            className="stories-section-card"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay={`${dataDelay ? "0" : "1500"}`}
          >
            <div className="stories-section-card-image">
              <img src={wtn_image_3} alt="Employees" />
            </div>
            <div className="stories-section-card-content">
              <h5>Workplace training and education programs</h5>
              <p>
                Hirdaramani places great value on the long-term benefits of
                workplace training and education. We operate many programs to
                help employees advance their skills and careers.
              </p>
              <button>
                Read More{" "}
                <div className="stories-section-card-content-icon">
                  <MdArrowRightAlt size={30} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}

export default WhereToNext;
