import React from "react";
import "./logo-card.css";

// Import your logos
import EllenMacArthurLogo from "../../assets/2022-184.png";
import ISOLogo from "../../assets/2022-194.png";
import GRILogo from "../../assets/2022-186.png";
import USGBCLogo from "../../assets/2022-193.png";
import IRECLogo from "../../assets/2022-189.png";
import ApparelImpactLogo from "../../assets/2022-190.png";
import HiggIndexLogo from "../../assets/2022-188.png";
import ScienceBasedTargetsLogo from "../../assets/2022-191.png";
import FairTradeLogo from "../../assets/2022-185.png";
import SustainableApparelLogo from "../../assets/2022-196.png";
import RoadmapToZeroLogo from "../../assets/2022-195.png";
import BetterWorkLogo from "../../assets/2022-187.png";
import SocialLaborConvergenceLogo from "../../assets/2022-192.png";

const logos = [
  { src: EllenMacArthurLogo, alt: "Ellen MacArthur Foundation" },
  { src: ISOLogo, alt: "ISO" },
  { src: GRILogo, alt: "GRI" },
  { src: USGBCLogo, alt: "USGBC" },
  { src: IRECLogo, alt: "I-REC Standard" },
  { src: ApparelImpactLogo, alt: "Apparel Impact Institute" },
  { src: HiggIndexLogo, alt: "Higg Index" },
  { src: ScienceBasedTargetsLogo, alt: "Science Based Targets" },
  { src: FairTradeLogo, alt: "Fair Trade Certified" },
];

const logos2 = [
  { src: SustainableApparelLogo, alt: "Sustainable Apparel Coalition" },
  { src: RoadmapToZeroLogo, alt: "Roadmap to Zero" },
  { src: BetterWorkLogo, alt: "Better Work" },
  { src: SocialLaborConvergenceLogo, alt: "Social & Labor Convergence" },
];

const LogosGrid = () => {
  return (
    <div className="bv">
      <div className="logos-grid">
        {logos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
      <div className="logos-grid-2">
        {logos2.map((logo, index) => (
          <div key={index} className="logo-item-2">
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogosGrid;
