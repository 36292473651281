import React from "react";
import "./blueParagraph.css";

export default function TypewriterAnimation() {
  return (
    <div
      className="trust-section-blue-area"
      style={{ backgroundColor: "#313893", color: "#ffffff" }}
    >
      <div
        className="trust-section-paragraphs"
        id="parent"
        data-aos="fade-in"
        data-aos-once="true"
      >
        <h1 style={{ marginBottom: "20px", fontSize: "40px" }}>
          TRUST OUR CHOICES
        </h1>
        <p className="trust-section-paragraph">
          Our customers, employees, industry and communities must have trust in
          our choices and practices and know that we are making positive
          differences in our business.
        </p>
        <p className="trust-section-paragraph">
          This is why Trust is such an important pillar in our Future First
          Sustainability Roadmap 2025. We take our commitments seriously because
          we know the impact of what we do on the future.
        </p>
      </div>
    </div>
  );
}
