import React from "react";
import "./greenParagraph.css";

export default function TypewriterAnimation() {
  return (
    <div
      className="water-greenArea"
      style={{ backgroundColor: "#1E6D54", color: "#ffffff" }}
    >
      <div
        className="water-energypage-para"
        id="parent"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <p className="water-paragra">
          While apparel manufacturers have traditionally been heavy consumers of
          water, Hirdaramani is fully committed to responsible consumption of
          water.
        </p>
        <p className="water-paragr">
          We have introduced widespread measures to ensure we maintain
          environmentally sound practices to prevent any adverse effects of
          water scarcity on the communities and ecosystems where we operate.
          This is reflected in our Future First Sustainability Roadmap 2025.
        </p>
      </div>
    </div>
  );
}
