import React, { useEffect } from "react";
import "./partnerships.css";
//import Img from "../../assets/partnerships.webp";
import LogoCard from "./logo-card";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function FeatureStories() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="partnerships-stries-container">
      <div>
        <div
          className="partnerships-story-topic"
          data-aos="fade-in"
          data-aos-duration="2500"
        >
          <h1>ACCOUNTABLE PARTNERSHIPS</h1>
        </div>
        <div
          className="partnerships-story-underline"
          data-aos="fade-in"
          data-aos-duration="2500"
          data-aos-offset="-10"
        />
      </div>

      <div>
        <div className="partnerships-two-column">
          <div className="partnerships-column-1">
            <div
              className="para-partnership"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <p className="para-partnership-1">
                Our commitment to implementing sustainable and trusted choices
                and global best practices is reflected in our alignment with
                selected international frameworks and standards.
              </p>
              <p className="para-partnership-1">
                <ul>
                  <li>Apparel Impact Institute</li>
                  <li>Ellen MacArthur Foundation</li>
                  <li>GRI Standards</li>
                  <li>Higg Index</li>
                  <li>I-REC Standard</li>
                  <li>ISO 14001 and 26000</li>
                  <li>Science Based Targets Initiative</li>
                  <li>Social & Labour Convergence Programme</li>
                  <li>Sustainable Apparel Coalition</li>
                  <li>US Green Building Council</li>
                  <li>ZDHC</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="partnerships-column-2">
            {/* <img
              src={Img}
              alt=""
              className="partnerships-img"
              data-aos="fade-left"
              data-aos-duration="2000"
            /> */}
            <LogoCard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureStories;
