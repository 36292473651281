import React, { useEffect } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Background_Img from "../../../assets/childrin&education_spotlight.webp";
// import wow_gender_image_with_frame from "../../../assets/image_014-n.png";
import wow_gender_image_with_frame from "../../../assets/TEAProject1.jpg";
import wow_iskole_image from "../../../assets/image_016.jpg";
import Frame1 from "../../../assets/image_015-n.png";
import Frame2 from "../../../assets/image_012.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import "./spotlight.css";

import Vietnam from "../../../assets/vietnam-flag.png";
import Bangladesh from "../../../assets/bangladesh.png";
import SriLanka from "../../../assets/Sri_Lanka.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Spotlight() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      {/* Section 1 */}

      <div style={{ position: "relative" }}>
        <div className="spotlight-background">
          <div>
            <img
              src={Background_Img}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div className="spotlight-text-box-2">
            <div>
              <Parallax speed={10}>
                <h1 data-aos="fade-right" className="spotlight-topic-home-spot">
                  SPOTLIGHT
                </h1>
              </Parallax>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.55)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Section 2 */}
      {/* 01 */}
      <div className="children-wow-gender-section">
        <div className="children-wow-gender-section-left children-wow-gender-section-left-01">
          <img src={Frame1} alt="WOW Gender" data-aos="flip-right" />
        </div>
        <div className="children-wow-gender-section-right children-wow-gender-section-right-01">
          <div className="children-wow-gender-section-right-contents">
            <div className="flag-container">
              <div className="flag-box">
                <img src={Vietnam} alt="flag" />
              </div>
            </div>
            <h1 data-aos="fade-in">Clean drinking water for children</h1>
            <p data-aos="fade-in">
              Ensuring a clean water supply reaches the most vulnerable people
              in our local communities is a deep commitment for Hirdaramani.{" "}
              {/* <a href="/#">
                Read more about our participation in clean water projects.
              </a> */}
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/children&education/spotlight-clean-drinking-water-for-children/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 02 */}
      <div className="children-wow-gender-section">
        <div className="children-wow-gender-section-left children-wow-gender-section-left-02">
          <img src={Frame2} alt="WOW Gender" data-aos="flip-right" />
        </div>
        <div className="children-wow-gender-section-right children-wow-gender-section-right-02">
          <div className="children-wow-gender-section-right-contents">
            <div className="flag-container">
              <div className="flag-box">
                <img src={Bangladesh} alt="flag" />
              </div>
            </div>
            <h1 data-aos="fade-in">FARE - Spectra School of Autism</h1>
            <p data-aos="fade-in">
              With the aim of enabling equal opportunities for all children,
              Hirdaramani has been supporting the FARE - Spectra School of
              Autism since 2014. This year, we made significant impact by
              supporting to set up the IT infrastructure for a communications
              skills development platform{" "}
              {/* <a href="/#">
                Read more about our participation in clean water projects.
              </a> */}
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/children&education/spotlight-fare-spectra-school-of-autism/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 03 */}
      <div className="children-wow-gender-section">
        <div className="children-wow-gender-section-left children-wow-gender-section-left-03">
          <img
            src={wow_gender_image_with_frame}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="children-wow-gender-section-right children-wow-gender-section-right-03">
          <div className="children-wow-gender-section-right-contents">
            <div className="flag-container">
              <div className="flag-box">
                <img src={SriLanka} alt="flag" />
              </div>
            </div>
            <h1 data-aos="fade-in">T.E.A. Project</h1>
            <p data-aos="fade-in">
              Hirdaramani’s support for the T.E.A. Project in Sri Lanka has been
              long running and impactful in connecting with the estate working
              community.. The establishment of ‘The Cloud’ children’s
              empowerment centre in Hanthana sees the great work continue.{" "}
              {/* <a href="/#">
                Read more about our participation in clean water projects.
              </a> */}
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/children&education/spotlight-t-e-a-project/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 04 */}
      <div className="children-wow-gender-section">
        <div className="children-wow-gender-section-left children-wow-gender-section-left-04">
          <img
            src={wow_iskole_image}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="children-wow-gender-section-right children-wow-gender-section-right-04">
          <div className="children-wow-gender-section-right-contents">
            {/* <div className="flag-container">
              <div className="flag-box">
                <img src={SriLanka} alt="flag" />
              </div>
            </div> */}
            <h1 data-aos="fade-in">WOW Iskole</h1>
            <h2 data-aos="fade-in">(school based project)</h2>
            <p data-aos="fade-in">
              We look for ways to engage directly with the schools that our
              employees’ children attend to provide support beyond the
              classroom. The WOW Iskole project is a great example of helping
              some of our community’s most marginalised families.{" "}
              {/* <a href="/#">
                Read more about our participation in clean water projects.
              </a> */}
            </p>
            <div data-aos="fade-in">
              <a
                href="/support/children&education/spotlight-wow-iskole/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
