import React, { useState } from "react";
import WorldMap from "../../assets/international-worldmap4.webp";
import Srilanka from "../../assets/srilankaEmployees.svg";
import Ethiopia from "../../assets/ethiopiaEmployees.svg";
import Bangladesh from "../../assets/bangladeshEmployees.svg";
import Vietnam from "../../assets/vietnamEmployees.svg";
import "./EmpowerPieChart01.css"; // Import CSS file for styling

function EmpowerPieChart01() {
  const [selectedIndex, setSelectedIndex] = useState(0); // State to track the selected index

  const data = [16693, 16700, 11244, 1161];

  const handleImageClick = (index) => {
    setSelectedIndex(index === selectedIndex ? -1 : index); // Toggle index
  };

  return (
    <div className="employeeByCountryChart-container">
      <p className="employeeByCountryTopic">Employees by Country</p>
      <img
        src={Ethiopia}
        alt="Ethiopia"
        height={50}
        width={20}
        className="ethiopia-employeeImage"
        onClick={() => handleImageClick(3)}
      />
      {selectedIndex === 3 && (
        <div className="employee-dataNumber">{data[3]}</div>
      )}
      
      <img
        src={Vietnam}
        alt="Vietnam"
        height={50}
        width={20}
        className="vietnam-employeeImage"
        onClick={() => handleImageClick(2)}
      />
      {selectedIndex === 2 && (
        <div className="employee-dataNumber">{data[2]}</div>
      )}
      
      <img
        src={Bangladesh}
        alt="Bangladesh"
        height={50}
        width={20}
        className="bangladesh-employeeImage"
        onClick={() => handleImageClick(1)}
      />
      {selectedIndex === 1 && (
        <div className="employee-dataNumber">{data[1]}</div>
      )}
      
      <img
        src={Srilanka}
        alt="Sri Lanka"
        height={50}
        width={20}
        className="srilanka-employeeImage"
        onClick={() => handleImageClick(0)}
      />
      {selectedIndex === 0 && (
        <div className="employee-dataNumber">{data[0]}</div>
      )}
      
      {/* Render the image of World Map */}
      <img src={WorldMap} height={350} width={400} alt="World Map" className="worldmapbg-image" />
    </div>
  );
}

export default EmpowerPieChart01;
