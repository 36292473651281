import React, { useState, useRef } from "react";
import "./form.css";
import { useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function App() {
  const { setValue } = useForm();
  const formRef = useRef(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hCaptchaToken, setHCaptchaToken] = useState(null);

  const onHCaptchaChange = (token) => {
    setValue("h-captcha-response", token);
    setHCaptchaToken(token);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[0-9]{10,}$/; // Optional '+' and minimum 10 digits
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const phone = formData.get("phone");
    const email = formData.get("email");

    // Phone and email validation
    if (!validatePhoneNumber(phone)) {
      setErrorMessage(
        "Invalid phone number. Please enter a valid phone number."
      );
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Invalid email address. Please enter a valid email.");
      return;
    }

    // HCaptcha validation
    if (!hCaptchaToken) {
      setErrorMessage("Please complete the captcha.");
      return;
    }

    try {
      formData.append("subject", "Hirdramani Sustainability Contacts Us Form");
      formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage("");

          // Reset form fields after successful submission
          formRef.current.reset();
          setHCaptchaToken(null); // Reset the captcha token

          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage("");
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="form-container">
      <form
        onSubmit={onSubmit}
        ref={formRef}
        id="hirdaramanisustainabilitycontactform"
      >
        <div className="form-row">
          <div className="form-group">
            <label className="contact-form">Name:</label>
            <input type="text" name="name" required className="inputStyle" />
          </div>
          <div className="form-group">
            <label className="contact-form">Phone Number:</label>
            <input
              type="tel"
              name="phone"
              required
              className="inputStyle"
              pattern="^\+?[0-9]*$" // Only allows numbers and optional '+'
              onKeyPress={(e) => {
                // Prevent non-numeric characters except for '+'
                if (!/[0-9+]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <label className="contact-form">Email:</label>
        <input type="email" name="email" required className="inputStyle" />
        <label className="contact-form">Enter your Inquiry:</label>
        <textarea
          name="inquiry"
          rows="9"
          required
          className="textareaStyle"
        ></textarea>

        <label className="contact-form">
          Please verify that you are human:
        </label>
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={onHCaptchaChange}
          required
        />

        {/* <input
          type="hidden"
          name="ccemail"
          value="priyankara@3cs.solutions"
        /> */}
        <input
          type="hidden"
          name="ccemail"
          value="imanthi.perera@hirdaramani.com; manindri.bandaranayake@hirdaramani.com; admin@3cs.lk"
        />
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <input type="checkbox" name="botcheck" style={{ display: "none" }} />
        <input name="from_name" type="hidden" value="H Sustainability" />
        <input
          name="subject"
          type="hidden"
          value="Hirdramani Sustainability Contacts Us Form"
        />

        <div className="form-footer">
          <button type="submit" className="btn submitButton btn-lg" id="button">
            Submit
          </button>
          <div style={{ height: "20px", marginTop: "10px" }}>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default App;
