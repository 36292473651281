// import React, { useRef, useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";
// import { useInView } from "react-intersection-observer";

// function EmpowerBarChart01() {
//   const chartRef = useRef(null);
//   const [chartInView, setChartInView] = useState(false);
//   const [ref, inView] = useInView({
//     triggerOnce: true,
//     rootMargin: "-100px 0px",
//   });

//   useEffect(() => {
//     if (inView) {
//       setChartInView(true);
//     }
//   }, [inView]);

//   const data = [0, 0, 538];

//   const options = {
//     chart: {
//       type: "bar",
//       height: 350,
//       toolbar: {
//         show: false,
//       },
//     },
//     colors: ["#15D699", ],
//     dataLabels: {
//       enabled: false,
//     },
//     grid: {
//       show: false,
//     },
//     title: {
//       text: ["No of Injuries by",'type Groupwide'],
//       align: "center",
//       style: {
//         fontSize: "20px",
//         fontFamily: "GT America Trial Md",
//         color: "#313893",
//       },
//     },
//     xaxis: {
//       categories: [
//         "Fatalities",
//         "High Consequence",
//         'Non High Consequence'
//       ],
//       labels: {
//         style: {
//           fontFamily: "GT America Trial Md",
//           colors: "#313893",
//         },
//       },
//       axisBorder: {
//         show: true,
//         color: "#313893",
//         offsetX: 0,
//       },
//     },
//     yaxis: [
//       {
//         max: 600,
//         axisBorder: {
//           show: true,
//           color: "#313893",
//         },
//         labels: {
//           style: {
//             fontFamily: "GT America Trial Md",
//             colors: "#313893",
//           }
//         },
//         tooltip: {
//           enabled: false,
//         },
//       }
//     ],
//     tooltip: {
//       enabled: false,
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: '90%',
//             zoom: {
//               enabled: false
//             }
//           },
//           legend: {
//             position: "bottom",
//             width: "100%",
//           },
//           title: {
//             style: {
//               fontSize: "14px",
//               width: "100%",
//             },
//           },
//         },
//       },
//       {
//         breakpoint: 400,
//         options: {
//           chart: {
//             width: '100%',
//           },
//           title: {
//             style: {
//               fontSize: "14px",
//             },
//           },
//         },
//       },
//     ],
//   };

//   const series = [{
//     name: 'Series 1',
//     data: data
//   }];

//   return (
//     <div ref={ref}>
//       <div>
//         {chartInView && (
//           <ReactApexChart ref={chartRef} options={options} series={series} type="bar" height={450} width={"100%"}/>
//         )}
//       </div>
//     </div>
//   );
// }

// export default EmpowerBarChart01;



import React from 'react';
import './CareerDevelopmentSingleBarChart01.css';

function EmpowerBarChart01() {
  return (
    <div>
      <p className='groupWideInjuryPara'>Main types of work related injuries - Groupwide</p>
      <div className="boxWrapper"> 
        <div className="blueBox a">
          <div className="backgroundImageContainer1"></div>
        </div>
        <div className="blueBox b">
          <div className="backgroundImageContainer2"></div>
        </div>
        <div className="blueBox c">
          <div className="backgroundImageContainer3"></div>
        </div>
        <div className="blueBox d">
          <div className="backgroundImageContainer4"></div>
        </div>
        <div className="blueBox e">
          <div className="backgroundImageContainer5"></div>
        </div>
        <div className="blueBox f">
          <div className="backgroundImageContainer6"></div>
        </div>
        <div className="blueBox g">
          <div className="backgroundImageContainer7"></div>
        </div>
        <div className="blueBox h">
          <div className="backgroundImageContainer8"></div>
        </div>
        <div className="blueBox i">
          <div className="backgroundImageContainer9"></div>
        </div>
      </div>
    </div>
  );
}

export default EmpowerBarChart01;

