import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import "./homepage.css";
import HomeVideo from "../../assets/HomePageMainVideo.webm";
import MarqureePart from "../../components/marquree";
import MenuCard from "./menu-card";
//import WhereToNext from "./where-to-next";
import Carousal from "./carousal";
import Pillars from "./pillars";
import Highlight from "./highlights";
//import HighlightLine from "./highlights-line"
import SustainabilityReport from "./sustainability_report";
import SquiggleSVG from "./SquiggleSVG";
import PopupMessage from "./PopupMessage";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //For Popup Message
  const [popUpState, setPopUpState] = useState(false);

  useEffect(() => {
    const svg = document.querySelector("svg.squiggle");
    const path = svg?.querySelector("path");

    if (!svg || !path) {
      console.error("SVG or path element not found.");
      return;
    }

    const scroll = () => {
      const distance = window.scrollY;
      const totalDistance = svg.clientHeight - window.innerHeight;
      const percentage = distance / totalDistance;
      const pathLength = path.getTotalLength();

      path.style.strokeDasharray = `${pathLength}`;
      path.style.strokeDashoffset = `${pathLength * (1 - percentage)}`;
    };

    scroll();
    window.addEventListener("scroll", scroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <div className="screen-page">
      <Header />
      
      {/* section 01 */}
      {/* home video */}
      <div style={{ position: "relative" }}>
        <div className="home-page-video">
          <div>
            <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video>
          </div>
        </div>

        {/* <div className="pc">
          <div className="video-text-box">
            <div className="video-background-text">
              <h1 className="video-topic-home">
                In 2006 <br />
                We set things in motion
              </h1>
            </div>
          </div>
        </div> */}

        {/* <div className="mobile">
          <div className="video-text-box">
            <div className="video-background-text">
              <h1 className="video-topic-home">
                In 2006 <br />
                We set things
                <br />
                in motion
              </h1>
            </div>
          </div>
        </div> */}

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Menu */}
      <center>
        <div
          style={{
            marginTop: "140px",
            marginBottom: "50px",
            width: "90%",
          }}
        >
          <MenuCard setPopUpState={setPopUpState} />
        </div>
        <SquiggleSVG />
      </center>

      {/* {Pop-Up Message Section} */}
      <PopupMessage setPopUpState={setPopUpState} popUpState={popUpState} />

      {/* section 03 */}
      <SustainabilityReport />

      {/* section 04 */}
      <Pillars />

      {/* section 05 */}
      <Highlight />
      {/* <HighlightLine /> */}

      {/* section 06 */}
      {/* where to next */}
      {/* <WhereToNext /> */}

      {/* section 07*/}
      <Carousal />
      
      {/* last section */}
      <MarqureePart />
    </div>
  );
}
