import React, {useState} from "react";
import CompanyLogo from "../assets/logo-white-2.webp";
import "./logo-2.css";
import { IoMdSearch } from "react-icons/io";
import PopupSearchBar from './PopupSearchBar'

export default function Logo() {

  //For Popup Search
  const [popUpState, setPopUpState] = useState(false);

  return (
    <div style={{ width: "100%", position: "relative"}}>
      <img src={CompanyLogo} alt="Company Logo" className="company-logo-2" />
      <div 
        className="search-icon-home" 
        onClick={() => {
          setPopUpState(true);
        }}>
        <IoMdSearch className="search-icon-home-svg" size={45} color="#fff" />
      </div>
      
      {/* {Pop-Up Message Section} */}
      <PopupSearchBar setPopUpState={setPopUpState} popUpState={popUpState} />
    </div>
  );
}
