import React, { useEffect } from "react";
import Header from "../../components/header";
import "./conservepage.css";
import HomeVideo from "../../assets/DSC00026.webp";
import FeatureStories from "./FeatureStories";
import FlipCard from "./FlipCard";
import OtherEnvironmentalTargetsCharts from "./OtherEnvironmentalTargetsCharts";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import BlueParagraph from "./blueParagraph";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="conserve-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="conserve-topic-home">Conserve</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <BlueParagraph />

        {/* section-3 */}
        <div className="conserve-card">
          <div className="para-box">
            <div data-aos="fade-up-left" data-aos-duration="500">
              <h2 className="para-one-1">
                In our Future First roadmap, we concentrate
              </h2>
              <h2 className="para-one-2">
                our conservation efforts on four main areas:
              </h2>
            </div>
            <div data-aos="zoom-in">
              <h1 className="para-two">CONSERVE</h1>
            </div>
            <div className="flip-part-new">
              <FlipCard />
            </div>
          </div>
        </div>

        {/* section-4 */}
        <OtherEnvironmentalTargetsCharts />

        {/* section-5 */}
        <FeatureStories />
      </div>
    </ParallaxProvider>
  );
}
