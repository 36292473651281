import React, { useEffect } from "react";
import "./wow-iskole.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/WOW_iskole_background.jpg";
import Blue_Background from "../../../../assets/image_013.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="childrin-education-p2-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="childrin-education-p2-topic-home"
                  >
                    wow
                    <br />
                    iskole
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="childrin-education-p2-contents">
          <div className="childrin-education-p2-content-left">
            <div className="childrin-education-p2-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="childrin-education-p2-content-left-r"></div>
          </div>
          <div className="childrin-education-p2-content-right">
            {/* <h1 className="childrin-education-p4-content-right-heading">
              WOW Community Efforts
            </h1> */}
            <h1 className="childrin-education-p4-content-right-heading-main">
              WOW Community Efforts
            </h1>
            <h1 className="childrin-education-p4-content-right-heading-sub">
              WOW Iskole (school-based project)
            </h1>
            <div
              className="childrin-education-p4-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                A central part of the Wonders of Wellbeing program is our vision
                of a WOW Child who is capable of facing real-life challenges
                with courage, perseverance, and positivity. This is the aim of
                the WOW Iskole project, a community-level program that strives
                to create real change.
              </p>
              <p>
                As part of this project, we engaged with two local Sri Lankan
                schools (Dapiligoda Primary School and Yatiyana Kanishta
                Vidyalayaigoda Primary School) for activities such as:
              </p>
              <ul>
                <li>
                  Skills education program – seven modules on life skills, moral
                  skills, and educational skills, delivered as monthly programs
                </li>
                <li>Leadership training programs</li>
                <li>School gardening program</li>
                <li>
                  Introducing principles and concepts to teach organisational
                  skills
                </li>
                <li>Wellbeing education for teachers and school staff.</li>
              </ul>
              <p>
                The program reached 1,738 children as well as trained 40
                teachers to help create a more sustainable impact in the school
                community ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
