import React, { useRef } from 'react'
import './PopupSearchBar.css'
import GoogleCSE from "./GoogleCSE";

// Icons
import { MdOutlineClose } from "react-icons/md";

function PopupMessage({popUpState, setPopUpState}) {

    const searchBox = useRef(null);
    const closeSearchBox = (e)=>{
        if(e.target === searchBox.current){
            setPopUpState(false);
        }
    }

    const closeSearchBoxByBtn = ()=>{
        setPopUpState(false)
    }

  return (
    <div className={`search-box ${popUpState?'':'hide'}`} ref={searchBox} onClick={closeSearchBox}>
        <div className='pop-up-search-bar'>
            <div className="pop-up-search-bar-header">
                <div className="pop-up-search-bar-title">
                    <h3>Search</h3>
                </div>
                <div className="pop-up-search-bar-close-icon">
                    <MdOutlineClose size={30} onClick={closeSearchBoxByBtn} />
                </div>
                
            </div>
            <center className='pop-up-search-bar-center-tag'>
                <div className="pop-up-search-bar-contents">
                    <GoogleCSE  />
                </div>
            </center>
        </div>
    </div>
  )
}

export default PopupMessage
