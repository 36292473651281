import React from "react";
//import { Helmet } from "react-helmet";
import Page from "../components/cookies-policy";

export default function CookiesPolicy() {
  return (
    <div>
      
      <Page />
    </div>
  );
}
