import React, { useEffect } from "react";
import "./SpotLight.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/wow_gender_background_02.jpg";
//import Blue_Background from "../../../../assets/wow_gender_image_with_blue_background_02.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 20%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="spotlight-topic-home">
                    WOW gender <br />
                    inclusive <br />
                    workplace
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left">
            <div className="spotlight-content-left-l equality-spotlight-left-l">
              {/* <img src={Blue_Background} alt="Blue Shape" data-aos="flip-right"/> */}

              <div className="equality-spotlight-content-left-l-content">
                <div className="b-cr">
                  <p data-aos="fade-in" data-aos-delay="1000">
                    Under our successful and globally recognized Wonders of
                    Wellbeing (WOW) program, Hirdaramani introduced a new pilot
                    project across two Sri Lankan facilities in 2023 aimed at
                    developing ‘gender enablers’ at leadership level. With the
                    support of the Board, the project also aimed to create and
                    implement a gender inclusive policy for the Group.
                  </p>
                </div>
              </div>
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">WOW</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                Over 300 leaders participated in workshops and became certified
                gender enablers. Additional programs were undertaken on:
              </p>
              <ul>
                <li>unveiling unconscious bias</li>
                <li>
                  eliminating stereotypes in the hiring, promotion, and
                  performance evaluation process
                </li>
                <li>creating more conducive facilities for females.</li>
              </ul>
              <p>
                In March 2023, a month-long initiative was run across all
                facilities in Sri Lanka aimed at having deeper discussions
                around gender issues in the workplace, at home, and in society
                in general.
              </p>
              <p>This program included:</p>
              <ul>
                <li>coaching on responsible communication and Behavior</li>
                <li>
                  self-assessment tool to associates of all genders to provide a
                  deeper level of understanding on the empowerment of women in
                  their lives
                </li>
                <li>
                  provision of smart phones via the Fairtrade fund to women to
                  facilitate easier communication with their family members.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
