import React, { useEffect } from "react";
import "./ChemicalTargetCharts.css";

//Charts
import ChemicalTargetChart01 from "./charts/chart-1/chart";
import ChemicalTargetChart02 from "./charts/chart-2/chart";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function ChemicalTargetCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="new-chemical-cha-hightlight-chart-container">
          <div className="new-chemical-cha-hightlight-bolder-box-line">
            <div className="new-chemical-cha-hightlight-header-contents">
              <div
                className="new-conserve-header-content-left-2"
                data-aos="flip-down"
                data-aos-duration="2000"
              >
                <h1>Chemical target highlights</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="new-chemical-cha-hightlight-bolder-box-line-content">
              <div className="new-chemical-cha-hightlight-bolder-box-line-content-left">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Target E12: Phase out hazardous chemicals</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Hirdaramani has adopted several effective chemical
                    management practices to minimise our environmental
                    footprint. Throughout our operations, the majority of
                    chemicals are screened under strict safety standards to
                    ensure the safety of both the environment and human health.
                    <br />
                    <br />
                    In 2023, a total 83.3% of chemicals used were screened, with
                    zero hazardous chemicals being used in H – Group. We are
                    keen to achieve our target of phasing out all hazardous
                    chemicals across our footprint, and our teams are working
                    with dedication towards this end.
                  </p>
                </div>
              </div>
              <div className="new-chemical-cha-hightlight-bolder-box-line-content-right">
                <center>
                  <ChemicalTargetChart01 />
                </center>
              </div>
            </div>
          </div>
          {/* // */}
          <div>
            <div className="new-chemical-cha-hightlight-bolder-box-line-content">
              <div className="new-chemical-cha-hightlight-bolder-box-line-content-left">
                <div>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    <i>
                      Note: Vietnam has completely eliminated the use of pumice
                      stones. Ethiopia does not have washing facilities and
                      therefore, does not have this target under their
                      Sustainability scope.
                    </i>
                    <br />
                    <br />
                    In 2023, we have reduced pumice stone usage by over 50% when
                    compared to 2022.
                    <br />
                    <br />
                    Our success has been due to a combination of collaborating
                    with brands, implementing new technologies, and switching
                    over to using synthetic stones as a replacement for pumice
                    stones. In Vietnam, our laundry facilities already use 100%
                    synthetic stones and as a result, have successfully achieved
                    this target.
                  </p>
                </div>
              </div>
              <div className="new-chemical-cha-hightlight-bolder-box-line-content-right">
                <center>
                  <ChemicalTargetChart02 />
                </center>
              </div>
            </div>
          </div>
          {/* // */}
          <div>
            <div className="new-chemical-cha-hightlight-bolder-box-line-content">
              <div className="new-chemical-cha-hightlight-bolder-box-line conserve-chemical-chart-03">
                <div>
                  <h4 data-aos="fade-in" data-aos-duration="2000">
                    <strong>Target E15: ZDHC Aspirational Level</strong>
                  </h4>
                  <br />
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    At Hirdaramani, we have made a commitment to follow the ZDHC
                    Roadmap towards leadership in sustainable chemical
                    management. The platform enables companies such as ours to
                    take ownership of the improvement of their chemical
                    management performance.
                    <br />
                    <br />
                    As of 31st December 2023, Hirdaramani was largely at the
                    ZDHC Progressive Level, with our facilities in Sri Lanka and
                    Bangladesh achieving Progressive level and our facilities in
                    Vietnam achieving Foundational level. From this point on, we
                    hope to transition to the Aspirational Level by 2025, in
                    line with our Future First Sustainability Roadmap ambitions.
                  </p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="new-chemical-chart-container-2">
        <div className="new-chemical-header-contents">
          <div
            className="new-chemical-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Chemical <br />
              target <br /> highlights
            </h1>
          </div>
          <div className="new-chemical-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target E12: Phase out hazardous chemicals</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              Hirdaramani has adopted several effective chemical management
              practices to minimise our environmental footprint. Throughout our
              operations, the majority of chemicals are screened under strict
              safety standards to ensure the safety of both the environment and
              human health.
              <br />
              <br />
              In 2023, a total 83.3% of chemicals used were screened, with zero
              hazardous chemicals being used in H – Group. We are keen to
              achieve our target of phasing out all hazardous chemicals across
              our footprint, and our teams are working with dedication towards
              this end.
            </p>
          </div>
        </div>
        <center>
          <div className="new-chemical-bar-chart">
            <ChemicalTargetChart01 />
          </div>
        </center>

        
        <div className="new-chemical-header-contents">
          <div className="new-chemical-header-content-left"> </div>
          <div className="new-chemical-header-content-right new-chemical-chart-margin-bottom">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>E13: Elimination of pumice stones</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              <i>
                Note: Vietnam has completely eliminated the use of pumice
                stones. Ethiopia does not have washing facilities and therefore,
                does not have this target under their Sustainability scope.
              </i>
              <br />
              <br />
              In 2023, we have reduced pumice stone usage by over 50% when
              compared to 2022.
              <br />
              <br />
              Our success has been due to a combination of collaborating with
              brands, implementing new technologies, and switching over to using
              synthetic stones as a replacement for pumice stones. In Vietnam,
              our laundry facilities already use 100% synthetic stones and as a
              result, have successfully achieved this target.
            </p>
          </div>
        </div>
        <center>
          <div className="new-chemical-bar-chart">
            <ChemicalTargetChart02 />
          </div>
        </center>

        
        <div className="new-chemical-header-contents">
          <div className="new-chemical-header-content-left"> </div>
          <div className="new-chemical-header-content-right new-chemical-chart-margin-bottom">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Target E15: ZDHC Aspirational Level</strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              At Hirdaramani, we have made a commitment to follow the ZDHC
              Roadmap towards leadership in sustainable chemical management. The
              platform enables companies such as ours to take ownership of the
              improvement of their chemical management performance.
              <br />
              <br />
              As of 31st December 2023, Hirdaramani was largely at the ZDHC
              Progressive Level, with our facilities in Sri Lanka and Bangladesh
              achieving Progressive level and our facilities in Vietnam
              achieving Foundational level. From this point on, we hope to
              transition to the Aspirational Level by 2025, in line with our
              Future First Sustainability Roadmap ambitions.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ChemicalTargetCharts;
