import React, { useEffect } from "react";
import Header from "../../components/header";
import "./trustpage.css";
import HomeVideo from "../../assets/trust_background.webp";
import Partnerships from "./partnerships";
import BlueParagraph from "./blueParagraph";
import TrustHightlightCharts from "./trust-hightlight-charts";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Tpage from "./unsdg";
import FeatureStories from "./feature-stories";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="trust-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="trust-topic-home">Trust</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <BlueParagraph />

        {/* section-4 */}
        <TrustHightlightCharts />

        {/* section-5 */}
        <Partnerships />

        <Tpage />

        <FeatureStories />
      </div>
    </ParallaxProvider>
  );
}
