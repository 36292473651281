import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import './chart.css';
import Content01 from "./group_2023_01";
import Content02 from './group_2023_02';

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box-ban">
          <div className="conserve-water-chart-03-card-container">
            <div className="conserve-water-chart-03-card" style={{ width: "120px" }}>
              <center>
                <div className="conserve-water-chart-03-number number-content-01">
                  {counterOn && (<CountUp start={0} end={72.45} duration={3} decimals={2} delay={0.3} />)}
                </div>
                <div>
                  <Content01 />
                </div>
              </center>
            </div>

            <div className="conserve-water-chart-03-card" style={{ width: "120px" }}>
              <center>
                <div className="conserve-water-chart-03-number number-content-02">
                  {counterOn && (<CountUp start={0} end={  74.08 } duration={3} decimals={2} delay={0.3} />)}
                </div>
                <div>
                  <Content02 />
                </div>
              </center>
            </div>

          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
