import React from "react";
import "./blueParagraph.css";

export default function TypewriterAnimation() {
  

  return (
    <div
      // className="blueArea"
      style={{ backgroundColor: "#313893", color: "#ffffff" }}
    >
          <div
            className="conservepage-para"
            id="parent"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <h1 style={{ marginBottom: "20px", marginTop:"20px" }}>CONSERVE THE ENVIRONMENT</h1>
            <p className="parag">
              We all share one world. Taking care of it is everyone’s responsibility. 
                <br />
              Hirdaramani doesn’t shy away from these responsibilities. We never have and never will. 
              Sustainability has always been an important part of our business and is deeply ingrained in our DNA. 
              It has been a rewarding journey from the very beginning, but it transitioned into a greater mission in 2006 when we developed 
              our first environmental roadmap. 

            </p>
            <p className="parag">
              Within the global apparel industry, we’re encouraged to see manufacturers and brands embrace 
              more sustainable practices as part of an evolving journey. Similarly, we are constantly exploring new technologies 
              and adapting our processes to meet these advancing standards.
            </p>
            <p className="parag">
              From the way we manufacture to the products and solutions that we create, we hold ourselves 
              accountable to a set of 
              global standards designed to foster the sustainability of our planet.
            </p>
          </div>
    </div>
  );
}
