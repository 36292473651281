import React from "react";
import Page from "../templates/support/supportpage";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Community Support Initiatives | Social Responsibility | Hirdaramani
        </title>
        <meta
          name="title"
          content="Community Support Initiatives | Social Responsibility | Hirdaramani"
        />
        <meta
          name="description"
          content="Learn how Hirdaramani supports local communities through various initiatives. Our focus on education, health, and environmental projects reflects our dedication to making a difference."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Community Support Initiatives" />
        <meta
          property="og:title"
          content="Community Support Initiatives | Social Responsibility | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Learn how Hirdaramani supports local communities through various initiatives. Our focus on education, health, and environmental projects reflects our dedication to making a difference."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
