import React from "react";
import "./greenParagraph.css";

export default function TypewriterAnimation() {

  return (
    <div
      className="greenArea"
      style={{ backgroundColor: "#1E6D54", color: "#ffffff" }}
    >
      <div
        className="energypage-green-para"
        id="parent"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <p className="paragra">
          Reducing our carbon footprint is more than just a key goal. It drives
          the way we think and innovate as an apparel manufacturer.
        </p>
        <p className="paragr">
          We are on a clear mission to explore and find new ways to be more
          energy efficient across our operations. We recognise the impact that
          our work has on the environment and our Future First roadmap has
          specific energy targets designed to meet our sustainability goals.
        </p>
      </div>
    </div>
  );
}
