import React from 'react'
import Page from '../templates/empower/wellbeing/spotlight/SpotLight-healthy-and-positive-relationships-program';
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          HPRP | Healthy and Positive Relationship Program | Hirdaramani
        </title>
        <meta
          name="title"
          content="HPRP | Healthy and Positive Relationship Program | Hirdaramani"
        />
        <meta
          name="description"
          content="Hirdaramani tackles family-related absenteeism and domestic violence with educational campaigns. Learn about fostering healthy relationships for a sustainable workforce."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-healthy-and-positive-relationships-program/"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          name="keywords"
          content="Healthy and Positive Relationship Program"
        />
        <meta
          property="og:title"
          content="HPRP | Healthy and Positive Relationship Program | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Hirdaramani tackles family-related absenteeism and domestic violence with educational campaigns. Learn about fostering healthy relationships for a sustainable workforce."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/wellbeing/spotlight-healthy-and-positive-relationships-program/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
