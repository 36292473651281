import React, { useState } from "react";
import { Link } from "react-router-dom";
import SriLanka from "./srilanka";
import Bangladesh from "./banglades";
import Vietnam from "./vietnam";
import Ethiopia from "./ethiopia";
import "./chart-4.css";

// import srilanka from "../../../../assets/Sri-Lanka-2.png";
// import banglades from "../../../../assets/bangladesh.png";
// import vietnam from "../../../../assets/vietnam.png";
// import ethiopia from "../../../../assets/Ethiopia-flag.png";

export default function Highlights() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "srilanka":
        return <SriLanka />;
      case "banglades":
        return <Bangladesh />;
      case "vietnam":
        return <Vietnam />;
      case "ethiopia":
        return <Ethiopia />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        {/* <h2 className="main-topic-chart-4-p">
          name
        </h2> */}
        <br />
        <div className="empower-chart-4-paragraph-two-column empower-chart-04">
          <div className="empower-chart-4-paragraph-column-1">
            <center>
              <h1 className="topic-chart-4-p empower-chart-04">Country</h1>
            </center>
          </div>
          <div className="empower-chart-4-paragraph-column-2">
            <div className="e-pc">
              <div className="chart-4-country-list">
                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "srilanka" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("srilanka")}
                  onKeyDown={(e) => handleKeyDown(e, "srilanka")}
                >
                  {/* <img src={srilanka} alt="1" /> */}
                  <h3 className={selectedTab === "srilanka" ? "selected" : ""}>
                    Sri Lanka
                  </h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "banglades" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("banglades")}
                  onKeyDown={(e) => handleKeyDown(e, "banglades")}
                >
                  {/* <img src={banglades} alt="2" /> */}
                  <h3 className={selectedTab === "banglades" ? "selected" : ""}>
                    Bangladesh
                  </h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "vietnam" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("vietnam")}
                  onKeyDown={(e) => handleKeyDown(e, "vietnam")}
                >
                  {/* <img src={vietnam} alt="3" /> */}
                  <h3 className={selectedTab === "vietnam" ? "selected" : ""}>
                    Vietnam
                  </h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "ethiopia" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("ethiopia")}
                  onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
                >
                  {/* <img src={ethiopia} alt="4" /> */}
                  <h3 className={selectedTab === "ethiopia" ? "selected" : ""}>
                    Ethiopia
                  </h3>
                </Link>
              </div>
            </div>

            <div className="e-mobile">
              <div className="chart-4-country-list">
                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "srilanka" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("srilanka")}
                  onKeyDown={(e) => handleKeyDown(e, "srilanka")}
                >
                  {/* <img src={srilanka} alt="1" /> */}
                  <h3 className={selectedTab === "srilanka" ? "selected" : ""}>
                    Sri Lanka
                  </h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "banglades" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("banglades")}
                  onKeyDown={(e) => handleKeyDown(e, "banglades")}
                >
                  {/* <img src={banglades} alt="2" /> */}
                  <h3>Bangladesh</h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "vietnam" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("vietnam")}
                  onKeyDown={(e) => handleKeyDown(e, "vietnam")}
                >
                  {/* <img src={vietnam} alt="3" /> */}
                  <h3 className={selectedTab === "vietnam" ? "selected" : ""}>
                    Vietnam
                  </h3>
                </Link>

                <Link
                  tabIndex={0}
                  className={`chart4-flag ${
                    selectedTab === "ethiopia" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("ethiopia")}
                  onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
                >
                  {/* <img src={ethiopia} alt="4" /> */}
                  <h3 className={selectedTab === "ethiopia" ? "selected" : ""}>
                    Ethiopia
                  </h3>
                </Link>
              </div>
            </div>

            <br />
          </div>
        </div>

        <div>{renderPage()}</div>
      </div>
    </div>
  );
}
