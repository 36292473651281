import React from "react";
import Page from "../templates/empower/career-development/career-development-page";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Career Development | Training and Development | Hirdaramani
        </title>
        <meta
          name="title"
          content="Career Development | Training and Development | Hirdaramani"
        />
        <meta
          name="description"
          content="Learn how Hirdaramani advances careers through career development programs. Discover our goals to reach supervisors and executives by 2025."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/career-development/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Career Development" />
        <meta
          property="og:title"
          content="Career Development | Training and Development | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Learn how Hirdaramani advances careers through career development programs. Discover our goals to reach supervisors and executives by 2025."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/career-development/"
        />
      </Helmet>

      <Page />
    </div>
  );
}
