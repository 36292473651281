import React, { useEffect } from "react";
import "./feature-stories.css";
import Img1 from "../../assets/natural-dyes2.jpeg";
import Img2 from "../../assets/science-based-targets-initiative2.jpeg";
import Img3 from "../../assets/development-of-sustainable-styles2.jpeg";
import Img4 from "../../assets/circular-jeans2.webp";
import Img5 from "../../assets/industry-efforts-towards-traceability2.jpeg";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function FeatureStories() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="trust-feature-stries-container">
      <div>
        <div
          className="trust-feature-story-topic"
          data-aos="fade-in"
          data-aos-duration="2500"
        >
          <h1>FEATURE STORIES</h1>
        </div>
        <div
          className="trust-feature-story-underline"
          data-aos="fade-in"
          data-aos-duration="2500"
        />
      </div>

      {/* 01 */}
      <div>
        <center className="trust-feature-stories-container">
          <div className="trust-feature-story-para">
            <div className="feature-two-column">
              <div className="feature-column-1">
                <h2
                  className="feature-main-topic"
                  data-aos="fade-in"
                  data-aos-duration="2500"
                >
                  NATURAL DYES
                </h2>
                <div data-aos="fade-up" data-aos-duration="2500" className="feature-main-topic-paras">
                  <p>
                    For several years, the apparel industry has been experimenting
                    with natural dyes as a safer, cleaner option for the
                    environment, primarily due to the fact that they do not use
                    heavy metal mordants. While there have been several innovative
                    options from suppliers proposing botanical and mineral based
                    natural dyes, the big challenge has always been in meeting
                    industry level test criteria. The general requirement has been
                    for colour fastness to light to be above level 4, where
                    natural dyes tend to range between 2.5 and 3, preventing their
                    use on a commercial scale.
                  </p>
                  <p>
                    At Hirdaramani, our product development team continued to
                    research this area, finding new suppliers and trialing
                    different products, and in 2023, we had our first major
                    breakthrough when one of our key customers approved their
                    first natural dye style for bulk production.
                  </p>
                  <p>
                    This was a strong collaboration between the companies with
                    their style and our natural dye methodology. Understanding the
                    limitations of natural dye in terms of colour fastness, our
                    customer took the leap in agreeing to a light fastness level
                    of 3.5, a significant step in the direction of the industry.
                    Following this milestone, our customer will be including more
                    styles with natural dyes in the upcoming seasons.{" "}
                  </p>
                  <p>
                    This case study highlights the opportunity the industry has to
                    re-evaluate compliance criteria and testing standards, and we
                    aim to adopt more sustainable and ethical dyes and chemicals
                    as part of our progressive journey.
                  </p>
                  <p>
                    The dyes we used for this project were Natural Pigment Dye
                    extracts from wood waste which is Certified by FSC (Forest
                    Stewardship Council). These include ZDHC, OECKO TEX ECO
                    PASSPORT, and USDA certified biobased products. They are free
                    of fossil fuels, are traceable, and come from renewable
                    sources.
                  </p>
                </div>
              </div>
              <div className="feature-column-2">
                <img
                  src={Img1}
                  alt=""
                  className="feature-stories-img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </center>
      </div>

      <div>
        <center className="trust-feature-stories-container">
          <div className="trust-feature-story-para">
            <div className="feature-two-column">
              <div className="feature-column-3">
                <img
                  src={Img2}
                  alt=""
                  className="feature-stories-img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
              <div className="feature-column-4">
                <h2
                  className="feature-sub-topic"
                  data-aos="fade-in"
                  data-aos-duration="2500"
                >
                  SCIENCE BASED TARGEST INITIATIVE (SBTI)
                </h2>
                <div data-aos="fade-up" data-aos-duration="2500" className="feature-main-topic-paras">
                  <p>
                    We have pledged to establish near-term goals and net-zero
                    emissions targets within the Science Based Targets Initiative
                    (SBTi) framework, and these targets have been set and shared
                    for verification in 2023.
                  </p>
                  <p>
                    We aim to integrate these goals to our Future First
                    Sustainability Roadmap 2025, but progress is subject to
                    receiving target verification from SBTi.
                  </p>
                  <p>
                    The SBTi is a global collaboration between CDP (Carbon
                    Disclosure Project), United Nations Global Compact, World
                    Resources Institute, and World-Wide Fund for Nature, which
                    supports companies in creating tangible goals to reduce their
                    emissions in line with the Paris Agreement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      <div>
        <center className="trust-feature-stories-container">
          <div className="trust-feature-story-para">
            <div className="feature-two-column">
              <div className="feature-column-1">
                <h2
                  className="feature-sub-topic"
                  data-aos="fade-in"
                  data-aos-duration="2500"
                >
                  DEVELOPMENT OF SUSTAINABLE STYLES
                </h2>
                <div data-aos="fade-up" data-aos-duration="2500" className="feature-main-topic-paras">
                  <p>
                    As part of our target to use more sustainable materials in the
                    production process, our product development teams are
                    continuously researching and testing new materials to present
                    to customer teams.
                  </p>
                  <p>
                    In 2023, a number of our customers adopted some of these
                    materials to develop specific styles and products to market.
                    These included:
                  </p>
                  <p>
                    <ul>
                      <li>
                        Natural dyed - 1 style
                      </li>
                      <li>
                        Regenerative cotton - 19 styles
                      </li>
                      <li>
                        Hemp - 61 styles
                      </li>
                      <li>
                        Recycle polyester - 130 styles
                      </li>
                      <li>
                        Recycle Nylon – 54 styles
                      </li>
                      <li>
                        Recycled cotton – 34 styles
                      </li>
                      <li>
                        CMIA cotton – 34 styles
                      </li>
                      <li>
                        Local BCI cotton – 2 styles
                      </li>
                      <li>
                        Organic cotton – 38 styles
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="feature-column-2">
                <img
                  src={Img3}
                  alt=""
                  className="feature-stories-img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* 02 */}
      <div>
        <center className="trust-feature-stories-container">
          <div className="trust-feature-story-para">
            <div className="feature-two-column">
              <div className="feature-column-1">
                <h2
                  className="feature-main-topic"
                  data-aos="fade-in"
                  data-aos-duration="2500"
                >
                  CIRCULAR JEANS
                </h2>
                <div data-aos="fade-up" data-aos-duration="2500" className="feature-main-topic-paras">
                  <p>
                    In 2019, Hirdaramani joined the Ellen Macarthur Foundations
                    “Make Fashion Circular” movement, specifically becoming a
                    partner in the “Make Jeans Circular Project.” This was the
                    start of a new journey not just for Hirdaramani but for the
                    industry, as we came together with other leaders in this
                    segment to share expertise and ideas on how we could make
                    jeans cleaner from the field to the entire product lifecycle.
                  </p>
                  <p>
                    We were able to make specific contributions in terms of
                    material health, environmentally better material choices,
                    durability of jeans, how to keep them in use for longer, how
                    to improve their recyclability, and improve traceability in
                    terms of the custody chain and authenticating circular jeans.
                  </p>
                  <p>
                    We also committed to zero use of potassium permanganate, acid
                    wash or sand blasting in any of the jeans we manufactured.
                    Bringing in a percentage of recycled cotton into the jeans was
                    a target we set, a difficult task at the time as few denim
                    suppliers had recycled cotton on offer, but as the “Make
                    fashion circular” movement gained momentum, with a strong push
                    from brands for more circularity in their products, the supply
                    chain also began to adapt.
                  </p>
                  <p>
                    At Hirdaramani, we continued to push circular jeans concepts
                    in every collection we presented, and in 2021, one of our key
                    customers, joined the EMF project and approached Hirdaramani
                    for a circular jeans style.
                    <br />
                    As a start, the jeans were 100% cotton of which 6% was
                    recycled cotton. By ensuring 100% cotton usage, we improve the
                    recyclability of the jeans as it does not contain a mix of
                    fibers. In addition, all polyester labels were removed with
                    care instructions being printed on the pocket bags, and an
                    easily identifiable recycle mark is a strong reminder for
                    consumers to recycle the product. The circular jeans program
                    with our customer is still active and is a great example of
                    how even seemingly impossible tasks can be achieved in a
                    matter of a few years as long as there is sincere commitment.
                  </p>
                  <p>
                    We are still committed to circularity across all our products
                    and use the principles of circularity learned from the EMF
                    jeans re -design project, to continue to create more circular
                    mindful products across all our collections.
                  </p>
                </div>
              </div>
              <div className="feature-column-2">
                <img
                  src={Img4}
                  alt=""
                  className="feature-stories-img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </center>
      </div>

      <div>
        <center className="trust-feature-stories-container">
          <div className="trust-feature-story-para">
            <div className="feature-two-column">
              <div className="feature-column-3">
                <img
                  src={Img5}
                  alt=""
                  className="feature-stories-img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
              <div className="feature-column-4">
                <h2
                  className="feature-sub-topic"
                  data-aos="fade-in"
                  data-aos-duration="2500"
                >
                  INDUSTRY EFFORTS TOWARDS TRACEABILITY
                </h2>
                <div data-aos="fade-up" data-aos-duration="2500">
                  <p>
                    Sri Lanka's apparel manufacturers, including Hirdaramani,
                    collaborated with the Asian Development Bank (ADB),
                    International Financial Reporting Standards (IFRS), and GS1 to
                    lead a transformative effort towards transparency,
                    traceability, and accountability in the industry. Central to
                    this initiative is the implementation of a single QR code
                    attached to every clothing item, functioning like a standard
                    tag that provides a wealth of information. Through this QR
                    code, stakeholders can access comprehensive details about the
                    brand, supply chain logistics, product specifications,
                    materials used, care instructions, compliance data, recycling
                    guidelines, and thorough tracking of the entire supply chain.
                    The primary goal of this initiative is to enhance the
                    traceability of supply chain data by promoting standardized
                    disclosure practices that streamline information exchange and
                    ensure accurate reporting.
                  </p>
                  <p>
                    This is a step in the right direction for Sri Lanka's apparel
                    industry towards a more transparent, traceable, and
                    accountable future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}

export default FeatureStories;
