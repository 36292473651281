import React, { useEffect } from 'react';
import "./CareerDevelopmentCharts.css";

//Charts
import CareeerDevelopmentChart01 from './charts/career-chart-10/chart-10';
import CareeerDevelopmentChart02 from './charts/career-chart-11/chart-11';

//AOS
import Aos from 'aos';
import 'aos/dist/aos.css';

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="career-development-chart-container-1">
      <div className="empower-career-dev-bolder-box-line-01">
        <div
          className="career-development-header-content-left-01"
          data-aos="flip-down"
          data-aos-duration="2000"
        >
          <h1>
            Other Development Targets
          </h1>
        </div>
      </div>

      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <div>
            <h4 data-aos="fade-in" data-aos-duration="2000">
              <strong>Target S4: Supervisors</strong>
            </h4>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000">
              <i>
                Notes on Results: Data above 100% indicates that more than 80% of
                supervisors were given training once in two months
              </i>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              In Sri Lanka and Vietnam particularly, career enhancement programs
              have led to strong increases in participants attaining supervisor
              level positions. Other markets were reasonably stable, resulting in
              the overall Group comfortably achieving our 80% goal.
            </p>
          </div>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <CareeerDevelopmentChart02 />
        </div>
      </div>

      {/* Chart Section 2  */}
      {/* <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <div>
            <h4 data-aos="fade-in" data-aos-duration="2000">
              <strong>Target S4: Supervisors</strong>
            </h4>
            <br />

          </div>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          
        </div>
      </div> */}

      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <div>
            <h4 data-aos="fade-in" data-aos-duration="2000">
              <strong>Target S5: Executive</strong>
            </h4>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000">
              <i>
                Notes on Results: Data above 100% indicates that more than 60% of executives were given training once in two months.
              </i>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              A standout result in Vietnam allowed the Group to achieve the 60% target
              of executives reaching their roles through career advancement training for the second year in a row.
            </p>
          </div>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <CareeerDevelopmentChart01 />
        </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
