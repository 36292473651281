import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "./carousal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeMute,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";

const CustomPrevArrow = ({ onClick }) => (
  <div onClick={onClick} className="arrow-v left-arrow-v">
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div onClick={onClick} className="arrow-v right-arrow-v">
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const VideoPlayer = ({
  src,
  type,
  isPlaying,
  isMuted,
  onTogglePlay,
  onToggleMute,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    videoRef.current.muted = isMuted;
  }, [isMuted]);

  const enterFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  return (
    <div style={{ position: "relative", width: "80%", margin: "auto" }}>
      <video
        ref={videoRef}
        controls // add the controls part and remove the button in css (.video-controls)
        autoPlay
        loop
        className="carousal-video"
        style={{ borderRadius: "10px", width: "100%" }}
      >
        <source src={src} type={type} />
      </video>
      <div className="video-controls">
        <button onClick={onTogglePlay}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
        <button onClick={onToggleMute}>
          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
        </button>
        <button onClick={enterFullscreen}>
          <FontAwesomeIcon icon={faExpand} />
        </button>
      </div>
    </div>
  );
};

const SimpleSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setIsPlaying(false); // Pause the current video before changing
  };

  const handleAfterChange = (current) => {
    setCurrentIndex(current);
    setIsPlaying(true); // Play the new video
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  };

  return (
    <div className="carousal">
      <center>
        <div className="carousal-container" style={{ position: "relative" }}>
          <div className="inside-carousal">
            <p className="topic">OUR FUTURE FIRST SUCCESS STORIES</p>
            <p className="topic-2">
              See how we make a difference through these real-life stories.
            </p>
            <div className="carousal-slider">
              <Slider {...settings}>
                <div>
                  <VideoPlayer
                    src="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/videos/Hirdaramani%20Employee%20Feature%20Video.mp4"
                    type="video/mp4"
                    isPlaying={isPlaying && currentIndex === 0}
                    isMuted={isMuted}
                    onTogglePlay={togglePlayPause}
                    onToggleMute={toggleMute}
                  />
                </div>
                <div>
                  <VideoPlayer
                    src="https://hirdaramani-main-2024.sgp1.digitaloceanspaces.com/video/Hirdaramani%20WOW.webm"
                    type="video/webm"
                    isPlaying={isPlaying && currentIndex === 1}
                    isMuted={isMuted}
                    onTogglePlay={togglePlayPause}
                    onToggleMute={toggleMute}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default SimpleSlider;
