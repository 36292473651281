import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaCaretRight } from "react-icons/fa6";
import "./numbercard.css";

const Card = (props) => {
  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div
      className="chemical-card"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      <div className="chemical-card-circle-container">
        <div
          className="chemical-card-circle"
          style={{ backgroundColor: props.circlbackgroundcolor }}
        >
          <p>{props.circlenumber}</p>
        </div>
        <div 
          className="hover-area"
          style={{ backgroundColor: props.circlbackgroundcolor,
          opacity:1 }}
        >
          <p className="tooltip-texts">{props.tooltip}</p>
          <FaCaretRight size={30} className="hover-area-arrow"
          style={{ color: props.circlbackgroundcolor,
          opacity:1 }}
          />
        </div>
      </div>
      
      <div>
        <h2
          className="chemical-card-title"
          style={{ color: props.titlefontcolor }}
        >
          {renderTitle(props.title)}
        </h2>
        <p
          className="chemical-card-description"
          style={{ color: props.fontcolor }}
        >
          {renderDescription(props.description)}
        </p>
      </div>
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <div onClick={onClick} className="chemical-arrow left-arrow-chemical">
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div onClick={onClick} className="chemical-arrow right-arrow-chemical">
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const Home = () => {
  const cards = [
    {
      title: "\n83.3%",
      titlefontcolor: "#313893",
      description:
        "Continued progress brings us closer to 100% elimination of harmful chemicals",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E12",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"Implement a Program to Phase out all Hazardous Chemicals"
    },
    {
      title: "\n2,535 t",
      titlefontcolor: "#313893",
      description:
        "Over a 50% decrease in pumice stones compared to 2022 puts us on track to achieve zero target",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E13",
      circlbackgroundcolor: "#3CB4E5",
      tooltip:"Elimination of Pumice Stones from Laundry Process"
    },
    {
      title: "\nFoundational\nLevel",
      titlefontcolor: "#313893",
      description: "On track to achieve ZDHC Aspirational Level",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E15",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"ZDHC - 100% of Industrial Processes to Achieve Aspirational Level"
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: false,
          autoplay:true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          autoplay:true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={index}>
          <Card
            title={card.title}
            description={card.description}
            backgroundcolor={card.backgroundcolor}
            fontcolor={card.fontcolor}
            circlenumber={card.circlenumber}
            circlbackgroundcolor={card.circlbackgroundcolor}
            titlefontcolor={card.titlefontcolor}
            tooltip={card.tooltip}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Home;
