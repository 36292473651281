import React from "react";
import Page from "../templates/support/wellbing/spotlight/building-the-khang-chien-bridge";
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>Community CSR Projects | Hirdaramani</title>
        <meta name="title" content="Community CSR Projects | Hirdaramani" />
        <meta
          name="description"
          content="Learn how Hirdaramani tackled traffic congestion in Vietnam's Mekong district by financing the construction of the Khang Chien Bridge."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-building-the-khang-chien-bridge/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Community CSR Projects" />
        <meta
          property="og:title"
          content="Community CSR Projects | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Learn how Hirdaramani tackled traffic congestion in Vietnam's Mekong district by financing the construction of the Khang Chien Bridge."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-building-the-khang-chien-bridge/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight;
