import React, { useEffect } from "react";
import "./SpotLight-workplace-training-and-education-programs.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/workplace_training_and_education_programs_background.jpg";
import Blue_Background from "../../../../assets/image_02-n.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 100%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="spotlight-topic-home spotlight-topic-home-workplace-section"
                  >
                    Workplace
                    <br /> training and <br />
                    education
                    <br />
                    programs
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left workplace-spotlight-content-left">
            <div className="spotlight-content-left-l workplace-spotlight-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading-2">
              TRAINING PROGRAMS
            </h1>
            <br />
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                A constant focus on workplace training and education allows our
                Group to empower employees to develop skills and advance their
                careers. In 2023, a number of country-level and Group
                initiatives were implemented covering a range of tools and
                initiatives, including:
              </p>
              <ul>
                <li>
                  <span className="bolder-text">
                    Launch of e-learning platform (Vietnam) -{" "}
                  </span>
                  533 employees enrolled across 23 courses offered via the
                  e-learning platform in its launch year of 2023. The platform
                  is designed to develop identified soft and interpersonal
                  skills.
                </li>
                <li>
                  <span className="bolder-text">
                    Finance training (Bangladesh) –
                  </span>{" "}
                  hosted multiple finance training programs for 730 employees in
                  Bangladesh, aimed at improving their financial decision-making
                  skills.
                </li>
                <li>
                  <span className="bolder-text">
                    English at the workplace (Vietnam) –
                  </span>{" "}
                  launched in 2022 in Vietnam, this program had 531 participants
                  in 2023. The courses create advancement opportunities for
                  employees who want to learn and improve their English
                  communication. This enables them to be better equipped to
                  interact with our primarily English-speaking client base.
                </li>
                <li>
                  <span className="bolder-text">
                    Fire Service and Civil Defence (FSCD) (Bangladesh) –
                  </span>{" "}
                  FSCD training equips employees with skills and knowledge to
                  respond effectively to fire emergencies and civil defence
                  situations. In 2023, 1,080 employees participated.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
