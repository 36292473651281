import React from "react";
import HomePage from "../templates/home/homepage";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Sustainable Apparel Solutions | Industry Leadership | Hirdaramani
        </title>
        <meta
          name="title"
          content="Sustainable Apparel Solutions | Industry Leadership | Hirdaramani"
        />
        <meta
          name="description"
          content="Discover our commitment to sustainability in the apparel industry. Learn about our eco-friendly practices and how we're leading the way to a greener future."
        />
        <link rel="canonical" href="https://sustainability.hirdaramani.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="keywords" content="Sustainable Apparel Solutions" />
        <meta
          property="og:title"
          content="Sustainable Apparel Solutions | Industry Leadership | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Discover our commitment to sustainability in the apparel industry. Learn about our eco-friendly practices and how we're leading the way to a greener future."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <HomePage />
    </div>
  );
}
