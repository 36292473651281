import React, { useEffect } from "react";
import './SpotLight-wow-health-and-vitality.css'
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/wow_health_and_vitality_background.jpg";
import Blue_Background from "../../../../assets/image_010.jpg";
import Blue_Background_02 from "../../../../assets/image_09.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";


function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "0% 0%",
                }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box-health-and-vitality-section">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="spotlight-topic-home">
                    wow health <br />
                    and vitality
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left health-and-vitality-spotlight-content-left">
            <div className="spotlight-content-left-l health-and-vitality-spotlight-content-left-l">
              <img
                src={Blue_Background_02}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r"></div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">WOW</h1>
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                General wellbeing and physical health go hand in hand. Our
                commitment to helping improve and maintain the physical health
                and vitality of our employees involves the running of
                well-crafted projects and initiatives.
              </p>
              <p className="bolder-text">2023 health and vitality highlights</p>
              <p>
                <u>Eye and dental clinics</u>
              </p>
              <p>
                Over 6,300 employees accessed free check-up and clinics to help
                diagnose and treat common eye and dental conditions.
              </p>
              <p>
                <u>Diabetes study and program</u>
              </p>
              <p>
                An assessment study across four Sri Lankan facilities showed 18%
                of employees had diabetes and that 36% were at pre-diabetes
                stage. The study resulted in a series of programs that reached
                4,920 employees. Key program activities included:
              </p>
              <ul>
                <li>Education programs on preventing and managing diabetes</li>
                <li>Ayurvedic clinics to provide medication treatments</li>
                <li>Reducing sugary food options in staff canteens</li>
                <li>Wellness clinics and assessments.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="spotlight-contents">
          <div className="spotlight-content-right">
            <div
              className="spotlight-content-right-contents"
              data-aos="fade-in"
            >
              <p className="bolder-text">Healthy leaders project</p>
              <p>
                We also recognise the importance of strong role models for
                positive health and vitality. The Healthy Leaders project
                educates people in leadership roles on how to manage their own
                health and how they too can promote valuable discussions with
                team members around common health issues.
              </p>
              <p>
                Over 85 leaders took part in the project in 2023, which involved
                workshops on seven health modules.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="spotlight-content-left health-and-vitality-spotlight-content-left-2">
            <div className="spotlight-content-left-l-2 health-and-vitality-spotlight-content-left-l-2">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="spotlight-content-left-r-2"></div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight
