import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaCaretRight } from "react-icons/fa6";
import "./numbercard.css";

const Card = (props) => {
  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div
      className="energy-card"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      <div className="energy-card-circle-container">
        <div
          className="energy-card-circle"
          style={{ backgroundColor: props.circlbackgroundcolor }}
        >
          <p>{props.circlenumber}</p>
        </div>
        <div 
          className="hover-area"
          style={{ backgroundColor: props.circlbackgroundcolor,
          opacity:1 }}
        >
          <p className="tooltip-texts">{props.tooltip}</p>
          <FaCaretRight size={30} className="hover-area-arrow"
          style={{ color: props.circlbackgroundcolor,
          opacity:1 }}
          />
        </div>
      </div>
      
      <div>
        <h2
          className="energy-card-title"
          style={{ color: props.titlefontcolor }}
        >
          {renderTitle(props.title)}
        </h2>
        <p
          className="energy-card-description"
          style={{ color: props.fontcolor }}
        >
          {renderDescription(props.description)}
        </p>
      </div>
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <div onClick={onClick} className="arrow left-arrow">
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div onClick={onClick} className="arrow right-arrow">
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const Home = () => {
  const cards = [
    {
      title: "0.23\nMJ/StdMin",
      titlefontcolor: "#313893",
      description:
        "Cut & Sew - a slight increase in normalised energy consumption",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E1",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"20% Savings in Normalised Energy Consumption - Cut & Sew"
    },
    {
      title: "19.97\nMJ/Garment",
      titlefontcolor: "#313893",
      description:
        "Washing - a slight increase in normalised energy consumption",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E1",
      circlbackgroundcolor: "#3CB4E5",
      tooltip:"20% Savings in Normalised Energy Consumption - Laundry"
    },
    {
      title: "SBTi \n",
      titlefontcolor: "#313893",
      description: "Targets submitted for verification",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E2",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"SBTi - Near term Emission Reduction and Net-Zero Targets"
    },
    {
      title: "18.46%\n",
      titlefontcolor: "#313893",
      description:
        "We’ve made progress in  increasing our renewable energy footprint",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E3",
      circlbackgroundcolor: "#3CB4E5",
      tooltip:"50% of Energy Requirements covered by Renewable Sources"
    },
  ];

  const settings = {
    dots: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={index}>
          <Card
            title={card.title}
            description={card.description}
            backgroundcolor={card.backgroundcolor}
            fontcolor={card.fontcolor}
            circlenumber={card.circlenumber}
            circlbackgroundcolor={card.circlbackgroundcolor}
            titlefontcolor={card.titlefontcolor}
            tooltip={card.tooltip}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Home;
