import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./waterpage.css";
import HomeVideo from "../../../assets/water1.webp";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import FlipCard from "./FlipCard";
import NumberCard from "./numbercard";
import GreenParagraph from "./greenParagraph";
import TargetsCharts from "./TargetsCharts";
import TargetsCharts2 from "./TargetsCharts-2";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="conserve-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="conserve-topic-home">WATER</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="water-cards">
          <div className="water-para-box">
            <div data-aos="zoom-in">
              <h1 className="water-para-two">WATER</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="water-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <GreenParagraph />

        <TargetsCharts />
        <TargetsCharts2 />

        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
