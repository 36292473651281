import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
//import { Link } from "react-router-dom";
import "./energy_report.css";
import energyimage1 from "../../../assets/energyimage2.png";

export default function Pcard() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div>
        <div className="energy_report-card-grid">
          <div>
            <div
              className="energy_report-card"
              style={{
                backgroundColor: "#3CB4E5",
              }}
            >
              <div className="energy_report-card-content">
                <h2
                  className="targetHeading"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Target E2: Aligning with SBTi
                </h2>
                <p
                  className="targetPara"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  <p>
                    In 2022, the Hirdaramani Group committed to the Science
                    Based Targets Initiative (SBTi), joining a select group of
                    companies acting on limiting global warming to 1.5°C and
                    preventing the worst impacts of climate change.
                  </p>
                  <p>
                    We have pledged to establish 'near-term goals' and 'net-zero
                    emissions' within the SBTi framework, with these targets
                    being set and shared for verification in 2023.{" "}
                  </p>
                  <p>
                    We aim to integrate these goals to our Future First
                    Sustainability Roadmap 2025, however progress is subject to
                    receiving target verification from SBTi, which we are
                    currently awaiting.
                  </p>
                </p>
                {/* <br />
                <div style={{ marginTop: "10px", marginBottom: "50px" }}>
                  <Link to="#" className="targetbutton">
                    Learn more
                  </Link>
                </div> */}
              </div>
            </div>
          </div>

          <div>
            <div
              className="energy_report-card"
              style={{
                backgroundImage: `url(${energyimage1})`,
              }}
            >
              <center>
                <div className="energy_report-card-content"></div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
// import energyimage1 from '../../assets/energyimage1.png';
// import './energy_report.css'

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   // Additional styling for height
//   height: '700px', // Adjust the height as needed
// }));

// export default function EnergyReport() {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={6} md={6} style={{ padding: 0 }}>
//           <Item style={{ backgroundColor: "#3CB4E5", padding: '16px' }}>
//             <h1
//               className='targetHeading'
//               data-aos="fade-in"
//               data-aos-duration="2000"
//               data-aos-delay="1000"
//             >
//               Target E2: Aligning with the STB
//             </h1>
//             <p
//                 className='targetPara'
//                 data-aos="fade-in"
//                 data-aos-duration="2000"
//                 data-aos-delay="1000"
//               >
//                 In 2022, Hirdaramani Group committed to the Science Based Targets Initiative (SBTi), joining a select group of companies acting to limit global warming to 1.5°C and prevent the worst impacts of climate change. We have pledged to establish 'near-term goals'
//                 and 'net-zero emissions' within the SBTi framework,
//                 with these targets have been set and shared for verification in 2023.
//               </p>
//           </Item>
//         </Grid>
//         <Grid item xs={6} md={6} style={{ padding: 0 }}>
//           <Item style={{ backgroundImage: `url(${energyimage1})`, padding: '16px' }}>xs=6 md=4</Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }
