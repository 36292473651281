import React, { useEffect } from "react";
import "./TargetsCharts.css";

//Charts
import OtherWaterTargetChart01 from "./charts/chart-1/chart";
import OtherWaterTargetChart02 from "./charts/chart-2/chart";
import OtherWaterTargetParagraph from "./TargetsChart_2_para";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const labels = document.getElementsByClassName("apexcharts-pie-label");

    if (labels && labels.length > 0) {
      for (let i = 0; i < labels.length; i++) {
        if (i % 2 === 0) {
          const label = labels[i];
          label.setAttribute("text-anchor", "end");
        } else {
          const label = labels[i];
          label.setAttribute("text-anchor", "start");
        }
      }
    }
  }, []);

  return (
    <div>
      <div className="new-water-page-hightlight-chart-container">
        <div className="new-chemical-chart-page-hightlight-bolder-box-line">
          <div className="new-chemical-chart-page-hightlight-header-contents">
            <div
              className="new-conserve-header-content-left-2"
              data-aos="flip-down"
              data-aos-duration="2000"
            >
              <h1>Other Environmental Targets</h1>
            </div>
          </div>
        </div>
        <div>
          <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left">
              <div>
                <h4 data-aos="fade-in" data-aos-duration="2000">
                  <strong>
                    Target E4 & E5: Reduction in industrial freshwater
                    consumption
                  </strong>
                </h4>
                <br />
                <p
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  The Hirdaramani Group’s freshwater consumption in 2023 showed
                  a marginal decrease mainly due to reduced usage in Vietnam and
                  Bangladesh. Sri Lanka noted a significant rise, and this
                  substantially impacted Group results. We are consciously
                  working towards addressing any setbacks in meeting our Future
                  First target of reducing freshwater consumption per garment by
                  50% and are eagerly exploring means of decreasing our reliance
                  on groundwater and municipal water for non-potable purposes.
                </p>
              </div>
            </div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right">
              <center>
                <OtherWaterTargetChart01 />
              </center>
            </div>
          </div>
        </div>
        {/* // */}
        <div>
          <div className="new-chemical-chart-page-hightlight-bolder-box-line-content">
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-left conserve-water-chart-02">
              <center>
                <OtherWaterTargetChart02 />
              </center>
            </div>
            <div className="new-chemical-chart-page-hightlight-bolder-box-line-content-right conserve-water-chart-02-paragraph">
              <center>
                <OtherWaterTargetParagraph />
              </center>
            </div>
          </div>
        </div>
        
      </div>
      {/* <div className="new-water-chart-container">
        <div className="new-water-header-contents">
          <div
            className="new-water-header-content-left"
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h1>
              Other <br />
              Environmental <br /> Targets
            </h1>
          </div>
          <div className="new-water-header-content-right">
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>
                Target E4 & E5: Reduction in industrial freshwater consumption
              </strong>
            </p>
            <br />
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              The Hirdaramani Group’s freshwater consumption in 2023 showed a
              marginal decrease mainly due to reduced usage in Vietnam and
              Bangladesh. Sri Lanka noted a significant rise, and this
              substantially impacted Group results. We are consciously working
              towards addressing any setbacks in meeting our Future First target
              of reducing freshwater consumption per garment by 50% and are
              eagerly exploring means of decreasing our reliance on groundwater
              and municipal water for non-potable purposes.
            </p>
          </div>
        </div>
        <center>
          <div className="new-water-bar-chart-01">
            <OtherWaterTargetChart01 />
          </div>

          <div className="new-water-bar-chart-01">
            <OtherWaterTargetChart02 />
          </div>
          <div className="water-chart-column-1">
            <OtherWaterTargetParagraph />
          </div>
        </center>
      </div> */}
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
