import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Background_Img from "../../../../assets/Healthy-Lifestyle-Bangladesh.webp";
import counselling_image_01 from "../../../../assets/image_05.jpg"; //
import on_site_mental_health_clinic from "../../../../assets/image_011.jpg";
import healthy_and_positive_relationships_program_image from "../../../../assets/image_04.jpg";
import wow_health_and_vitality_image_01 from "../../../../assets/image_09.jpg";
import wow_libraries_image_01 from "../../../../assets/image_07.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import "./SpotLight.css";


export default function f() {
  return (
    <ParallaxProvider>
      {/* Section 1 */}

      <div style={{ position: "relative" }}>
        <div className="spotlight-background">
          <div>
            <img
              src={Background_Img}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div className="spotlight-text-box-2">
            <div>
              <Parallax speed={10}>
                <h1 data-aos="fade-right" className="spotlight-topic-home-spot">
                  SPOTLIGHT
                </h1>
              </Parallax>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.45)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Section 2 */}
      {/* 01 */}
      <div className="spotlight-wow-gender-section">
        <div className="wellbeing-wow-gender-section-left wellbeing-wow-gender-section-left-01">
          <img
            src={counselling_image_01}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="wellbeing-wow-gender-section-right wellbeing-wow-gender-section-right-01">
          <div className="wellbeing-wow-gender-section-right-contents">
            <h1 data-aos="fade-in" data-aos-duration="2000">Mental Health & Wellbeing</h1>
            <p data-aos="fade-in" data-aos-duration="2000">
              Mental health is key focus area in wellbeing objectives. With dedicated practitioners and on-site counselling services, Hirdaramani addresses this crucial area of wellbeing with the support of both internal and external resources.{" "}
            </p>
            <div data-aos="fade-in">
              <a href="/empower/wellbeing/spotlight-mental-health-and-wellbeing/" className="link">
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 02 */}
      <div className="spotlight-wow-gender-section">
        <div className="wellbeing-wow-gender-section-left wellbeing-wow-gender-section-left-02">
          <img
            src={on_site_mental_health_clinic}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="wellbeing-wow-gender-section-right wellbeing-wow-gender-section-right-02">
          <div className="wellbeing-wow-gender-section-right-contents">
            <h1 data-aos="fade-in" data-aos-duration="2000">On-site mental health clinics</h1>
            <p data-aos="fade-in" data-aos-duration="2000">
              Find out more here about Hirdaramani’s projects featuring workplace mental health clinics.{" "}
            </p>
            <div data-aos="fade-in" data-aos-duration="2000">
              <a href="/empower/wellbeing/spotlight-on-site-mental-health-clinics/" className="link">
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>



      {/* 03 */}
      <div className="spotlight-wow-gender-section">
        <div className="wellbeing-wow-gender-section-left wellbeing-wow-gender-section-left-03">
          <img
            src={healthy_and_positive_relationships_program_image}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="wellbeing-wow-gender-section-right wellbeing-wow-gender-section-right-03">
          <div className="wellbeing-wow-gender-section-right-contents">
            <h1 data-aos="fade-in" data-aos-duration="2000">Healthy and positive relationships program</h1>
            <p data-aos="fade-in" data-aos-duration="2000">
              An in-depth analysis of absenteeism rates and employee surveys indicated that more awareness was required on the importance of healthy and stable family relationships. Read here how this program is making an impact.{" "}
            </p>
            <div data-aos="fade-in" data-aos-duration="2000">
              <a href="/empower/wellbeing/spotlight-healthy-and-positive-relationships-program/" className="link">
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* 04 */}
      <div className="spotlight-wow-gender-section">
        <div className="wellbeing-wow-gender-section-left wellbeing-wow-gender-section-left-04">
          <img
            src={wow_health_and_vitality_image_01}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="wellbeing-wow-gender-section-right wellbeing-wow-gender-section-right-04">
          <div className="wellbeing-wow-gender-section-right-contents">
            <h1 data-aos="fade-in" data-aos-duration="2000">WOW health and vitality</h1>
            <p data-aos="fade-in" data-aos-duration="2000">
              It can be easy to overlook how vital physical health is to workplace happiness and productivity.{" "}
            </p>
            <div data-aos="fade-in" data-aos-duration="2000">
              <a href="/empower/wellbeing/spotlight-wow-health-and-vitality/" className="link">
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* 05 */}
      <div className="spotlight-wow-gender-section">
        <div className="wellbeing-wow-gender-section-left wellbeing-wow-gender-section-left-05">
          <img
            src={wow_libraries_image_01}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="wellbeing-wow-gender-section-right wellbeing-wow-gender-section-right-05">
          <div className="wellbeing-wow-gender-section-right-contents">
            <h1 data-aos="fade-in" data-aos-duration="2000">WOW libraries</h1>
            <p data-aos="fade-in" data-aos-duration="2000">
              Even in this digital screen era of multiple visual forms of content, the power and benefits of reading can’t go unnoticed.{" "}
            </p>
            <div data-aos="fade-in" data-aos-duration="2000">
              <a href="/empower/wellbeing/spotlight-wow-libraries/" className="link">
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
