import React, { useEffect } from "react";
import "./undp-backyard-poultry-and-market.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/UNDP-backyard-poultry-and-market.webp";
import Blue_Background from "../../../../assets/image_029.jpg";
//import Vietnam from "../../../../assets/vietnam-flag.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="community-p2-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="community-p2-topic-home">
                    UNDP backyard
                    <br />
                    poultry and <br />
                    market
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="community-p2-contents">
          <div className="community-p2-content-left">
            <div className="community-p2-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="community-p2-content-left-r"></div>
          </div>
          <div className="community-p2-content-right">
            {/* <h1 className="community-p2-content-right-heading">Vietnam:</h1> */}
            <h1 className="community-p2-content-right-heading-main">
              UNDP backyard poultry and market
            </h1>
            <div
              className="community-p2-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                Hirdaramani and the Lacoste Foundation have come together
                through the United Nations Development Programme (UNDP) in Sri
                Lanka’s facility for private sector giving to promote
                socio-economic recovery and food security and address its
                multidimensional vulnerabilities.
              </p>

              <p>
                The grants for Integrated Backyard Poultry and Market
                Development will support over 100 families through the
                integration of free-range backyard poultry (country birds)
                farming with commercial fruit and vegetables, helping increase
                income and nutrition supply for the beneficiaries. The
                initiative will additionally play a key role in creating market
                linkages and access to eco-friendly fruits and vegetables on a
                regular basis for more than 500 community members. The
                beneficiaries of the project will be communities residing in two
                remote villages based in Vavuniya Town and Vavuniya South DS
                divisions, which are in close proximity to Hirdaramani’s
                manufacturing operations in the area.
              </p>

              <p>
                The initiative aligns with the core values of the Lacoste
                Foundation and Hirdaramani Apparel, in aspiring to improve the
                living conditions of local communities under the focus areas of
                education, environment, and health.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
