import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./environment.css";
import HomeVideo from "../../../assets/environment_background.jpg";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
//import TargetsCharts from "./TargetsCharts";
import Spotlight from "./spotlight";
import FlipCard from "./FlipCard";

export default function Environment() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="environment-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 className="environment-topic-home">ENVIRONMENT</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.45)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}

        <div>
          <div className="environment-animation-para">
                <center className="environment-animation-para-center">
                  <p>
                    The connection between community and environment is
                    important to Hirdaramani. Our enduring belief is that we
                    must foster a greater sense of sustainability among all
                    people – well beyond those within our factory walls – to
                    create a better future.
                  </p>
                  <p>
                    We’re proud to not only drive our own environmental projects
                    in the communities we operate but also support local-led
                    initiatives. We focus extensively on engaging with schools
                    and children to instill a love of the environment from a
                    young age.
                  </p>
                  {/* <p
                  className="environment-animation-para-center-yello-p"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  80% of supervisors to be reached through career advancement
                  programs.
                  <br />
                  60% of executives to be reached through career advancement
                  programs.
                </p> */}
                </center>
          </div>
        </div>

        {/* section-4 */}
        <Spotlight />

        {/* Section - 5 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
