import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./numbercard.css";
import { FaCaretRight } from "react-icons/fa6";

const Card = (props) => {
  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div
      className="e-wellbeing-card"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      <div className="e-wellbeing-card-circle-container">
        <div
          className="e-wellbeing-card-circle"
          style={{ backgroundColor: props.circlbackgroundcolor }}
        >
          <p>{props.circlenumber}</p>
        </div>
        <div 
          className="hover-area hover-area-empower-e-wellbeing"
          style={{ backgroundColor: props.circlbackgroundcolor,
          opacity:1 }}
        >
          <p className="tooltip-texts">{props.tooltip}</p>
          <FaCaretRight size={30} className="hover-area-arrow"
          style={{ color: props.circlbackgroundcolor,
          opacity:1 }}
          />
        </div>
      </div>
      
      <div>
        <h2
          className="e-wellbeing-card-title"
          style={{ color: props.titlefontcolor, fontWeight: "bolder" }}
        >
          {renderTitle(props.title)}
        </h2>
        <p
          className="e-wellbeing-card-description"
          style={{ color: props.fontcolor }}
        >
          {renderDescription(props.description)}
        </p>
      </div>
    </div>
  );
};

const Home = () => {
  const cards = [
    {
      title: "\n104%",
      titlefontcolor: "#313893",
      description:
        "We continue to surpass the 80% target for development of supervisors",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "S4",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"80% of Supervisors reached through our Career Enhancement Program"
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    centerPadding: "10px",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider className="e-wellbeing-slider" {...settings}>
      {cards.map((card, index) => (
        <div key={index}>
          <Card
            title={card.title}
            description={card.description}
            backgroundcolor={card.backgroundcolor}
            fontcolor={card.fontcolor}
            circlenumber={card.circlenumber}
            circlbackgroundcolor={card.circlbackgroundcolor}
            titlefontcolor={card.titlefontcolor}
            tooltip={card.tooltip}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Home;
