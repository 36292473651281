import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./equalitypage.css";

//Images
import Home_Background from "../../../assets/equality_background.jpg";
import Spotlight_Background from "../../../assets/spotlight_equality-back.png";
import wow_gender_image_with_frame from "../../../assets/GenderInclusiveWorkplace.jpg";

//import { Link } from "react-router-dom";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import NumberCard from "./numbercard";
import FlipCard from "./FlipCard";

//Charts
import EqualityStackedBarChart from "./Charts/chart-2/chart";
import EqualityHighlightsCharts from "./EqualityHighlightsCharts";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

//Icons
import { MdArrowRightAlt } from "react-icons/md";

export default function Homepage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="equality-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="equality-topic-home">
                    EQUALITY
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="equality-cards">
          <div className="equality-para-box">
            <div data-aos="zoom-in">
              <h1 className="equality-para-two">EQUALITY</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="equality-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <div className="euqality-paragraph-section-1">
          <center className="euqality-paragraph-section-1-center">
            <p>
              Approximately two-thirds of the Hirdaramani workforce are female.
              We are focused on providing fair and equal opportunities for women
              in leadership positions and career advancement.
            </p>
            <p>Our stated Equality goals within the Future First plan are:</p>
            <ul className="euqality-paragraph-section-1-center-yello-p">
              <li>30% of leadership positions are to be held by women by 2025.</li>
              <li>70% of facility supervisory positions are to be held by women by 2025.</li>
            </ul>
          </center>
        </div>

        {/* Section 4 */}
        <div
          className="equality-stacked-bar-chart-container"
          style={{ background: "#fff" }}
        >
          <div className="new-equality-chart-02-hightlight-bolder-box-line-content">
              <div className="new-equality-chart-02-hightlight-bolder-box-line-content-left">
                <div>
                  <p
                    data-aos="fade-in"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    Currently, there are significantly more male employees in the
                    category of Executive and above than female employees. While there
                    has been a positive trend over recent years of more women stepping
                    into leadership roles, we expect to make more progress on our
                    goals during the period outlined in our Future First Roadmap.
                  </p>
                </div>
              </div>
              <div className="new-equality-chart-02-hightlight-bolder-box-line-content-right">
                <EqualityStackedBarChart />
              </div>
            </div>

          {/* <div className="equality-stacked-bar-charts">
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
              Currently, there are significantly more male employees in the
              category of Executive and above than female employees. While there
              has been a positive trend over recent years of more women stepping
              into leadership roles, we expect to make more progress on our
              goals during the period outlined in our Future First Roadmap.
            </p>
            <ScrollTrigger onEnter={() => setRenderStateChart(true)}>
              {renderStateChart && (
                <center data-aos="fade-in">
                  <EqualityStackedBarChart />
                </center>
              )}
            </ScrollTrigger>
          </div> */}
        </div>
        {/* Section 4 */}
        {/* Equality Highlights Charts */}
        <EqualityHighlightsCharts />

        {/* Section 4 */}

        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Spotlight_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="equality-text-box">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="equality-topic-home-spot"
                  >
                    SPOTLIGHT
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* Section 5 */}
        <div className="equality-wow-gender-section-01">
          <div className="equality-wow-gender-section-01-left">
            <img
              src={wow_gender_image_with_frame}
              alt="WOW Gender"
              data-aos="flip-right"
            />
          </div>
          <div className="equality-wow-gender-section-01-right">
            <div className="equality-wow-gender-section-01-right-contents">
              <h1 data-aos="fade-in">
                WOW gender <br /> inclusive <br /> workplace
              </h1>
              <p data-aos="fade-in">
                2023 witnessed the introduction of a new pilot project to
                develop gender enablers at leadership level and to formulate a
                gender inclusive policy for the group.
              </p>
              <div data-aos="fade-in">
                <a href="/empower/equality/spotlight/" className="link">
                  <button>
                    Read the full story{" "}
                    <div className="equality-wow-gender-icon">
                      <MdArrowRightAlt size={30} />
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Section - 6 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
