import React from 'react'
import Page from '../templates/empower/career-development/spotlight/SpotLight-PACE'
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>
          PACE Program | Career Development and Training | Hirdaramani
        </title>
        <meta
          name="title"
          content="PACE Program | Career Development and Training | Hirdaramani"
        />
        <meta
          name="description"
          content="P.A.C.E. empowers female associates for career growth and personal development through communication, problem-solving, and time management modules"
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/career-development/spotLight-PACE/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="PACE Program" />
        <meta
          property="og:title"
          content="PACE Program | Career Development and Training | Hirdaramani"
        />
        <meta
          property="og:description"
          content="P.A.C.E. empowers female associates for career growth and personal development through communication, problem-solving, and time management modules"
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/career-development/spotLight-PACE/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight
