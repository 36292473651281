import React from "react";
import Page from "../templates/support/wellbing/wellbing";
import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div>
      <Helmet>
        <title>
          Health Care Programs | Local Health care Initiatives | Hirdaramani
        </title>
        <meta
          name="title"
          content="Health Care Programs | Local Health care Initiatives | Hirdaramani"
        />
        <meta
          name="description"
          content="Hirdaramani supports local healthcare with medication donations, infrastructure projects, and specialized clinics, fostering healthier communities near our factories."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/wellbeing/
"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Health Care Programs" />
        <meta
          property="og:title"
          content="Health Care Programs | Local Health care Initiatives | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Hirdaramani supports local healthcare with medication donations, infrastructure projects, and specialized clinics, fostering healthier communities near our factories."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/wellbeing/
"
        />
      </Helmet>

      <Page />
    </div>
  );
}
