import React, { useEffect } from "react";
import "./t-e-a-project.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/TEAProject1.jpg";
// import Blue_Background from "../../../../assets/image_014-n.png";
import Blue_Background from "../../../../assets/TEAProject2.jpg";
import Blue_Background1 from "../../../../assets/TEAProject3.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="childrin-education-p3-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="childrin-education-p3-topic-home"
                  >
                    t. e. a. <br />
                    project
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="childrin-education-p3-contents">
          <div className="childrin-education-p3-content-left">
            <div className="childrin-education-p3-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="childrin-education-p3-content-left-r"></div>
          </div>
          <div className="childrin-education-p3-content-right">
            <h1 className="childrin-education-p3-content-right-heading">
              Sri Lanka:
            </h1>
            <h1 className="childrin-education-p3-content-right-heading-main">
              T.E.A. Project and The Cloud
            </h1>
            <div
              className="childrin-education-p3-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                Established in 2014, The T.E.A Project has empowered thousands
                of children and individuals across Sri Lanka. The Project
                supports children from economically challenged communities,
                regardless of cultural or religious background.
              </p>
              <p>
                In 2018, Hirdaramani committed to partner The T.E.A. Project by
                supporting to build ‘The Cloud’ Centre for Children’s
                Empowerment in Kithulmulla, Hanthana and we continue to support
                its initiatives to reach more individuals for lasting impact.In
                2023, our support for the centre included the following
                programs.
              </p>
            </div>

            <h1 className="childrin-education-p3-content-right-heading-sub">
              Y.E.S. (Youth Empowerment Service)
            </h1>
            <div
              className="childrin-education-p3-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                This program is dedicated to developing children’s practical and
                language skills and academic progress and achievements through
                tuition and after-school classes, as well as vocational and
                empowerment initiatives.. In 2023, 144 students benefitted from
                this service.
              </p>
            </div>
          </div>
        </div>

        {/* para 02 */}
        <div className="screen-pc">
          <div className="childrin-education-p3-contents">
            <div className="childrin-education-p3-content-right">
              <h1 className="childrin-education-p3-content-right-heading-sub">
                Tiny T.E.A Pre-School
              </h1>
              <div
                className="childrin-education-p3-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  We’re proud to continue supporting the Tiny T.E.A. Pre-school,
                  ‘The Cloud’ in Hanthana, Sri Lanka to provide free early
                  childhood education for children of tea estate workers. The
                  initiative is led by The T.E.A. Project in collaboration with
                  Hirdaramani and a valued partner.
                </p>
              </div>

              <h1 className="childrin-education-p3-content-right-heading-sub">
                F.O.O.D.
              </h1>
              <div
                className="childrin-education-p3-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  Fuelling Opportunities, Outcomes and Diets (F.O.O.D) is a
                  program that provides free nutritious daily vegetarian meals
                  to children attending the centre’s after-school classes. The
                  young beneficiaries are from low-income families with
                  special/additional needs, or are from family units of single
                  parents, young parents, or parents working in the estate
                  sector with low-income. This program benefited 362 students in
                  2023.
                </p>
              </div>
              <div style={{ height: "150px" }} />
            </div>
            <div className="childrin-education-p3-content-left">
              <div className="childrin-education-p3-content-left-l-2">
                <img
                  src={Blue_Background1}
                  alt="Blue Shape"
                  data-aos="flip-right"
                />
              </div>
              <div className="childrin-education-p3-content-left-r-2"></div>
            </div>
          </div>
        </div>
        {/* mobile */}
        <div className="screen-mobile">
          <div className="childrin-education-p3-contents">
            <div className="childrin-education-p3-content-left">
              <div className="childrin-education-p3-content-left-l">
                <img
                  src={Blue_Background1}
                  alt="Blue Shape"
                  data-aos="flip-right"
                />
              </div>
              <div className="childrin-education-p3-content-left-r"></div>
            </div>
            <div className="childrin-education-p3-content-right">
              <h1 className="childrin-education-p3-content-right-heading-sub">
                Tiny T.E.A Pre-School
              </h1>
              <div
                className="childrin-education-p3-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  We’re proud to continue supporting the Tiny T.E.A. Pre-school,
                  ‘The Cloud’ in Hanthana, Sri Lanka to provide free early
                  childhood education for children of tea estate workers. The
                  initiative is led by The T.E.A. Project in collaboration with
                  Hirdaramani and a valued partner.
                </p>
              </div>

              <h1 className="childrin-education-p3-content-right-heading-sub">
                F.O.O.D.
              </h1>
              <div
                className="childrin-education-p3-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  Fuelling Opportunities, Outcomes and Diets (F.O.O.D) is a
                  program that provides free nutritious daily vegetarian meals
                  to children attending the centre’s after-school classes. The
                  young beneficiaries are from low-income families with
                  special/additional needs, or are from family units of single
                  parents, young parents, or parents working in the estate
                  sector with low-income. This program benefited 362 students in
                  2023.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* para 02 - end */}
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
