import React, { useEffect } from "react";
import './SpotLight-wow-libraries.css'
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/wow_libraries_background.jpg";
import Blue_Background from "../../../../assets/image_08.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";


function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="spotlight-text-box-wow-library-section">
              <div className="text-box-background-text">
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="spotlight-topic-home">wow <br />libraries</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="spotlight-contents">
          <div className="spotlight-content-left wow-libraries-spotlight-content-left">
            <div className="spotlight-content-left-l wow-libraries-spotlight-content-left-l">
              <img src={Blue_Background} alt="Blue Shape" data-aos="flip-right"/>
            </div>
            <div className="spotlight-content-left-r">
              
            </div>
          </div>
          <div className="spotlight-content-right">
            <h1 className="spotlight-content-right-heading">WOW</h1>
            <div className='spotlight-content-right-contents' data-aos="fade-in">
              <p>Studies have proven the wellbeing benefits of reading. Improvements in cognitive abilities such as focus, memory, empathy, creativity, and communication are common benefits for people who read regularly. In addition, reading is a great way to relieve stress and take a break from the hustle and bustle of the outside world.</p>
              <p>We introduced WOW Everyday Reading Projects to encourage our workforce to develop the positive habit of regular reading. In support of this project, we have set up four libraries with nearly 1,000 books in each facility.</p>
              <p>Membership has exceeded over 700 for each library, and the project attracted 4,720 employees in 2023.</p>
            </div>
          </div>
        </div>
        
      </div>
    </ParallaxProvider>
  )
}

export default SpotLight
