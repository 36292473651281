import React, { useState, useEffect, useRef } from "react";
import "./image-chart.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import bottleImage from "../../../../assets/wow_background_V3.webp";

const Chart = () => {
  const [waterPercentage1, setWaterPercentage1] = useState(0);
  const [waterPercentage2, setWaterPercentage2] = useState(0);
  const [waterPercentage3, setWaterPercentage3] = useState(0);
  const [waterPercentage4, setWaterPercentage4] = useState(0);
  const [waterPercentage5, setWaterPercentage5] = useState(0);
  const [counterOn, setCounterOn] = useState(false);
  const chartSectionRef = useRef(null);

  useEffect(() => {
    const chartSectionNode = chartSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const timer = setTimeout(() => {
              const waterHeight1 = '23%';
              const waterHeight2 = '18%';
              const waterHeight3 = '18%';
              const waterHeight4 = '19%';
              const waterHeight5 = '23%';
              setWaterPercentage1(waterHeight1);
              setWaterPercentage2(waterHeight2);
              setWaterPercentage3(waterHeight3);
              setWaterPercentage4(waterHeight4);
              setWaterPercentage5(waterHeight5);
            }, 300);

            return () => clearTimeout(timer);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (chartSectionNode) {
      observer.observe(chartSectionNode);
    }

    return () => {
      if (chartSectionNode) {
        observer.unobserve(chartSectionNode);
      }
    };
  }, []);

  return (
    <div className="empower-wellbeing-chart-02-chart-section" ref={chartSectionRef}>
      <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
      >
        <div className="empower-wellbeing-image-chart-02-container-2">
        <img className="image-chart-02-image" src={bottleImage} alt="Bottle" />
          <div className="image-chart-02">
            <div
              className="image-chart-02-water-1"
              style={{ height: waterPercentage1 }}
            >
              {counterOn && (
                <CountUp
                  start={0}
                  end={32352}
                  duration={3}
                  decimals={0}
                  delay={0.3}
                />
              )}
              </div>
            <div
              className="image-chart-02-water-2"
              style={{ height: waterPercentage2 }}
            >
              {counterOn && (
                <CountUp
                  start={0}
                  end={0}
                  duration={3}
                  decimals={0}
                  delay={0.3}
                />
              )}
            </div>
            <div
              className="image-chart-02-water-3"
              style={{ height: waterPercentage3 }}
            >
              {counterOn && (
                <CountUp
                  start={0}
                  end={7011}
                  duration={3}
                  decimals={0}
                  delay={0.3}
                />
              )}
              </div>
            <div
              className="image-chart-02-water-4"
              style={{ height: waterPercentage4 }}
            >
              {counterOn && (
                <CountUp
                  start={0}
                  end={5535}
                  duration={3}
                  decimals={0}
                  delay={0.3}
                />
              )}
            </div>
            <div
              className="image-chart-02-water-5"
              style={{ height: waterPercentage5 }}
            >
              {counterOn && (
                <CountUp
                  start={0}
                  end={19806}
                  duration={3}
                  decimals={0}
                  delay={0.3}
                />
              )}
            </div>
          </div>
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default Chart;
