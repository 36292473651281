import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./srilanka.css";

export default function Male() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <br />
      <br />
      <br />
      <div className="sri-lanka-box1 sri-lanka-box-1-blue">
        {counterOn && <CountUp start={0} end={0} duration={3} delay={0.3} />}%
      </div>
      <br />
      <br />
      <div className="sri-lanka-box3 sri-lanka-box-3-green">
        {counterOn && <CountUp start={0} end={59} duration={3} delay={0.3} />}%
      </div>
    </ScrollTrigger>
  );
}
