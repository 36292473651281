import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./energypage.css";
import HomeVideo from "../../../assets/energy_background-2.png";
import FlipCard from "./FlipCard";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import OtherEnergyTargetsCharts from "./OtherEnergyTargetsCharts";
import EnergyReport from "./energy_report";
import NumberCard from "./numbercard";
import EnergyCharts2 from "./EnergyCharts_2"
import GreenParagraph from "./greenParagraph";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="energy-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="energy-topic-home">ENERGY</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="energy-cards">
          <div className="energy-para-box">
            <div data-aos="zoom-in">
              <h1 className="energy-para-two">ENERGY</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="energy-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <GreenParagraph />

        {/* section-4 */}
        <OtherEnergyTargetsCharts />

        {/* section-5 */}
        <EnergyReport />

        {/* section-6 */}
        <EnergyCharts2 />

        {/* section-7 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
