import React from "react";
import "./chartWater4.css";
import Srilanka from './srilanka.jsx';

export default function Chart() {

  return (
    <div style={{ width: "100%", padding:'0' }}>
      <div className="conserve-water-chart-04">
        <h1 className="conserve-chart-heading">Recycled Industrial wastewater used back in the process – Group wide</h1>
        <div className="conserve-water-chart-04-paragraph-two-column">
          <div className="conserve-water-chart-04-paragraph-column-02">
            <div><Srilanka /></div>
          </div>
        </div>
      </div>
    </div>
  );
}