import React from "react";
//import { Link } from "react-router-dom";
import Male from "./srilanka-male.jsx";
import "./srilanka.css";

export default function Srilanka() {

  return (
    <div style={{ width: "100%" }}>
      <div>
        <p className="st-para">
          *Data includes repeat participation of training programmes
        </p>
        <div className="career-empower-chart-4-srilanka-paragraph-two-column-chart-1">
          <div className="empower-chart-4-srilanka-paragraph-column-1 career-development-empower-chart-4-srilanka-paragraph-column-1">
            <div className="aa-mobile">
              <p className="gender-topic">Country</p>
            </div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-2 career-development-empower-chart-4-srilanka-paragraph-column-2">
            <div>{<Male />}</div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-3">
            <center>
              <div>
                <br />
                <div className="chart-4-flag-n selected">
                  {/* <img src={ethiopia} alt="1" /> */}
                  <h3 className="selected">Ethiopia</h3>
                </div>
                <br />
                <div className="chart-4-flag-n selected">
                  {/* <img src={vietnam} alt="1" /> */}
                  <h3 className="selected">Vietnam</h3>
                </div>
                <br />
                <div className="chart-4-flag-n selected">
                  {/* <img src={banglades} alt="1" /> */}
                  <h3 className="selected">Bangladesh</h3>
                </div>
                <br />
                <div className="chart-4-flag-n selected">
                  {/* <img src={srilanka} alt="1" /> */}
                  <h3 className="selected">Sri Lanka</h3>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="career-empower-chart-4-srilanka-paragraph-two-column-chart-1">
            <div className="empower-chart-4-srilanka-paragraph-column-4 career-development-empower-chart-4-srilanka-paragraph-column-4"></div>
            <div className="empower-chart-4-srilanka-paragraph-column-5 career-development-empower-chart-4-srilanka-paragraph-column-5"></div>
            <div className="empower-chart-4-srilanka-paragraph-column-6">
              <br />
              <p className="gender-topic">Country</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
