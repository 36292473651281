import React from "react";
import "./menu-card.css";
import "aos/dist/aos.css";
//import { Link } from "react-router-dom";

const Card = (props) => {
  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div data-aos="flip-right">
      <center>
        {props.title === "2025\nRoadmap" ? (
          <div
            className="g-card"
            style={{ backgroundColor: props.backgroundcolor }}
            onClick={() => {
              props.setPopUpState(true);
            }}
          >
            <div>
              <h3 className="card-title">{renderTitle(props.title)}</h3>
            </div>
          </div>
        ) : (
          <a href={`/${props.link}/`} className="link">
            <div
              className="g-card"
              style={{ backgroundColor: props.backgroundcolor }}
            >
              <div>
                <h3 className="card-title">{renderTitle(props.title)}</h3>
              </div>
            </div>
          </a>
        )}
      </center>
    </div>
  );
};

const Home = (props) => {
  const cards = [
    {
      title: "2025\nRoadmap",
      backgroundcolor: "#124535",
    },
    {
      title: "Conserve",
      backgroundcolor: "#1E6D54",
      link: "conserve",
    },
    {
      title: "Empower",
      backgroundcolor: "#247e46",
      link: "empower",
    },
    {
      title: "Support",
      backgroundcolor: "#38ad38",
      link: "support",
    },
    {
      title: "Trust",
      backgroundcolor: "#66ca03",
      link: "trust",
    },
    // {
    //   title: "Word from\nthe Board",
    //   backgroundcolor: "#313893",
    //   link: "#",
    // },
  ];

  return (
    <div className="g-card-grid">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          backgroundcolor={card.backgroundcolor}
          link={card.link}
          setPopUpState={props.setPopUpState}
        />
      ))}
    </div>
  );
};

export default Home;
