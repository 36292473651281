import React, { useEffect } from "react";
import Header from "../../components/header";
import "./empowerepage.css";
import HomeVideo from "../../assets/empower_background2.jpg";
import FeatureStories from "./stories";
import FlipCard from "./FlipCard";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//Charts
import EmpowerCharts from './EmpowerCharts';

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="empowere-video-text-box">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="empowere-topic-home">Empower</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div
          style={{ backgroundColor: "#313893", color: "#ffffff" }}
          className="empowerpage-para-conatiner"
        >
          <center>
            <div
              className="empowerpage-para"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <h1>EMPOWER OUR PEOPLE</h1>
              <p>
                <span style={{ color: 'white', fontWeight:'bolder' }}>A LIFE WELL LIVED.</span>{" "}
                This is what we want for every single person at Hirdaramani. It
                is their ideas, passion, and commitment, that go into every
                garment we make. This makes our people our greatest asset and
                the lifeline of our organisation and this is why we’re deeply
                committed to uplifting, inspiring, and protecting them to ensure
                their overall wellbeing.
              </p>
              <p>
                Healthy and fair working conditions, a focus on equal
                opportunity, and personal wellbeing are the hallmarks of our
                Empower pillar. Our Wonders of Wellbeing program best
                exemplifies our commitment to the holistic wellbeing of everyone
                in our workforce.
              </p>
            </div>
          </center>
        </div>

        {/* section-3 */}
        <div className="empower-card">
          <div className="para-box">
            <div data-aos="fade-up-left" data-aos-duration="500">
              <h2 className="para-one-1">
                In our Future First roadmap, we concentrate
              </h2>
              <h2 className="para-one-2">
                our Empower principles across three main areas:
              </h2>
            </div>
            <div data-aos="zoom-in">
              <h1 className="empower-para-two">EMPOWER</h1>
            </div>
            <div className="empower-flip-part">
              <FlipCard />
            </div>
          </div>
        </div>

        {/* section-4 */}
        <FeatureStories />

        {/* Section-5 */}
        <EmpowerCharts />
      </div>
    </ParallaxProvider>
  );
}
