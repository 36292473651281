import React from 'react'
import "./TargetsCharts.css"

export default function OtherEnvironmentalTargetsPieChart_2() {
  return (
    <div className='chart-water-para'>
      <div className="new-water-header-contents">
        <p data-aos="fade-in" data-aos-duration="1000">
          While there has been a slight increase in absolute industrial
          freshwater consumption from a Group perspective, we remain focused on
          achieving the 10% reduction target.
        </p>
      </div>
    </div>
  );
}
