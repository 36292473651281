import React, { useEffect } from "react";
import "./clean-drinking-water-for-children.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/CleanDrinkingWaterVietnam.webp";
import Blue_Background from "../../../../assets/image_015-n.png";
//import Vietnam from "../../../../assets/vietnam-flag.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="childrin-education-p1-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="childrin-education-p1-topic-home"
                  >
                    Clean <br />
                    drinking water <br />
                    for children
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.55)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="childrin-education-p1-contents">
          <div className="childrin-education-p1-content-left">
            <div className="childrin-education-p1-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="childrin-education-p1-content-left-r"></div>
          </div>
          <div className="childrin-education-p1-content-right">
            <h1 className="childrin-education-p1-content-right-heading">
              Vietnam:
            </h1>
            <h1 className="childrin-education-p1-content-right-heading-main">
              Clean drinking water for children
            </h1>
            <div
              className="childrin-education-p1-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                The figures make for stark reading. Each year in Vietnam alone,
                9,000 people die and 250,000 more are hospitalised due to poor
                sanitation and water quality. (The World Bank) With children and
                the elderly among the most vulnerable to water borne diseases
                such as cholera, Hirdaramani has committed to setting up and
                supporting clean drinking water projects in local communities in
                Vietnam. In 2023, we initiated two key projects in Vietnam to
                facilitate access to clean drinking water for local children in
                our communities. to.
              </p>

              <h3>Phu Tan Primary School</h3>
              <p>
                By sponsoring the development of a clean water system at Phu Tan
                Primary School, Tan Phu, Dong Nai, we have been able to support
                over 1,200 school children.
              </p>

              <h3>Lee Quay Primary School</h3>
              <p>
                As part of a larger community project centred on a water
                purification system set up by Hirdraramani, over 1,500 students
                at the Lee Quay Primary School, Xuan Tay, Dong Nai, now have
                access to a clean drinking water supply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
