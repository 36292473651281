import React, { useState } from "react";
//import { Link } from "react-router-dom";
import "./chartEnergy3.css";

import Group from './group.jsx'
import Ethiopia from './ethiopia.jsx'
import Vietnam from './vietnam.jsx'
import Bangladesh from './bangladesh.jsx'
import Srilanka from './srilanka.jsx';

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "group":
        return <Group />;
      case "ethiopia":
        return <Ethiopia />;
      case "vietnam":
        return <Vietnam />;
      case "bangladesh":
        return <Bangladesh />;
      case "srilanka":
        return <Srilanka />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%", padding:'0px 80px' }}>
      <div className="conserve-energy-chart-03">
        <h1 className="conserve-chart-heading">Washing (MJ/Garment)</h1>
        <div className="conserve-energy-chart-03-paragraph-two-column">
          {/* <div className="conserve-energy-chart-1-paragraph-column-1-new">
          </div> */}
          <div className="conserve-energy-chart-03-paragraph-column-2">
            <div>{renderPage()}</div>
          </div>
          <div className="conserve-energy-chart-03-paragraph-column-3">
            <div>
              <div
                className={`conserve-energy-chart-03-text-box-1-opacity ${
                  selectedTab === "group" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("group")}
                onKeyDown={(e) => handleKeyDown(e, "group")}
              >Group</div>
              <br />
              <div 
                className={`conserve-energy-chart-03-text-box-2-opacity ${
                  selectedTab === "ethiopia" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("ethiopia")}
                onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
              >Ethiopia</div>
              <br />
              <div
                className={`conserve-energy-chart-03-text-box-3-opacity ${
                  selectedTab === "vietnam" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("vietnam")}
                onKeyDown={(e) => handleKeyDown(e, "vietnam")}
              >Vietnam</div>
              <br />
              <div
                className={`conserve-energy-chart-03-text-box-4-opacity ${
                  selectedTab === "bangladesh" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("bangladesh")}
                onKeyDown={(e) => handleKeyDown(e, "bangladesh")}
              >Bangladesh</div>
              <br />
              <div
                className={`conserve-energy-chart-03-text-box-5-opacity ${
                  selectedTab === "srilanka" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("srilanka")}
                onKeyDown={(e) => handleKeyDown(e, "srilanka")}
              >Sri Lanka</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}