import React from "react";
import "./greenParagraph.css";

export default function TypewriterAnimation() {

  return (
    <div
      className="chemical-greenArea"
      style={{ backgroundColor: "#1E6D54", color: "#ffffff" }}
    >
      <div
        className="chemical-page-green-para"
        id="parent"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <p className="chemical-paragra">
          With more than 8 billion people living on our planet, waste is
          unsurprisingly one of the world’s most pressing issues – particularly
          for an industry like apparel where we use fabrics, plastics, and
          chemicals on a daily basis.
        </p>
        <p className="chemical-paragr">
          Initiatives such as our Zero Waste to Landfill, and research into
          improved fabric and product circularity is helping us manage our waste
          more responsibly and sustainably.
        </p>
      </div>
    </div>
  );
}
