import React, { useEffect } from "react";
import "./coastal-protection-and-mangrove-projects.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/mangrove_project_background.webp";
import Blue_Background_2 from "../../../../assets/image_030.jpg";
import Blue_Background_1 from "../../../../assets/image_031.jpg";
//import Vietnam from "../../../../assets/vietnam-flag.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="environment-p1-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="environment-p1-topic-home"
                  >
                    COASTAL
                    <br />
                    PROTECTION
                    <br />
                    AND MANGROVE
                    <br />
                    PROJECTS
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.55)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="environment-p1-contents">
          <div className="environment-p1-content-left">
            <div className="environment-p1-content-left-l">
              <img
                src={Blue_Background_1}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="environment-p1-content-left-r"></div>
          </div>
          <div className="environment-p1-content-right">
            <h1 className="environment-p1-content-right-heading">Vietnam & Sri Lanka:</h1>
            <h1 className="environment-p1-content-right-heading-main">
              Coastal protection and mangrove projects
            </h1>
            <div
              className="environment-p1-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                Many of the local communities where we operate enjoy beautiful
                coastal or mangrove environments. Yet the increasing occurrence
                of coastal erosion and mangrove destruction is a continuing
                issue and one with significant impact.
              </p>
              <p>
                Across Vietnam and Sri Lanka, Hirdaramani is committed to
                supporting projects that help mitigate the severe impacts of
                these environmental threats, along with helping educate young
                people in the communities on the importance of environment
                conservation.
              </p>
            </div>
          </div>
        </div>

        {/* para 02 */}
        <div className="screen-pc">
          <div className="environment-p1-contents">
            <div className="environment-p1-content-right">
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <h3>Highlighted projects include:</h3>
              </div>
              <h2 className="environment-p1-content-right-heading-sub-h2">
                Mangrove Conservation
              </h2>
              <h1 className="environment-p1-content-right-heading-sub">
                Vietnam
              </h1>
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  We initiated the Green House Symphony Project in Vietnam in
                  2020 to prevent coastal erosion by planting mangroves. It
                  recently diversified its crops to include food sources and
                  further empower the community. The facility also educates
                  school children about coastal erosion and mangroves.
                </p>
                <p>
                  The project has had a significant impact on reducing erosion
                  on the Cửa Đại beach, and in 2023, over 5,000 plants were
                  added to protect coastal areas.
                </p>
              </div>

              <br />
              <h1 className="environment-p1-content-right-heading-sub">
                Sri Lanka
              </h1>
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  In 2023, a mangrove conservation project was established in
                  collaboration with the Marine Environment Protection Authority
                  of Sri Lanka. A dedicated team from Hirdaramani participated
                  in a project to plant 500 plants in abandoned lands in Chilaw.
                </p>
                <p>
                  In 2023, the UNEP recognized Sri Lanka for its efforts in
                  mangrove regeneration projects, recognizing it as a World
                  Restoration Flagship. We are proud of the amazing efforts made
                  to date and look forward to continuing to support this work.
                </p>
              </div>
              {/* <div style={{ height: "150px" }} /> */}
            </div>
            <div className="environment-p1-content-left">
              <div className="environment-p1-content-left-l-2">
                <img
                  src={Blue_Background_2}
                  alt="Blue Shape"
                  data-aos="flip-right"
                />
              </div>
              <div className="environment-p1-content-left-r-2"></div>
            </div>
          </div>
        </div>
        {/* mobile */}
        <div className="screen-mobile">
          <div className="environment-p1-contents">
            <div className="environment-p1-content-left">
              <div className="environment-p1-content-left-l">
                <img
                  src={Blue_Background_2}
                  alt="Blue Shape"
                  data-aos="flip-right"
                />
              </div>
              <div className="environment-p1-content-left-r"></div>
            </div>
            <div className="environment-p1-content-right">
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <h3>Highlighted projects include:</h3>
              </div>
              <h2 className="environment-p1-content-right-heading-sub-h2">
                Mangrove Conservation
              </h2>
              <h1 className="environment-p1-content-right-heading-sub">
                Vietnam
              </h1>
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  We initiated the Green House Symphony Project in Vietnam in
                  2020 to prevent coastal erosion by planting mangroves. It
                  recently diversified its crops to include food sources and
                  further empower the community. The facility also educates
                  school children about coastal erosion and mangroves.
                </p>
                <p>
                  The project has had a significant impact on reducing erosion
                  on the Cửa Đại beach, and in 2023, over 5,000 plants were
                  added to protect coastal areas.
                </p>
              </div>

              <br />
              <h1 className="environment-p1-content-right-heading-sub">
                Sri Lanka
              </h1>
              <div
                className="environment-p1-content-right-contents"
                data-aos="fade-in"
              >
                <p>
                  In 2023, a mangrove conservation project was established in
                  collaboration with the Marine Environment Protection Authority
                  of Sri Lanka. A dedicated team from Hirdaramani participated
                  in a project to plant 500 plants in abandoned lands in Chilaw.
                </p>
                <p>
                  In 2023, the UNEP recognized Sri Lanka for its efforts in
                  mangrove regeneration projects, recognizing it as a World
                  Restoration Flagship. We are proud of the amazing efforts made
                  to date and look forward to continuing to support this work.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* para 02 - end */}
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
