import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Background_Img from "../../../../assets/career-development-page.webp";
import PACE_Image_01 from "../../../../assets/image_23.jpg";
import workplace_training_and_education_programs_image_01 from "../../../../assets/image_21.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import "./SpotLight.css";



export default function f() {
  return (
    <ParallaxProvider>
      {/* Section 1 */}

      <div style={{ position: "relative" }}>
        <div className="spotlight-background">
          <div>
            <img
              src={Background_Img}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div className="spotlight-text-box-2">
            <div>
              <Parallax speed={10}>
                <h1 data-aos="fade-right" className="spotlight-topic-home-spot">
                  SPOTLIGHT
                </h1>
              </Parallax>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            zIndex: 0,
          }}
        ></div>
      </div>

      {/* Section 2 */}
      {/* 01 */}
      <div className="spotlight-wow-gender-section">
        <div className="career-development-spotlight-wow-gender-section-left-01">
          <img
            src={PACE_Image_01}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="spotlight-wow-gender-section-right">
          <div className="spotlight-wow-gender-section-right-contents small-heading">
            <div className="flag-container">
              <div className="flag-box">
                {/* <img src={Vietnam} alt="flag" /> */}
              </div>
            </div>
            <h1 data-aos="fade-in">
              P.A.C.E.(Personal Advancement and Career Enhancement) Program
            </h1>
            <p data-aos="fade-in">
              Dedicated to empowering women in the apparel industry through
              personal and professional development, the P.A.C.E. (Personal
              Advancement and Career Enhancement) program is one of our great
              success stories from the recent past.{" "}
            </p>
            <div data-aos="fade-in">
              <a
                href="/empower/career-development/spotLight-PACE/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 02 */}
      <div className="spotlight-wow-gender-section">
        <div className="career-development-spotlight-wow-gender-section-left">
          <img
            src={workplace_training_and_education_programs_image_01}
            alt="WOW Gender"
            data-aos="flip-right"
          />
        </div>
        <div className="spotlight-wow-gender-section-right">
          <div className="spotlight-wow-gender-section-right-contents">
            <div className="flag-container">
              <div className="flag-box">
                {/* <img src={Bangladesh} alt="flag" /> */}
              </div>
            </div>
            <h1 data-aos="fade-in">
              Workplace training and education programs
            </h1>
            <p data-aos="fade-in">
              Hirdaramani places great value on the long-term benefits of
              workplace training and education. We operate many programs to help
              employees advance their skills and careers.{" "}
            </p>
            <div data-aos="fade-in">
              <a
                href="/empower/career-development/spotLight-workplace-training-and-education-programs/"
                className="link"
              >
                <button>
                  Read the full story{" "}
                  <div className="spotlight-wow-gender-icon">
                    <MdArrowRightAlt size={30} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}
