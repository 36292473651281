import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./wellbing.css";
import HomeVideo from "../../../assets/support_background.webp";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Healthcare from "./healthcare-part";
import Community from "./community-part";
import FlipCard from './FlipCard'

export default function Wellbing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        {/* home video */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
              {/* <video
              autoPlay
              loop
              muted
              className="home-video"
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video> */}
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="wellbing-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 className="wellbing-topic-home">WELLBEING</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <Healthcare />

        {/* section-3 */}
        <Community />

        {/* Section - 4 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
