import React from "react";
import Page from "../templates/support/wellbing/spotlight/undp-backyard-poultry-and-market";
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>UNDP Backyard Poultry and Market | Hirdaramani</title>
        <meta
          name="title"
          content="UNDP Backyard Poultry and Market | Hirdaramani"
        />
        <meta
          name="description"
          content="Hirdaramani, with Lacoste Foundation & UNDP, aids Sri Lanka's recovery through Integrated Backyard Poultry & Market Development, empowering 100+ families."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-undp-backyard-poultry-and-market/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Backyard Poultry and Marke" />
        <meta
          property="og:title"
          content="UNDP Backyard Poultry and Market | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Hirdaramani, with Lacoste Foundation & UNDP, aids Sri Lanka's recovery through Integrated Backyard Poultry & Market Development, empowering 100+ families."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-undp-backyard-poultry-and-market/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight;
