import React, { useState } from "react";
//import { Link } from "react-router-dom";
import "./chart.css";

import Supervisor from './suppervisor.jsx'
import SeniorManager from './senior_manager.jsx'
import MiddleManager from './middle_manager.jsx'
import Executives from './executives.jsx'

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("supervisors");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "supervisors":
        return <Supervisor />;
      case "senior-manager":
        return <SeniorManager />;
      case "middle-manager":
        return <MiddleManager />;
      case "executives":
        return <Executives />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="empower-chart-4-srilanka-paragraph-two-column empower-equality-chart-02">
          <div className="empower-chart-4-srilanka-paragraph-column-1-new">
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-2-new">
            <div>{renderPage()}</div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-3-new empower-equality-chart-02">
            <div>
              <br />
              <div
                className={`sri-lanka-text-box-1-opacity ${
                  selectedTab === "supervisors" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("supervisors")}
                onKeyDown={(e) => handleKeyDown(e, "supervisors")}
              >Supervisors</div>
              <br />
              <div
                className={`sri-lanka-text-box-4-opacity ${
                  selectedTab === "executives" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("executives")}
                onKeyDown={(e) => handleKeyDown(e, "executives")}
              >Executives</div>
              <br />
              <div
                className={`sri-lanka-text-box-3-opacity ${
                  selectedTab === "middle-manager" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("middle-manager")}
                onKeyDown={(e) => handleKeyDown(e, "middle-manager")}
              >Middle Manager</div>
              <br />
              <div 
                className={`sri-lanka-text-box-2-opacity ${
                  selectedTab === "senior-manager" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("senior-manager")}
                onKeyDown={(e) => handleKeyDown(e, "senior-manager")}
              >Senior Manager</div>
            </div>
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="empower-chart-4-srilanka-paragraph-two-column empower-equality-chart-02">
            <div className="empower-chart-4-srilanka-paragraph-column-4-new">
              <br />
            </div>
            <div className="empower-chart-4-srilanka-paragraph-column-5-new"></div>
            <div className="empower-chart-4-srilanka-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}