import React, { useEffect } from 'react';
import './sustainability_report.css'
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
// import { Link } from "react-router-dom";

// Images
import sustainability_report_img from '../../assets/sustainability_report_img.webp'

//Icons
// import { MdArrowRightAlt } from "react-icons/md";

//AOS
import Aos from 'aos'
import 'aos/dist/aos.css'

function SustainabilityReport() {
  
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])

  return (
    <ParallaxProvider>
      <div className="sus-rep-conatiner">
        <div className="sus-rep-main-contents">
          <div className="sus-rep-left-contents">
            <div className="sus-rep-back"></div>
            <div className="sus-rep-front-image">
              <ParallaxBanner
                style={{ aspectRatio: "1 / 0.8" }}
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <ParallaxBannerLayer
                  image={sustainability_report_img}
                  speed={6}
                />
              </ParallaxBanner>
              {/* <img
                src={sustainability_report_img}
                alt=""
                data-aos="fade-right"
                data-aos-duration="1500"
              /> */}
            </div>
          </div>

          <div className="sus-rep-right-contents">
            <div className="sus-rep-right-content">
              <h3 data-aos="fade-in" data-aos-duration="2000">
                PUTTING THE FUTURE FIRST
              </h3>
              <h1
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="1000"
              >
                OUR SUSTAINABILITY REPORT 2023
              </h1>
              <p
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="1500"
              >
                <p>
                  Words matter. Commitments matter. But only actions create
                  change.
                </p>
                <p>
                  Actions are at the very centre of our sustainability journey.
                  Every target we set for ourselves is measured and monitored.
                  Our sustainability goals live in reality, not spreadsheets.
                </p>
                <p>
                  We believe that our actions today will help create a future
                  where Hirdaramani keeps doing what we do best – provide
                  solutions.
                </p>
              </p>
              <div
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="1800"
                data-aos-offset="-500"
              >
                {/* <Link to="/#" className='link'>
                  <button className='target_button'>
                    A word from the board{" "} */}
                    {/* <div className="sus-rep-right-content-icon">
                      <MdArrowRightAlt size={30} />
                    </div> */}
                  {/* </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SustainabilityReport
