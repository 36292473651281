import React, { useEffect } from "react";
import "./support-for-hospitals.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/spotlight-support-for-hospitals.webp";
import Blue_Background from "../../../../assets/image_025.jpg";
//import Vietnam from "../../../../assets/vietnam-flag.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="healthcare-p1-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="healthcare-p1-topic-home"
                  >
                    Spotlight <br />
                    support for <br />
                    hospitals
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="healthcare-p1-contents">
          <div className="healthcare-p1-content-left">
            <div className="healthcare-p1-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="healthcare-p1-content-left-r"></div>
          </div>
          <div className="healthcare-p1-content-right">
            {/* <h1 className="healthcare-p1-content-right-heading">Vietnam:</h1> */}
            <h1 className="healthcare-p1-content-right-heading-main">
              Spotlight support for hospitals
            </h1>
            <div
              className="healthcare-p1-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                The key healthcare services that hospitals provide to local
                communities should never be taken for granted. But sometimes
                infrastructure and resources are not readily available to ensure
                the quality of services that local populations require. As an
                organisation that strives to address the needs of the community,
                we play a vital role in supporting local hospitals.
              </p>

              <p>
                The type of support we provide varies on the requirements of
                each healthcare facility. Our 2023 highlights include:
              </p>
              <ul>
                <li>
                  <span style={{ color: "black" }}>
                    Lady Ridgeway Hospital (LRH)
                  </span>{" "}
                  — LRH has been one of our key beneficiaries for many years as
                  part of the work we do through the Hirdaramani Memorial Trust
                  (HMT). In 2023, we donated vital new operating theatre
                  equipment, as well as 250 medicinal vials, impacting 267 of
                  its patients.
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    National Institute for Nephrology Dialysis Hospital and
                    Transplantation
                  </span>{" "}
                  — We contributed seating and food service utensils, with the
                  aim of providing valuable logistical support for 600 patients
                  of the unit.
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    Financial donations — Over the course of 2023,
                  </span>{" "}
                  we supported the Sri Lanka Cancer Society, Apeksha Cancer
                  Hospital, CCC Cancer Hospital, and the Colombo General
                  Hospital to ensure that patients of these facilities were
                  benefitted through these efforts
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
