import React from "react";
// import React, { useState } from "react";
//import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./srilanka.css";

export default function Male() {
  // const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
    // onEnter={() => setCounterOn(true)}
    // onExit={() => setCounterOn(false)}
    >
      <div className="new-sri-lanka-box-1 new-career-development-sri-lanka-box-1">
        189 attendees from a 1,161 headcount
      </div>
      <br />
      <div className="new-sri-lanka-box-2 new-career-development-sri-lanka-box-2">
        10,633 attendees from a 11,244 headcount
      </div>
      <br />
      <div className="new-sri-lanka-box-3 new-career-development-sri-lanka-box-3">
        27,016 attendees from 16,700 headcount
      </div>
      <br />
      <div className="new-sri-lanka-box-4 new-career-development-sri-lanka-box-4">
        {/* {counterOn && <CountUp start={0} end={59} duration={3} delay={0.3} />}% */}
        18,622 attendees from a 16,693 headcount
      </div>
    </ScrollTrigger>
  );
}
