import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import "./wellbeing-page.css";
import FlipCard from "./FlipCard";

//Images
import Home_Background from "../../../assets/Eye_care_at_BEPZA.webp";
import WOW_Background from "../../../assets/WOW_Logo_page-V2.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import NumberCard from "./numbercard";
import ScrollTrigger from "react-scroll-trigger";

//Charts
import WellBeingCharts from "./WellbeingCharts";
import Chart02 from './Chart-2/Sri-Lanka';

//Pages
import SpotLight from "./spotlight/SpotLight";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

export default function Homepage() {
  const [renderState, setRenderState] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="e-wellbeing-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 className="e-wellbeing-topic-home">WELLBEING</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.35)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="e-wellbeing-cards">
          <div className="e-wellbeing-para-box">
            <div data-aos="zoom-in">
              <h1 className="e-wellbeing-para-two">WELLBEING</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="e-wellbeing-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <div style={{ position: "relative" }}>
          <div className="wow-page-background">
            <div>
              <center>
                <img
                  src={WOW_Background}
                  style={{ width: "35%", objectFit: "cover" }}
                  alt=""
                  data-aos='fade-in'
                />
              </center>
            </div>
          </div>
        </div>
        <div className="e-wellbeing-paragraph-section-1">
          <ScrollTrigger onEnter={() => setRenderState(true)}>
            {renderState && (
              <div className="e-wellbeing-paragraph-section-1-contents">
                <div className="e-wellbeing-paragraph-section-1-center">
                  <p>
                    Wellbeing is a critical part of our Empower pillar. To that
                    end, we have been actively implementing the Wonders of
                    Wellbeing (WOW) program since 2014 to help our employees live
                    “healthy, wealthy, and meaningful lives”.
                  </p>
                  <p>
                    At Hirdaramani, we don’t view wellbeing as a tick in the box
                    concept. Rather, it is strategically integrated it into our
                    business operations, agenda, and culture. WOW is presently
                    fully embedded in our operations in Sri Lanka and Vietnam, and
                    is being rolled out progressively in Bangladesh* and Ethiopia.
                    We further empower wellbeing champions across our footprint to
                    spearhead key initiatives under the WOW umbrella.
                  </p>
                  <p>
                    WOW case studies have been presented to and recognised by
                    Harvard University’s T.H. Chan School of Public Health, SHINE
                    (Sustainability and Health Initiative for NetPositive
                    Enterprise), and public health advocates Swasti Health
                    Catalyst.
                  </p>
                  <p>WOW is founded on five main pillars :</p>
                  <p className="e-wellbeing-paragraph-section-1-center-yello-p">
                    <ul>
                      <li>Psychological wellbeing</li>
                      <li>Relational wellbeing</li>
                      <li>Physiological wellbeing</li>
                      <li>Economic wellbeing</li>
                      <li>Environmental wellbeing</li>
                    </ul>
                  </p>

                  <p>
                    We constantly measure and track the performance of WOW across
                    120 different indicators, with employee involvement vital to
                    providing transparent feedback and pathways for continuous
                    improvement.
                  </p>
                  <p>
                    <i>
                      * Setting up WOW trainers alongside of the P.A.C.E.
                      (Personal Advancement and Career Enhancement) program and
                      trainers to support the integration of WOW into Bangladesh.
                    </i>
                  </p>
                  <br />
                </div>
                
                <div className="wellbeing-single-bar-chart-container">
                  <Chart02 />
                </div>
              </div>
            )}
          </ScrollTrigger>
          
        </div>

        {/* Secion 4 */}
        <WellBeingCharts />

        {/* Section 5 */}
        <SpotLight />

        {/* Section 6 */}
        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
