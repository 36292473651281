import React, { useState, useEffect, useRef } from "react";
import "./image-chart.css";
import bottleImage from "../../../../../assets/support-4.png";

const Chart = () => {
  const [waterPercentage, setWaterPercentage] = useState(0);
  const chartSectionRef = useRef(null);

  useEffect(() => {
    const chartSectionNode = chartSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const timer = setTimeout(() => {
              const waterHeight = (100 / 100) * 250;
              setWaterPercentage(waterHeight);
            }, 300); // Adjust delay time as needed

            return () => clearTimeout(timer);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (chartSectionNode) {
      observer.observe(chartSectionNode);
    }

    return () => {
      if (chartSectionNode) {
        observer.unobserve(chartSectionNode);
      }
    };
  }, []);

  return (
    <div className="chart-section" ref={chartSectionRef}>
      <div className="support-wellbeing-image-chart-container-2">
        <div className="support-wellbeing-image-chart2">
          <img className="support-wellbeing-image-chart-image" src={bottleImage} alt="Bottle" />
          <div
            className="support-wellbeing-image-chart-water2"
            style={{ height: waterPercentage }}
          />
        </div>
      </div>
    </div>
  );
};

export default Chart;
