import React, { useState, useEffect } from "react";
import "./Waste_chart_02.css";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

import Srilanka2019 from './srilanka/group_2019.jsx';
import Srilanka2021 from './srilanka/group_2021.jsx';
import Srilanka2022 from './srilanka/group_2022.jsx';
import Srilanka2023 from './srilanka/group_2023.jsx';

import Bangladesh2019 from './bangladesh/group_2019.jsx';
import Bangladesh2021 from './bangladesh/group_2021.jsx';
import Bangladesh2022 from './bangladesh/group_2022.jsx';
import Bangladesh2023 from './bangladesh/group_2023.jsx';

import Ethiopia2019 from './ethiopia/group_2019.jsx';
import Ethiopia2021 from './ethiopia/group_2021.jsx';
import Ethiopia2022 from './ethiopia/group_2022.jsx';
import Ethiopia2023 from './ethiopia/group_2023.jsx';

import HGroup2019 from './h-group/group_2019.jsx';
import HGroup2021 from './h-group/group_2021.jsx';
import HGroup2022 from './h-group/group_2022.jsx';
import HGroup2023 from './h-group/group_2023.jsx';

import Vietnam2019 from './vietnam/group_2019.jsx';
import Vietnam2021 from './vietnam/group_2021.jsx';
import Vietnam2022 from './vietnam/group_2022.jsx';
import Vietnam2023 from './vietnam/group_2023.jsx';

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const [year, setYear] = useState(2023);
  const [btnPlusState, setBtnPlusState] = useState(false);
  const [btnMinusState, setBtnMinusState] = useState(false);

  const handleYearPlusCount = () =>{
    if(year === 2019){
      setYear(year+2)
    }else if(year !== 2023){
      setYear(year+1)
    }
  }
  
  const handleYearMinusCount = () =>{
    if(year === 2021 ){
      setYear(year-2)
    }else if(year !== 2019){
      setYear(year-1)
    }
  }

  useEffect(() => {
    if(year >= 2023){
      setBtnPlusState(false)
      setBtnMinusState(true)
    }else if(year <= 2019){
      setBtnMinusState(false)
      setBtnPlusState(true)
    }else{
      setBtnMinusState(true)
      setBtnPlusState(true)
    }
  },[year])

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderSrilankaPages =()=>{
    switch (year) {
      case 2019:
        return <Srilanka2019 />;
      case 2021:
        return <Srilanka2021 />;
      case 2022:
        return <Srilanka2022 />;
      case 2023:
        return <Srilanka2023 />;
      default:
        return null;
    }
  }

  const renderHGroupPages =()=>{
    switch (year) {
      case 2019:
        return <HGroup2019 />;
      case 2021:
        return <HGroup2021 />;
      case 2022:
        return <HGroup2022 />;
      case 2023:
        return <HGroup2023 />;
      default:
        return null;
    }
  }

  const renderBangladeshPages =()=>{
    switch (year) {
      case 2019:
        return <Bangladesh2019 />;
      case 2021:
        return <Bangladesh2021 />;
      case 2022:
        return <Bangladesh2022 />;
      case 2023:
        return <Bangladesh2023 />;
      default:
        return null;
    }
  }

  const renderEthiopiaPages =()=>{
    switch (year) {
      case 2019:
        return <Ethiopia2019 />;
      case 2021:
        return <Ethiopia2021 />;
      case 2022:
        return <Ethiopia2022 />;
      case 2023:
        return <Ethiopia2023 />;
      default:
        return null;
    }
  }

  const renderVeitnamPages =()=>{
    switch (year) {
      case 2019:
        return <Vietnam2019 />;
      case 2021:
        return <Vietnam2021 />;
      case 2022:
        return <Vietnam2022 />;
      case 2023:
        return <Vietnam2023 />;
      default:
        return null;
    }
  }

  const renderPage = () => {
    switch (selectedTab) {
      case "group":
        return renderHGroupPages();
      case "ethiopia":
        return renderEthiopiaPages();
      case "vietnam":
        return renderVeitnamPages();
      case "bangladesh":
        return renderBangladeshPages();
      case "srilanka":
        return renderSrilankaPages();
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%", padding:'0px 20px' }}>
      <div className="conserve-waste-chart-01">
        <h1 className="conserve-chart-heading">Fabric Waste Per Garment (g/Garment)</h1>
        <div className="conserve-waste-chart-01-paragraph-two-column">
          <div className="conserve-waste-chart-01-paragraph-column-1-new">
          </div>
          <div className="conserve-waste-chart-01-paragraph-column-2-new">
            <div>{renderPage()}</div>
          </div>
          <div className="conserve-waste-chart-01-paragraph-column-3-new">
            <div>
              <div
                className={`conserve-waste-chart-01-text-box-1-opacity ${
                  selectedTab === "group" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("group")}
                onKeyDown={(e) => handleKeyDown(e, "group")}
              >
                {/* <img src={HGroupLogo} alt="" /> */}
                <h4>H-Group</h4>
              </div>
              <div 
                className={`conserve-waste-chart-01-text-box-2-opacity ${
                  selectedTab === "ethiopia" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("ethiopia")}
                onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
              >
                {/* <img src={EthiopiaLogo} alt="" /> */}
                <h4>Ethiopia</h4>
              </div>
              <div
                className={`conserve-waste-chart-01-text-box-3-opacity ${
                  selectedTab === "vietnam" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("vietnam")}
                onKeyDown={(e) => handleKeyDown(e, "vietnam")}
              >
                {/* <img src={VietnamLogo} alt="" /> */}
                <h4>Vietnam</h4>
              </div>
              <div
                className={`conserve-waste-chart-01-text-box-4-opacity ${
                  selectedTab === "bangladesh" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("bangladesh")}
                onKeyDown={(e) => handleKeyDown(e, "bangladesh")}
              >
                {/* <img src={BangladeshLogo} alt="" /> */}
                <h4>Bangladesh</h4>
              </div>
              <div
                className={`conserve-waste-chart-01-text-box-5-opacity ${
                  selectedTab === "srilanka" ? "selected-text" : ""
                }`}
                onClick={() => handleTabClick("srilanka")}
                onKeyDown={(e) => handleKeyDown(e, "srilanka")}
              >
                {/* <img src={SriLankaLogo} alt="" /> */}
                <h4>Sri Lanka</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style={{ marginTop: "-20px" }}>
          <div className="conserve-waste-chart-01-paragraph-two-column">
            <div className="conserve-waste-chart-01-paragraph-column-4-new">
              <br />
            </div>
            <div className="conserve-waste-chart-01-paragraph-column-5-new">
              <div className="conserve-waste-chart-01-letter letter-content-01">
                <div className="conserve-waste-chart-01-letter-icons">
                  <TiArrowSortedUp 
                    onClick={()=>handleYearPlusCount()} 
                    className={`conserve-waste-chart-01-letter-icon ${btnPlusState ? 'selected-icon':''}`}
                  />

                  <TiArrowSortedDown 
                    onClick={()=>handleYearMinusCount()} 
                    className={`conserve-waste-chart-01-letter-icon ${btnMinusState? 'selected-icon':''}`}
                  />
                </div>
                <h4 style={{userSelect:'none'}}>{year}</h4>
              </div>
              <div className="conserve-waste-chart-01-letter letter-content-02">
                <h4 style={{userSelect:'none'}}>Target</h4>
              </div>
            </div>
            <div className="conserve-waste-chart-01-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}