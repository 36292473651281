import React from "react";
import Page from "../templates/support/wellbing/spotlight/support-for-hospitals";
import { Helmet } from "react-helmet";

function spotlight() {
  return (
    <div>
      <Helmet>
        <title>CSR Projects | Support for Hospitals | Hirdaramani</title>
        <meta
          name="title"
          content="CSR Projects | Support for Hospitals | Hirdaramani"
        />
        <meta
          name="description"
          content="Hirdaramani aided hospitals with equipment & donations in 2023, benefiting patients at Lady Ridgeway & Nephrology Dialysis and cancer patients."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-support-for-hospitals/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="CSR Projects" />
        <meta
          property="og:title"
          content="CSR Projects | Support for Hospitals | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Hirdaramani aided hospitals with equipment & donations in 2023, benefiting patients at Lady Ridgeway & Nephrology Dialysis and cancer patients."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/support/wellbeing/spotlight-support-for-hospitals/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default spotlight;
