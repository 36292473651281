import React, { useEffect } from "react";
import "./fare-spectra-school-of-autism.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/childrin&education_spotlight.webp";
import Blue_Background from "../../../../assets/image_028.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="childrin-education-p2-text-box">
              <div>
                <Parallax speed={10}>
                  <h1
                    data-aos="fade-right"
                    className="childrin-education-p2-topic-home"
                  >
                    Fare spectra <br />
                    school of <br />
                    autism
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.35)",
              zIndex: 0,
            }}
          ></div>
        </div>

        <div className="childrin-education-p2-contents">
          <div className="childrin-education-p2-content-left">
            <div className="childrin-education-p2-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
              {/* <div
                className="childrin-education-p2-content-left-l-content"
                data-aos="fade-in"
                data-aos-delay="1000"
              >
                <p>
                  Under our successful and globally recognized Wonders of
                  Wellbeing (WOW) program, Hirdaramani introduced a new pilot
                  project across two Sri Lankan facilities in 2023 aimed at
                  developing ‘gender enablers’ at leadership level. With the
                  support of the Board, the project also aimed to create and
                  implement a gender inclusive policy for the Group.
                </p>
              </div> */}
            </div>
            <div className="childrin-education-p2-content-left-r"></div>
          </div>
          <div className="childrin-education-p2-content-right">
            <h1 className="childrin-education-p2-content-right-heading">
              Bangladesh:
            </h1>
            <h1 className="childrin-education-p2-content-right-heading-main">
              FARE - Spectra School of Autism
            </h1>
            <div
              className="childrin-education-p2-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                FARE - Foundation for Autism Research and EducationWe are deeply
                committed to the health and wellbeing of all children as part of
                our efforts to uplift lives across communities in the countries
                we operate. 'FARE - Foundation for Autism Research and
                Education' is one of our initiatives towards inclusive education
                led by Hirdaramani Bangladesh in a decade-long partnership. Over
                the years, we have supported the school's maintenance and in
                2023, we embarked on a special project to enhance the students'
                communication skills through a tech platform. The solution
                included the setting up of infrastructure and provision of
                required equipment and software for the children’s use at the
                facility.
              </p>
              <p>
                The institute is maintained with funding from Hirdaramani and 75
                students are empowered with better access to technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
