import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./srilanka.css";

export default function Female() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <br />
      <div className="sri-lanka-box-2-chart-1 career-development-sri-lanka-box-2-chart-1">
        {counterOn && <CountUp decimals={1} start={0} end={17.4} duration={3} delay={0.3} />}
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="sri-lanka-box-3-chart-1 career-development-sri-lanka-box-3-chart-1">
        {counterOn && <CountUp start={0} end={6} duration={3} delay={0.3} />}
      </div>
    </ScrollTrigger>
  );
}
