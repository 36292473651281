import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaCaretRight } from "react-icons/fa6";
import "./numbercard.css";

const Card = (props) => {
  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div
      className="water-card"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      <div className="water-card-circle-container">
        <div
          className="water-card-circle"
          style={{ backgroundColor: props.circlbackgroundcolor }}
        >
          <p>{props.circlenumber}</p>
        </div>
        <div 
          className="hover-area"
          style={{ backgroundColor: props.circlbackgroundcolor,
          opacity:1 }}
        >
          <p className="tooltip-texts">{props.tooltip}</p>
          <FaCaretRight size={30} className="hover-area-arrow"
          style={{ color: props.circlbackgroundcolor,
          opacity:1 }}
          />
        </div>
      </div>
      
      <div>
        <h2
          className="water-card-title"
          style={{ color: props.titlefontcolor }}
        >
          {renderTitle(props.title)}
        </h2>
        <p
          className="water-card-description"
          style={{ color: props.fontcolor }}
        >
          {renderDescription(props.description)}
        </p>
      </div>
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <div onClick={onClick} className="water-arrow water-left-arrow">
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div onClick={onClick} className="water-arrow water-right-arrow">
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const Home = () => {
  const cards = [
    {
      title: "3.53 \nL/Garment",
      titlefontcolor: "#313893",
      description:
        "Reduction in normalised industrial freshwater consumed compared to 2022",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E4",
      circlbackgroundcolor: "#3CE5B2",
      tooltip: "50% Reduction in Normalised Industrial Freshwater Consumption",
    },
    {
      title: "\n2,548 dam³",
      titlefontcolor: "#313893",
      description: "Slight increase in absolute industrial freshwater consumed",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E5",
      circlbackgroundcolor: "#3CB4E5",
      tooltip: "10% Reduction in Absolute Industrial Freshwater Consumption",
    },
    {
      title: "72 L/per\nemployee/day",
      titlefontcolor: "#313893",
      description: "Increase compared to 2022 but remains below the target",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E6",
      circlbackgroundcolor: "#3CE5B2",
      tooltip: "10% Reduction in Normalised Domestic Freshwater Consumption",
    },
    {
      title: "\n36%",
      titlefontcolor: "#313893",
      description: "Recycled water used back in the garment washing process",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E7",
      circlbackgroundcolor: "#3CB4E5",
      tooltip:
        "Recycle over 50% of Industrial Wastewater for Industrial Processes",
    },
    {
      title: "\n1%",
      titlefontcolor: "#313893",
      description: "of domestic water consumption fulfilled by rainwater",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E8",
      circlbackgroundcolor: "#3CE5B2",
      tooltip: "Harvest Minimum 10% of Domestic Water from Rainwater",
    },
  ];

  const settings = {
    dots: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={index}>
          <Card
            title={card.title}
            description={card.description}
            backgroundcolor={card.backgroundcolor}
            fontcolor={card.fontcolor}
            circlenumber={card.circlenumber}
            circlbackgroundcolor={card.circlbackgroundcolor}
            titlefontcolor={card.titlefontcolor}
            tooltip={card.tooltip}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Home;
