import React, { useEffect } from "react";
import "./CareerDevelopmentCharts02.css";

//Charts
import CareerDevelopmentDonutChart01 from "./charts/career-chart-1/career-chart-1";
import CareerDevelopmentDonutChart02 from "./charts/career-chart-2/career-chart-2";
import CareerDevelopmentDonutChart06 from "./charts/career-chart-6/srilanka";
import CareerDevelopmentBarChart04 from "./charts/career-chart-4/career-chart-4";
// import CareerDevelopmentBarChart04 from "./CareerDevelopmentBarChart04";
import CareerDevelopmentPieChart01 from "./charts/career-chart-5/career-chart-5";
import CareerDevelopmentPieChart02 from "./charts/career-chart-8/chart-4";
import CareerDevelopmentSingleBarChart01 from "./CareerDevelopmentSingleBarChart01";
import CareerDevelopment03BarChart from "./charts/career-chart-9/chart-9";
//import CareerDevelopmentPieChart03 from './CareerDevelopmentPieChart03';
//import CareerDevelopmentNewChart from "./charts/career-chart-7/chart-4";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function OtherEnvironmentalTargetsCharts() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="career-development-chart-container2">
      <div className="empower-career-dev-bolder-box-line-01">
        <div
          className="career-development-header-content-left-01"
          data-aos="flip-down"
          data-aos-duration="2000"
        >
          <h1>
            Other Development Highlights
          </h1>
        </div>
      </div>

      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <center>
            <CareerDevelopmentDonutChart01 />
          </center>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <center>
            <CareerDevelopmentDonutChart02 />
          </center>
        </div>
      </div>
      

      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left career-chart-06">
          <center>
            <p data-aos="fade-in" data-aos-duration="2000">
              <strong>Average Hours of Training by Gender & Function</strong>
            </p>
            <br />
            <CareerDevelopmentDonutChart06 />
          </center>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <center>
            <CareerDevelopmentBarChart04 />
          </center>
        </div>
      </div>

      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <p data-aos="fade-in" data-aos-duration="2000">
            <strong>Occupational health and safety</strong>
          </p>
          <br />
          <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
            Occupational health and safety is a critical element of our apparel
            manufacturing business. To ensure safety compliance and proper
            processes at all times, we have specialised teams at both the
            factory and Group levels. We also conduct regular training programs
            that cover general health and safety, job/task-specific safety, and
            induction training for the overall workforce as well.
          </p>
          <br />
          <p data-aos="fade-in" data-aos-duration="2000">
            Total number of people participating in OSH T\training programs in
            2023: 56,460*
          </p>
          <p data-aos="fade-in" data-aos-duration="2000">
            Total hours of training in OSH provided in 2023: 1,659
          </p>
          <p data-aos="fade-in" data-aos-duration="2000">
            *Note: With 50,672 employees in total getting trained, this indicates that a % of employees have received more than one occupational health and safety training.
          </p>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <center>
            <CareerDevelopmentPieChart01 />
            <CareerDevelopmentPieChart02 />
          </center>
        </div>
      </div>


      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left">
          <p data-aos="fade-in" data-aos-duration="2000">
            <strong>Work related injuries</strong>
          </p>
          <br />
          <p data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
            We have successfully ensured zero fatalities and high consequence
            injuries across the Group. With 261 non-high consequence Injuries
            across the Group considered comparatively low across our total
            footprint, we continue to drive a strong focus on compliance and
            training as well as machine maintenance and safety to ensure that
            even non-high consequence injuries are maintained at low rates.
          </p>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <center>
            <CareerDevelopmentSingleBarChart01 />
          </center>
        </div>
      </div>
      
      
      <div className="conserve-empower-bolder-box-line-content">
        <div className="conserve-empower-bolder-box-line-content-left conserve-empower-chart-09">
          <center>
            <CareerDevelopment03BarChart />
          </center>
        </div>
        <div className="conserve-empower-bolder-box-line-content-right">
          <center>
            {/* <CareerDevelopmentNewChart /> */}
          </center>
        </div>
      </div>
    </div>
  );
}

export default OtherEnvironmentalTargetsCharts;
