import React from 'react'
import './accessibility.css'
import Accessibilik from'accessibility-react-widget';

function Accessibility() {
  return (
    <div>
        <Accessibilik/>
    </div>
  )
}

export default Accessibility
