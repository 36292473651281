import React, { useRef } from 'react'
import './PopupMessage.css'

// Icons
import { MdOutlineClose } from "react-icons/md";

function PopupMessage({popUpState, setPopUpState}) {

    const messageBox = useRef(null);
    const closeMessage = (e)=>{
        if(e.target === messageBox.current){
            setPopUpState(false);
        }
    }

    const closeMessageByBtn = ()=>{
        setPopUpState(false)
    }

  return (
    <div className={`message-box ${popUpState?'':'hide'}`} ref={messageBox} onClick={closeMessage}>
        <div className='road-map-messages'>
            <div className="road-map-messages-header">
                <div className="road-map-message-title">
                    <h3>OUR 2025 FUTURE FIRST ROADMAP</h3>
                </div>
                <div className="road-map-message-icon">
                    <MdOutlineClose size={30} onClick={closeMessageByBtn} />
                </div>
            </div>
            <center>
                <div className="road-map-message-contents">
                    <h5>‘Sustainability is a journey’</h5>
                    <div className="road-map-message-contents-p">
                        <p>
                            This is a statement that’s well integrated into our DNA and has been a way of life since our early days – well before environmental challenges were considered a corporate responsibility. This commitment has since then been brought into the work we do in our business, our people, and our communities. We have thought long and hard about our responsibilities and accountabilities under this scope and have formulated these into a plan that we call our Future First Roadmap 2025. 
                        </p>
                        <p>
                            For us, it is a journey. But not an unplanned one. Our Future First Roadmap 2025 is our blueprint for the future and our Sustainability efforts – our commitment to doing the right thing.
                        </p>
                        <p>
                            Under our framework of four pillars — Conserve our Environment, Empower our Associates, Support our Communities, and Trust in Good Choices — the Future First Roadmap 2025 sets out our core Sustainability focus areas, what we want to achieve as targets, how we’re going to do it, and our progress thus far. 
                        </p>
                        <p>
                            Our goal is to make measurable and impactful differences that will benefit generations of today and tomorrow. 
                        </p>
                        <p>
                            We believe in our power to make a difference and strive to be a global benchmark in sustainability. We also believe that likeminded partners can help our journey of amplifying impact. So, we welcome all stakeholders – our employees, customers, suppliers, and local communities — to join us on our journey to put the future first.
                        </p>
                    </div>
                </div>
            </center>
        </div>
    </div>
  )
}

export default PopupMessage
