import React, { useEffect } from "react";
import "./building-the-khang-chien-bridge.css";
import Header from "../../../../components/header";

//Images
import Home_Background from "../../../../assets/building-the-khang-chien-bridge.webp";
import Blue_Background from "../../../../assets/image_024-n.png";
//import Vietnam from "../../../../assets/vietnam-flag.png";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function SpotLight() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-background">
            <div>
              <img
                src={Home_Background}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="community-p1-text-box">
              <div>
                <Parallax speed={10}>
                  <h1 data-aos="fade-right" className="community-p1-topic-home">
                    Building
                    <br />
                    the Khang <br />
                    Chien Bridge
                  </h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* para 01 */}
        <div className="community-p1-contents">
          <div className="community-p1-content-left">
            <div className="community-p1-content-left-l">
              <img
                src={Blue_Background}
                alt="Blue Shape"
                data-aos="flip-right"
              />
            </div>
            <div className="community-p1-content-left-r"></div>
          </div>
          <div className="community-p1-content-right">
            {/* <h1 className="community-p1-content-right-heading">Vietnam:</h1> */}
            <h1 className="community-p1-content-right-heading-main">
              Building the Khang Chien Bridge
            </h1>
            <div
              className="community-p1-content-right-contents"
              data-aos="fade-in"
            >
              <p>
                In the Mekong district of Vietnam, severe traffic congestion and
                bottlenecks have been an ongoing problem. With many of our
                employees living in this area, it became clear to Hirdaramani
                that the lack of infrastructure was negatively impacting the
                lives of our associates and taking too much time out of their
                day to travel to and from work.
              </p>

              <p>
                Our solution was to provide financial support for the
                construction of the Khang Chien Bridge, which resulted in
                alleviating a large amount of the traffic congestion in its
                vicinity. The bridge is valued at VND 3 billion and is expected
                to have significant long-term benefits for the region’s
                community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default SpotLight;
