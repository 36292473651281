import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import "./FlipCard.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Pillars() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div style={{ paddingBottom: "90px", paddingTop: "120px" }}>
      <center>
        <div className="ban-gap">
          <div className="icon-container">
            <div
              className="icon-column"
              id="column1"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <div aria-label="button" style={{ cursor: "not-allowed" }}>
                <div div className="energy-flip-button-image-1">
                  <h1 className="topic-overlay">ENERGY</h1>
                </div>
              </div>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/conserve/water/" aria-label="button">
                <div div className="energy-flip-button-image-2">
                  <h1 className="topic-overlay">WATER</h1>
                </div>
              </a>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/conserve/waste/" aria-label="button">
                <div div className="energy-flip-button-image-4">
                  <h1 className="topic-overlay">WASTE</h1>
                </div>
              </a>
            </div>
            <div
              className="icon-column"
              id="column2"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <a href="/conserve/chemical/" aria-label="button">
                <div div className="energy-flip-button-image-3">
                  <h1 className="topic-overlay">CHEMICAL MANAGEMENT</h1>
                </div>
              </a>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
