import React from "react";

const SquiggleSVG = () => (
  <svg
                  className="squiggle"
                  width="198px"
                  height="1458px"
                  style={{
                    position: "absolute",
                    top: "900px",
                    left: "394px",
                    width: "30vw",
                    height: "155vw",
                    zIndex: -1,
                  }}
                  viewBox="0 0 198 1458"
                >
                  <defs>
                    <linearGradient
                      x1="50%"
                      y1="7.06935325%"
                      x2="50%"
                      y2="100%"
                      id="linearGradient-1"
                    >
                      <stop stop-color="#2fcd3c" offset="0%"></stop>
                      <stop stop-color="#2fcd3c" offset="50.2239948%"></stop>
                      <stop stop-color="#2fcd3c" offset="100%"></stop>
                    </linearGradient>
                    <mask id="theMask" maskUnits="userSpaceOnUse">
                      <path
                        id="maskPath"
                        d="M702,266 C682,424 795.064639,474.307498 716,600 C599,786 769,821 688,988 C548.560405,1275.48657 822.815807,1223 840.843207,1373 C858.870608,1523 605.485477,1528 687.610302,1728"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-dasharray="1637"
                        stroke-dashoffset="1050"
                        transform="translate(-646.000000, -825.000000)"
                        stroke-width="4"
                        stroke="#fff"
                      />
                    </mask>
                  </defs>
                  <g id="content" mask="url(#theMask)">
                    <path
                      id="thePath"
                      d="M702,266 C682,424 795.064639,474.307498 716,600 C599,786 769,821 688,988 C548.560405,1275.48657 822.815807,1223 840.843207,1373 C858.870608,1523 605.485477,1528 687.610302,1728"
                      fill="none"
                      fill-rule="evenodd"
                      stroke-dasharray="12,16"
                      transform="translate(-646.000000, -825.000000)"
                      stroke-width="4"
                      stroke="url(#linearGradient-1)"
                    ></path>
                  </g>
                </svg>
);

export default SquiggleSVG;
