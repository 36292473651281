import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./career-chart-1.css";
import MaleChart from "./production";
import FemaleChart from "./non-production";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <h2 className="career-employees-chart-topic-1">
          Hours of Training by Function
        </h2>
        <br />

        <div style={{ width: "100%" }}>
          <div className="career-card-container-1">
            <div className="card career-chart-01-card" style={{ width: "110px" }}>
              <center>
                <div>
                  <MaleChart />
                </div>
                <br />
                <span className="career-para-1-male">Production Male and Female Breakdown</span>
                <div className="career-number-loader-1-male">
                  {counterOn && (
                    <CountUp
                      start={200000}
                      end={245125}
                      duration={3}
                      delay={0.3}
                    />
                  )}
                </div>
              </center>
            </div>

            <div className="card career-chart-01-card" style={{ width: "110px" }}>
              <center>
                <div>
                  <FemaleChart />
                </div>
                <br />
                <span className="career-para-1-female">Non Production Male and Female Breakdown</span>
                <div className="career-number-loader-1-female">
                  {counterOn && (
                    <CountUp
                      start={100000}
                      end={(109249)}
                      duration={3}
                      delay={0.3}
                    />
                  )}
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
