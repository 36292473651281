import React from "react";
//import { Link } from "react-router-dom";
import Chart from "./banglades-male.jsx";
import "./srilanka.css";

import malebutton1 from "../../../../assets/male-button-1.webp";
import femalebutton1 from "../../../../assets/female-button-1.webp";

export default function Srilanka() {

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="empower-chart-4-srilanka-paragraph-two-column">
          <div className="empower-chart-4-srilanka-paragraph-column-1">
            <div
              className={'button-image'}
              style={{
                backgroundImage: `url(${ malebutton1})`,
              }}
            />
            <br />
            <div
              className={'button-image'}
              style={{
                backgroundImage: `url(${femalebutton1})`,
              }}
            />
            <div className="aa-mobile">
              <p className="gender-topic">Gender</p>
            </div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-2">
            <div>{<Chart />}</div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-3">
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="empower-chart-4-srilanka-paragraph-two-column">
            <div className="empower-chart-4-srilanka-paragraph-column-4">
              <br />
              <p className="gender-topic">Gender</p>
            </div>
            <div className="empower-chart-4-srilanka-paragraph-column-5"></div>
            <div className="empower-chart-4-srilanka-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
