import React, { useEffect } from "react";
import "./FeatureStories.css";

// images
import tree_plant from "../../assets/future_stories_tree_plant.jpg";
import water_day from "../../assets/future_stories_water_day.png";
import cleanup from "../../assets/future_stories_cleanup.png";

//AOS
import Aos from "aos";
import "aos/dist/aos.css";

function FeatureStories() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="new-feature-stries-container">
      <div className="new-feature-stry-header">
        <div
          className="feature-story-topic"
          data-aos="fade-in"
          data-aos-duration="2500"
        >
          <h1>FEATURE STORIES</h1>
        </div>
        <div
          className="feature-story-underline"
          data-aos="fade-in"
          data-aos-duration="2500"
        ></div>
      </div>
      <center>
        <div className="feature-story-contents-container">
          {/* conatiner 1 */}
          <div className="feature-story-contents">
            <div className="feature-story-content-left">
              <h3 data-aos="fade-in" data-aos-duration="2500">
              2,500+ Trees Planted 
              </h3>
              <h1
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="500"
              >
                Tree Planting <br />
                Projects
              </h1>
              <p
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="1000"
              >
                <p>
                  We well understand that planting saplings play a vital role in
                  maintaining a healthy environment, combating climate change,
                  supporting wildlife, and enhancing the quality of life for all
                  living beings, among other things. This is well reflected in
                  our Future First Sustainability Roadmap under reforestation
                  and biodiversity commitments.
                </p>
                <p>
                  To this end, Hirdaramani teams across the Group have always
                  encouraged the planting of saplings, whether as projects driven
                  by the organisation or as part of larger projects involving
                  schools and communities.
                </p>
              </p>
            </div>
            <div
              className="feature-story-content-right"
              data-aos="fade-right"
              data-aos-duration="2500"
            >
              <img src={tree_plant} alt="" />
            </div>
          </div>

          {/* conatiner 2 */}
          <div className="feature-story-contents">
            <div className="feature-story-content-left">
              <h3 data-aos="fade-in" data-aos-duration="2500">
                we reached 10,000+ people
              </h3>
              <h1
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="500"
              >
                World Water Day
              </h1>
              <p
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="1000"
              >
                As a leader in apparel manufacturing, we’re happy to play our
                role in ensuring responsible water consumption across our
                footprint. At the same time, we understand the importance of
                creating awareness by continuously engaging with students,
                communities, our employees, and other stakeholders on the role
                we individually play in mitigating issues faced due to water
                scarcity. Special calendar days such as World Water Day are
                often an opportunity for us to drive home these messages with
                greater focus. In 2023, we reached 10,000+ people with a range
                of programs run by each facility across our operations including
                awareness sessions, and employee and community engagement
                activities.
              </p>
            </div>
            <div
              className="feature-story-content-right"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
              <img src={water_day} alt="" />
            </div>
          </div>

          {/* conatiner 3 */}
          <div className="feature-story-contents">
            <div className="feature-story-content-left">
              <h3 data-aos="fade-in" data-aos-duration="2500">
                Collected 16+ Kg<span className="lowercase">s</span> Of Plastic Waste
              </h3>
              <h1
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="500"
              >
                Bopath Ella Waterfall
              </h1>
              <p
                data-aos="fade-in"
                data-aos-duration="2500"
                data-aos-delay="1000"
              >
                <p>
                  In recent years, the Bopath Ella area in the hills of Sri Lanka has
                  become a popular holiday destination for both locals and
                  international visitors. Rapid growth in visitors can often
                  have a negative impact on natural locations with increasing
                  footfall, due to lack of infrastructure, regulations, and
                  awareness.
                </p>
                <p>
                  To encourage responsible waste disposal and management, the
                  Hirdaramani Seethawaka Washing plant team conducted a project
                  in 2023 to clean up the area surrounding the Bopath Ella waterfall as
                  well as provide bins for collection of waste.
                </p>
              </p>
            </div>
            <div
              className="feature-story-content-right"
              data-aos="fade-right"
              data-aos-duration="2500"
            >
              <img src={cleanup} alt="" />
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}

export default FeatureStories;
