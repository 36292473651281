import React, { useEffect } from "react";
import Header from "../../../components/header";
import "./chemicalpage.css";
import HomeVideo from "../../../assets/chemical_back.png";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import FlipCard from "./FlipCard";
import NumberCard from "./numbercard";
import GreenParagraph from "./greenParagraph";
import ChemicalTargetCharts from "./ChemicalTargetCharts";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        <Header />
        {/* section 01 */}
        <div style={{ position: "relative" }}>
          <div className="home-page-video">
            <div>
              <img
                src={HomeVideo}
                style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div className="conserve-video-text-box-2">
              <div className="video-background-text">
                <Parallax speed={10}>
                  <h1 className="chemicalpage-topic-home">CHEMICAL<br/>MANAGEMENT</h1>
                </Parallax>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              zIndex: 0,
            }}
          ></div>
        </div>

        {/* section-2 */}
        <div className="chemical-cards">
          <div className="chemical-para-box">
            <div data-aos="zoom-in">
              <h1 className="chemical-para-two">CHEMICAL</h1>
            </div>
            <div data-aos="fade-left">
              <center>
                <div className="chemical-card-width">
                  <NumberCard />
                </div>
              </center>
            </div>
          </div>
        </div>

        {/* section-3 */}
        <GreenParagraph />

        {/* Section-4 */}
        <ChemicalTargetCharts />

        <FlipCard />
      </div>
    </ParallaxProvider>
  );
}
