import React from "react";
import Header from "../../components/header";
import HomeVideo from "../../assets/contact-banner.webp";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import "./contact-us.css";
import Form from "./form";

export default function ContactUs() {
  return (
    <ParallaxProvider>
      <div>
        <div className="screen-page">
          <Header />

          <div style={{ position: "relative" }}>
            <div className="home-page-video">
              <div>
                <img
                  src={HomeVideo}
                  style={{ height: "100vh", width: "100%", objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>

            <div style={{ marginLeft: "20px" }}>
              <div className="contact-us-video-text-box">
                <div>
                  <Parallax speed={10}>
                    <h1 className="contact-us-topic-home">Contact Us</h1>
                  </Parallax>
                </div>
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.15)",
                zIndex: 0,
              }}
            ></div>
          </div>
        </div>
        <div className="contact-us-content">
          <h1 style={{ marginTop: "70px" }}>CONTACT US</h1>

          <div className="contact-us-two-column">
            <div className="contact-us-column-1">
              <p>
                Sri Lanka:{" "}
                <a href="mailto:srilanka@hirdaramani.com" className="link">
                  srilanka@hirdaramani.com
                </a>
              </p>
              <p>
                Vietnam:{" "}
                <a href="mailto:vietnam@hirdaramani.com" className="link">
                  vietnam@hirdaramani.com
                </a>
              </p>
            </div>
            <div className="contact-us-column-2">
              <p>
                Bangladesh:{" "}
                <a href="mailto:bangladesh@hirdaramani.com" className="link">
                  bangladesh@hirdaramani.com
                </a>
              </p>
              <p>
                Ethiopia:{" "}
                <a href="mailto:ethiopia@hirdaramani.com" className="link">
                  ethiopia@hirdaramani.com
                </a>
              </p>
            </div>
          </div>

          <br />

          <h3> Please submit your inquiries below</h3>
          <Form />

          <br />

          {/* <h3>Address</h3>

          <div className="contact-us-two-column">
            <div className="contact-us-column-1">
              <p>
                Group Head Office, <br />
                Level 23 West Tower, <br />
                World Trade Center, <br />
                Echelon Square, <br />
                Colombo 1, <br />
                Sri Lanka.
              </p>
              <p>
                Tel:{" "}
                <a className="link" href="tel:+94114797000">
                  {" "}
                  (+94) 11 4797000
                </a>{" "}
                <br />
                Fax:{" "}
                <a className="link" href="fax:+94112446135">
                  {" "}
                  (+94) 11 2446135
                </a>{" "}
                <br />
                Email:{" "}
                <a href="mailto:info@hirdaramani.com" className="link">
                  info@hirdaramani.com
                </a>
              </p>
            </div>
            <div className="contact-us-column-2">2</div>
          </div> */}
        </div>
      </div>
    </ParallaxProvider>
  );
}
