import React from 'react'
import './sitemap.css'
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo.webp";
import ScrollArrow from "./scroll-arrow";
import Accessibility from "./accessibility";

function sitemap() {
  return (
    <div className='sitemap-page-container'>
      <center>
        <div style={{ width: "100%", position: "relative" }}>
            <Link to="/" className="link">
            <img
                src={CompanyLogo}
                alt="Company Logo"
                className="sitemap-company-logo"
            />
            </Link>
            <ScrollArrow />
            <Accessibility />
        </div>
        <div className="sitemap-page-contents">
            <div className="sitemap-page-topic-section">
                <h1>Site Map</h1>
            </div>
            <div className="sitemap-page-content-section">
                <div className="sitemap-page-coloumn">
                    <h2>Pages</h2>
                    <div className="sitemap-page-coloumn-sub-headings">
                        <a href="/">Home</a>
                        <a href="/conserve/">Conserve</a>
                        <a href="/empower/">Empower</a>
                        <a href="/support/">Support</a>
                        <a href="/trust/">Trust</a>
                        <a href="/contact-us/">Contact Us</a>
                    </div>
                </div>
                <div className="sitemap-page-coloumn">
                    <h2>Conserve</h2>
                    <div className="sitemap-page-coloumn-sub-headings">
                        <a href="/conserve/energy/">Energy</a>
                        <a href="/conserve/water/">Water</a>
                        <a href="/conserve/waste/">Waste</a>
                        <a href="/conserve/chemical/">Chemical Management</a>
                    </div>
                </div>
                <div className="sitemap-page-coloumn">
                    <h2>Empower</h2>
                    <div className="sitemap-page-coloumn-sub-headings">
                        <a href="/empower/equality/">Equality</a>
                        <a href="/empower/career-development/">Career Development</a>
                        <a href="/empower/wellbeing/">Wellbeing</a>
                    </div>
                </div>
                <div className="sitemap-page-coloumn">
                    <h2>Support</h2>
                    <div className="sitemap-page-coloumn-sub-headings">
                        <a href="/support/children&education/">Children & Education</a>
                        <a href="/support/wellbeing/">Wellbeing</a>
                        <a href="/support/environment/">Environment</a>
                    </div>
                </div>
                <div className="sitemap-page-coloumn">
                    <h2>Legal</h2>
                    <div className="sitemap-page-coloumn-sub-headings">
                        <a href="/privacy-policy/">Privacy Policy</a>
                        <a href="/cookies-policy/">Cookie Policy</a>
                    </div>
                </div>
            </div>
        </div>
      </center>
    </div>
  )
}

export default sitemap
