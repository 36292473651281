import React from "react";
import "./chart1.css";
import Srilanka from './srilanka.jsx';

export default function Chart() {

  return (
    <div style={{ width: "100%", padding: "50px 0" }}>
      <div className="conserve-chart-02">
        <h1 className="conserve-chartHeading">
          Green Building Certified Facilities and Non Certified
        </h1>
        <div className="conserve-chart-2-paragraph-two-column">
          <div className="conserve-chart-2-paragraph-column-2-new">
            <div>
              <Srilanka />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}