import React, { useState } from "react";
import { Link } from "react-router-dom";
import NonProduction from "./gender-non-production";
import Production from "./gender-production";
import "./career-chart-4.css";

export default function Chart() {
  const [selectedTab, setSelectedTab] = useState("Production");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "NonProduction":
        return <NonProduction />;
      case "Production": // Corrected tab name
        return <Production />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <h2 className="main-topic-chart-p">
          Performance Reviews by Gender & Function
        </h2>
        <br />
        <div className="career-mobile">
          <div className="career-chart-04-mobile-content">
            <Link
              tabIndex={0}
              className={`chart-topic-button ${
                selectedTab === "NonProduction" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("NonProduction")}
              onKeyDown={(e) => handleKeyDown(e, "NonProduction")}
            >
              <p>Non Production</p>
            </Link>
            <Link
              tabIndex={0}
              className={`chart-topic-button ${
                selectedTab === "Production" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("Production")}
              onKeyDown={(e) => handleKeyDown(e, "Production")}
            >
              <p>Production</p>
            </Link>
          </div>
        </div>

        <div className="career-chart-4-two-column">
          <div className="career-chart-4-column-1">
            <div>{renderPage()}</div>
          </div>
          <div className="career-chart-4-column-2">
            <div>
              <div className="button-a-chart">
                <Link
                  tabIndex={0}
                  className={`chart-topic-button ${
                    selectedTab === "NonProduction" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("NonProduction")}
                  onKeyDown={(e) => handleKeyDown(e, "NonProduction")}
                >
                  <p>Non Production</p>
                </Link>
                <Link
                  tabIndex={0}
                  className={`chart-topic-button ${
                    selectedTab === "Production" ? "selected" : ""
                  }`}
                  onClick={() => handleTabClick("Production")}
                  onKeyDown={(e) => handleKeyDown(e, "Production")}
                >
                  <p>Production</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
