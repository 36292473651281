import React, { useState, useEffect, useRef } from "react";
import "./sri_lanka_female.css";
import bottleImage from "../../../../../assets/Women-1-new.webp";

const Chart = () => {
  const [waterPercentage, setWaterPercentage] = useState(0);
  const chartSectionRef = useRef(null);

  useEffect(() => {
    const chartSectionNode = chartSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const timer = setTimeout(() => {
              const waterHeight = (17 / 100) * 200; // 30% of the bottle height
              setWaterPercentage(waterHeight);
            }, 300); // Adjust delay time as needed

            return () => clearTimeout(timer);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (chartSectionNode) {
      observer.observe(chartSectionNode);
    }

    return () => {
      if (chartSectionNode) {
        observer.unobserve(chartSectionNode);
      }
    };
  }, []);

  return (
    <div className="chart-section" ref={chartSectionRef}>
      <div className="female-container-2">
        <div className="female1">
          <img className="female-image" src={bottleImage} alt="Bottle" />
          <div className="female-water1" style={{ height: waterPercentage }} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
