import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./SriLanka&Ethiopia.css";
import FemaleChart1 from "./srilanka-chart-1";
import FemaleChart2 from "./srilanka-chart-2";
import FemaleChart3 from "./srilanka-chart-3";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="trust-chart-01-chart-box">
          <div className="trust-section-card-container-1">
            <div className="card trust-chart-01-card">
              <center>
                <div className="sri_lanka-ethiopia-number-loader">
                  {counterOn && (
                    <CountUp start={0} end={33} duration={3} delay={0.3} />
                  )}
                  %
                </div>
                <br />
                <div>
                  <FemaleChart1 />
                </div>
                <br />
                <span className="trust-Sri_Lanka_2023">2023</span>
              </center>
            </div>

            <div className="card trust-chart-01-card">
              <center>
                <div className="sri_lanka-ethiopia-number-loader">
                  {counterOn && (
                    <CountUp start={0} end={37} duration={3} delay={0.3} />
                  )}
                  %
                </div>
                <br />
                <div>
                  <FemaleChart2 />
                </div>
                <br />
                <span className="trust-Sri_Lanka_2022">2022</span>
              </center>
            </div>

            <div className="card trust-chart-01-card">
              <center>
                <div className="sri_lanka-ethiopia-number-loader">
                  {counterOn && (
                    <CountUp start={0} end={80} duration={3} delay={0.3} />
                  )}
                  %
                </div>
                <br />
                <div>
                  <FemaleChart3 />
                </div>
                <br />
                <span className="trust-Sri_Lanka_Target">Target</span>
              </center>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
