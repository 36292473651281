import React from "react";
//import { Helmet } from "react-helmet";
import Page from "../components/privacy-policy";

export default function PrivacyPolicy() {
  return (
    <div>
      <Page />
    </div>
  );
}
