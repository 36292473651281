import React, { useState } from "react";
//import { Link } from "react-router-dom";
import Male from "./ethiopia-male";
import Female from "./ethiopia-female";
import "./srilanka.css";

import malebutton1 from "../../../../assets/male-button-1.webp";
import malebutton2 from "../../../../assets/male-button-2.webp";
import femalebutton1 from "../../../../assets/female-button-1.webp";
import femalebutton2 from "../../../../assets/female-button-2.webp";

export default function Srilanka() {
  const [selectedTab, setSelectedTab] = useState("male");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "male":
        return <Male />;
      case "female":
        return <Female />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className="empower-chart-4-srilanka-paragraph-two-column">
          <div className="empower-chart-4-srilanka-paragraph-column-1">
            <div
              tabIndex={0}
              className={`button-image ${
                selectedTab === "male" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("male")}
              onKeyDown={(e) => handleKeyDown(e, "male")}
              style={{
                backgroundImage: `url(${
                  selectedTab === "male" ? malebutton1 : malebutton2
                })`,
              }}
            />
            <br />
            <div
              tabIndex={0}
              className={`button-image ${
                selectedTab === "female" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("female")}
              onKeyDown={(e) => handleKeyDown(e, "female")}
              style={{
                backgroundImage: `url(${
                  selectedTab === "female" ? femalebutton1 : femalebutton2
                })`,
              }}
            />
            <div className="aa-mobile">
              <p className="gender-topic">Gender</p>
            </div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-2">
            <div>{renderPage()}</div>
          </div>
          <div className="empower-chart-4-srilanka-paragraph-column-3">
            <div>
              <br />
              <div className="sri-lanka-text-box1">Over 50</div>
              <br />
              <div className="sri-lanka-text-box2">30-50</div>
              <br />
              <div className="sri-lanka-text-box3">Under 30</div>
              <br />
              <div className="sri-lanka-text-box4">Percentage</div>
            </div>
          </div>
        </div>
      </div>
      <div className="aa-pc">
        <div style={{ marginTop: "-20px" }}>
          <div className="empower-chart-4-srilanka-paragraph-two-column">
            <div className="empower-chart-4-srilanka-paragraph-column-4">
              <br />
              <p className="gender-topic">Gender</p>
            </div>
            <div className="empower-chart-4-srilanka-paragraph-column-5"></div>
            <div className="empower-chart-4-srilanka-paragraph-column-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
