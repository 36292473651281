  import React from "react";
  import { Link } from "react-router-dom";
  import CompanyLogo from "../assets/logo-white.webp";
  import "./header.css";
  import ScrollArrow from "./scroll-arrow";
  import Logo from "./logo-2";
  import Accessibility from "./accessibility";

  export default function Header() {

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Link to="/" className="link">
          <img src={CompanyLogo} alt="Company Logo" className="company-logo" />
        </Link>
        <Logo />
        <ScrollArrow />
        <Accessibility />
      </div>
    );
  }
