import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./highlights-trust.css";
import HighlightLine from "./highlights-line";
import { FaCaretRight } from "react-icons/fa6";

export default function HighlightsTrust() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className="r-part">
          <div className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp
                start={0}
                end={6.41}
                decimals={2}
                duration={2}
                delay={0}
              />
            )}
            % <span className="card-circle-conserve1">E10</span>
            <div className="hover-area-home-page hover-area-home-page-conserve-01">
              <p className="tooltip-texts-home-page">Zero Waste to Landfill</p>
              <FaCaretRight size={30} className="hover-area-arrow-home-page" />
            </div>
          </div>
          <p className="values-para">
            Remaining to achieve the Zero Waste to Landfill target
          </p>
          <div className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp start={0} end={83} decimals={0} duration={2} delay={0} />
            )}
            % <span className="card-circle-conserve2">E12</span>
            <div className="hover-area-home-page hover-area-home-page-conserve-02">
              <p className="tooltip-texts-home-page">
                Implement a Program to Phase out all Hazardous Chemicals
              </p>
              <FaCaretRight size={30} className="hover-area-arrow-home-page" />
            </div>
          </div>
          <p className="values-para">
            Achieved on goal to phase out 100% of Hazardous Chemicals
          </p>
          <div className="values-loader">
            <span style={{ color: "#3CE5B2" }}>+</span>
            {counterOn && (
              <CountUp start={0} end={72} decimals={0} duration={2} delay={0} />
            )}
            L <span className="card-circle-conserve3">E7</span>
            <div className="hover-area-home-page hover-area-home-page-conserve-03">
              <p className="tooltip-texts-home-page">
                Recycle over 50% of Industrial Wastewater for Industrial
                Processes
              </p>
              <FaCaretRight size={30} className="hover-area-arrow-home-page" />
            </div>
          </div>
          <p className="values-para">
            per employee/day, exceeded the target to reduce domestic freshwater
            consumption
          </p>

          <div className="horizontal-line" />
          <div style={{ paddingBottom: "120px" }} />
        </div>
        <HighlightLine />
        <div style={{ paddingBottom: "1px" }} />
        <div className="mobile">
          <div style={{ paddingBottom: "10px" }} />
        </div>
      </ScrollTrigger>
    </div>
  );
}
