import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaCaretRight } from "react-icons/fa6";
import "./numbercard.css";

const Card = (props) => {
  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const renderTitle = (title) => {
    return title.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <div
      className="waste-card"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      <div className="waste-card-circle-container">
        <div
          className="waste-card-circle"
          style={{ backgroundColor: props.circlbackgroundcolor }}
        >
          <p>{props.circlenumber}</p>
        </div>
        <div 
          className="hover-area"
          style={{ backgroundColor: props.circlbackgroundcolor,
          opacity:1 }}
        >
          <p className="tooltip-texts">{props.tooltip}</p>
          <FaCaretRight size={30} className="hover-area-arrow"
          style={{ color: props.circlbackgroundcolor,
          opacity:1 }}
          />
        </div>
      </div>
      <div>
        <h2
          className="waste-card-title"
          style={{ color: props.titlefontcolor }}
        >
          {renderTitle(props.title)}
        </h2>
        <p
          className="waste-card-description"
          style={{ color: props.fontcolor }}
        >
          {renderDescription(props.description)}
        </p>
      </div>
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <div onClick={onClick} className="waste-arrow left-arrow-waste">
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div onClick={onClick} className="waste-arrow right-arrow-waste">
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const Home = () => {
  const cards = [
    {
      title: "34.42\ng/Garment",
      titlefontcolor: "#313893",
      description:
        "Significant decrease in fabric waste per garment brings us close to the target 33.6 g/Garment",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E9",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:"Reduce 5% Normalised Processed Fabric Waste per Garment"
    },
    {
      title: "\n6.41%",
      titlefontcolor: "#313893",
      description: "of waste sent for landfilling\n\n\n",
      backgroundcolor: "#C5F1F7",
      fontcolor: "#313893",
      circlenumber: "E10",
      circlbackgroundcolor: "#3CB4E5",
      tooltip:'Zero Waste to Landfill'
    },
    {
      title: "\n78%",
      titlefontcolor: "#313893",
      description:
        "Continued progress and on track to meet the 80% target for recycling fabric waste",
      backgroundcolor: "#C5F7E8",
      fontcolor: "#313893",
      circlenumber: "E11",
      circlbackgroundcolor: "#3CE5B2",
      tooltip:'80% of Fabric Waste to be Recycled'
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          dots: false,
          autoplay:true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          autoplay:true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={index}>
          <Card
            title={card.title}
            description={card.description}
            backgroundcolor={card.backgroundcolor}
            fontcolor={card.fontcolor}
            circlenumber={card.circlenumber}
            circlbackgroundcolor={card.circlbackgroundcolor}
            titlefontcolor={card.titlefontcolor}
            tooltip={card.tooltip}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Home;
