import React from 'react'
import Page from '../templates/empower/equality/equalitypage'
import { Helmet } from "react-helmet";

function equality() {
  return (
    <div>
      <Helmet>
        <title>Driving Gender Equality | Hirdaramani</title>
        <meta name="title" content="Driving Gender Equality | Hirdaramani" />
        <meta
          name="description"
          content="Join us in leading women's empowerment at Hirdaramani. Explore our goals for increased female representation in leadership by 2025."
        />
        <link
          rel="canonical"
          href="https://sustainability.hirdaramani.com/empower/equality/"
        />
        <meta property="og:locale" content="en_US" />
        <meta name="keywords" content="Gender Equality" />
        <meta
          property="og:title"
          content="Driving Gender Equality | Hirdaramani"
        />
        <meta
          property="og:description"
          content="Join us in leading women's empowerment at Hirdaramani. Explore our goals for increased female representation in leadership by 2025."
        />
        <meta
          property="og:image"
          content="https://hirdaramani-microsite.sgp1.digitaloceanspaces.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://sustainability.hirdaramani.com/empower/equality/"
        />
      </Helmet>

      <Page />
    </div>
  );
}

export default equality
