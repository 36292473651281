import React, { useState } from "react";
import { Link } from "react-router-dom";
import SriLanka from "./SriLanka&Ethiopia";
import BangladeshCard from "./Bangladesh";
import Vietnam from "./Vietnam";
import Ethiopia from "./Bangladesh";
import "./trust-chart-1.css";

export default function Highlights() {
  const [selectedTab, setSelectedTab] = useState("srilanka");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "srilanka":
        return <SriLanka />;
      case "banglades":
        return <BangladeshCard />;
      case "vietnam":
        return <Vietnam />;
      case "ethiopia":
        return <Ethiopia />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <h2 className="main-topic-chart-1-p">
         % of Sustainable fabrics from total purchased value
        </h2>
        <br />
        <div className="trust-chart-1-paragraph-two-column">
          <div className="chart-1-country-list">
            <Link
              tabIndex={0}
              className={`para-1-flag ${
                selectedTab === "srilanka" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("srilanka")}
              onKeyDown={(e) => handleKeyDown(e, "srilanka")}
            >
              {/* <img src={srilanka} alt="1" /> */}
              <h3 className={selectedTab === "srilanka" ? "selected" : ""}>
                Sri Lanka & Ethiopia
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`para-2-flag ${
                selectedTab === "banglades" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("banglades")}
              onKeyDown={(e) => handleKeyDown(e, "banglades")}
            >
              {/* <img src={banglades} alt="2" /> */}
              <h3 className={selectedTab === "banglades" ? "selected" : ""}>
                Bangladesh
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`para-3-flag ${
                selectedTab === "vietnam" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("vietnam")}
              onKeyDown={(e) => handleKeyDown(e, "vietnam")}
            >
              {/* <img src={vietnam} alt="3" /> */}
              <h3 className={selectedTab === "vietnam" ? "selected" : ""}>
                Vietnam
              </h3>
            </Link>

            <Link
              tabIndex={0}
              className={`para-4-flag ${
                selectedTab === "ethiopia" ? "selected" : ""
              }`}
              onClick={() => handleTabClick("ethiopia")}
              onKeyDown={(e) => handleKeyDown(e, "ethiopia")}
            >
              {/* <img src={ethiopia} alt="4" /> */}
              <h3 className={selectedTab === "ethiopia" ? "selected" : ""}>
                Group
              </h3>
            </Link>
          </div>
        </div>

        <br />
      </div>

      <div>{renderPage()}</div>
    </div>
  );
}
