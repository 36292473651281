import React from "react";
import "./chart.css";
import Srilanka from './srilanka.jsx';

export default function Chart() {

  return (
    <div style={{ width: "100%", padding: "50px 0" }}>
      <div className="conserve-chart-03">
        <h1 className="conserve-chartHeading">
          ISO 14001/ 26000 Certified Facilities
        </h1>
        <div className="conserve-chart-03-paragraph-two-column">
          <div className="conserve-chart-03-paragraph-column-02">
            <div>
              <Srilanka />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}