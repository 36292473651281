import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Sri-Lanka.css";
import FemaleChart1 from "./image-chart-1";
import FemaleChart2 from "./image-chart-2";

export default function Chart() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div style={{ position: "relative" }}>
        <div className="chart-box empower-wellbeing-chart-01">
          <center>
            <div className="trust-card-container-1">
              <div className="card" style={{ width: "250px" }}>
                <center>
                  <div>
                    <FemaleChart1 />
                  </div>
                  <br />

                  <div className="sri_lanka-number-loader">
                    <p className="chart-1-t-c">
                      Implemented
                      <span className="num-c-2">
                        <br />
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={82}
                            duration={3}
                            decimals={0}
                            delay={0.3}
                          />
                        )}
                        %
                      </span>
                    </p>
                  </div>
                </center>
              </div>

              <div className="card" style={{ width: "250px" }}>
                <center>
                  <div>
                    <FemaleChart2 />
                  </div>
                  <br />
                  <div className="sri_lanka-number-loader">
                    <p className="chart-2-t-c">
                      Not Implemented
                      <span className="num-c-2">
                        <br />
                        {counterOn && (
                          <CountUp start={0} end={18} duration={3} delay={0.3} />
                        )}
                        %
                      </span>
                    </p>
                  </div>
                </center>
              </div>
            </div>
          </center>
        </div>
      </div>
    </ScrollTrigger>
  );
}
