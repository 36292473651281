import React, { useEffect } from "react";
import "./healthcare-part.css";
import { ParallaxProvider } from "react-scroll-parallax";
import HealthcareTargetsCharts from "./HealthcareTargetsCharts";
import HealthcareSpotlight from "./healthcare-spotlight";

export default function Healthcare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ParallaxProvider>
      <div className="screen-page">
        {/* section-2 */}
        <div>
          <div className="healthcare-animation-para">
            <center className="healthcare-animation-para-center">
              <h1>Healthcare</h1>
              <p>
                It’s well understood that healthcare and hospitals are critical
                for any community, including in areas we operate. But the
                reality is that many healthcare services and hospitals are
                significantly underfunded and face daily challenges due to lack
                of resources.
              </p>
              <p>
                Hirdaramani engages with every local hospital near our factories
                to provide support through any possible means, including
                donation of medication , infrastructure projects, and equipment
                funding.
              </p>
              <p>
                We also run community clinics for specialized needs such as eye
                care and dental, along with hosting blood donation drives among
                our workforce.
              </p>
            </center>
          </div>
        </div>

        <HealthcareTargetsCharts />

        <HealthcareSpotlight />
      </div>
    </ParallaxProvider>
  );
}
